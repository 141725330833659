import LayersIcon from "@mui/icons-material/Layers";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import { Chip, IconButton, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { FC, useCallback, useMemo, useState } from "react";
import {
  GeneralSearchQuery,
  Sequence,
} from "../../../../../../param/generalSearchQuery";
import { sequenceTypes } from "../recipe/data/generalSearchRecipe";
import { AnswerKeySearch } from "../recipe/form/AnswerKeyForm";
import { KanaToRomaForm } from "../recipe/form/KanaToRomaForm";
import { RotateKeyForm } from "../recipe/form/RotateKeyForm";
import { SimpleSearchForm } from "../recipe/form/SimpleSearch";
import { VariableParallelTransformForm } from "../recipe/form/VariableParallelTransform";
import { CandidateCharactersForm } from "./CandidateCharactersForm";
import { ConditionalTransformForm } from "./ConditionalTransformForm";
import { DictionaryMatchForm } from "./DictionaryMatchForm";
import { IdenticalTraceFilterForm } from "./IdenticalTraceFilterForm";
import { KeyValueMatchForm } from "./KeyValueMatchForm";
import { LengthForm } from "./LengthForm";
import { ParallelTransformForm } from "./ParallelTransformForm";
import { RegexFilterForm } from "./RegexFilterForm";
import { RegexMatchCompareForm } from "./RegexMatchCompareForm";
import { RegexReplaceForm } from "./RegexReplaceForm";

type SequenceFormProps = {
  index: number;
  query: Sequence;
  updateQuery: (index: number, field: string, value: any) => void;
  onDelete: () => void;
  onClick: () => void;
  displayTraceNumber: boolean;
  sequencialQueries: GeneralSearchQuery[];
  displayTooltip: boolean;
  isDemo: boolean;
};

export const SequenceForm: FC<SequenceFormProps> = ({
  index,
  query,
  displayTraceNumber,
  sequencialQueries,
  updateQuery,
  onClick,
  onDelete,
  displayTooltip,
  isDemo,
}) => {
  const [viewMode, setViewMode] = useState<"recipe" | "sequence">("recipe");
  const updateSequenceQuery = useCallback(
    (sIndex: number, sField: string, sValue: any) => {
      (query.processes[sIndex] as any)[sField] = sValue;
      updateQuery(index, "processes", query.processes);
    },
    [index, query.processes, updateQuery]
  );
  const disabled = useMemo(
    () => sequenceTypes[query.sequenceType as string].singleDirection || isDemo,
    [query.sequenceType, isDemo]
  );
  return (
    <>
      {viewMode === "recipe" && (
        <>
          {sequenceTypes[query.sequenceType as string] && (
            <Box
              sx={{
                display: "inline-block",
                verticalAlign: "middle",
                height: "auto",
                width: 200,
                my: 0.5,
              }}
            >
              <Tooltip
                title={
                  displayTooltip &&
                  sequenceTypes[query.sequenceType as string].description
                }
                arrow
              >
                <Chip
                  label={sequenceTypes[query.sequenceType as string].name || ""}
                  icon={<WysiwygIcon />}
                  color={
                    query.enabled === undefined || query.enabled === true
                      ? "success"
                      : "default"
                  }
                  sx={{
                    mr: 1,
                  }}
                  onClick={() => {
                    setViewMode(viewMode === "recipe" ? "sequence" : "recipe");
                  }}
                  disabled={isDemo}
                />
              </Tooltip>
            </Box>
          )}

          {query.sequenceType === "answerKeySearch" && (
            <AnswerKeySearch
              index={index}
              query={query}
              updateQuery={updateQuery}
              isDemo={isDemo}
            />
          )}
          {query.sequenceType === "rotateKeySearch" && (
            <RotateKeyForm
              index={index}
              query={query}
              updateQuery={updateQuery}
              isDemo={isDemo}
            />
          )}
          {query.sequenceType === "kanaToRoma" && (
            <KanaToRomaForm
              index={index}
              query={query}
              updateQuery={updateQuery}
              isDemo={isDemo}
            />
          )}
          {query.sequenceType === "simpleSearch" && (
            <SimpleSearchForm
              index={index}
              query={query}
              updateQuery={updateQuery}
              isDemo={isDemo}
            />
          )}
          {query.sequenceType === "variableParallelTransform" && (
            <VariableParallelTransformForm
              index={index}
              query={query}
              updateQuery={updateQuery}
              isDemo={isDemo}
            />
          )}
        </>
      )}

      {viewMode === "sequence" && (
        <>
          {sequenceTypes[query.sequenceType as string] && (
            <Box
              sx={{
                display: "inline-block",
                verticalAlign: "middle",
                height: "auto",
                width: 200,
                my: 0.5,
              }}
            >
              <Chip
                label={"シーケンス表示"}
                icon={<LayersIcon />}
                color={
                  query.enabled === undefined || query.enabled === true
                    ? "primary"
                    : "default"
                }
                sx={{
                  mr: 1,
                }}
                onClick={() => setViewMode("recipe")}
                disabled={isDemo}
              />
            </Box>
          )}

          {query.processes.map(
            (process: GeneralSearchQuery, pIndex: number) => (
              <Box key={"processform-" + pIndex} mb={0} mt={2}>
                {process.type === "regexReplace" && (
                  <RegexReplaceForm
                    index={pIndex}
                    query={process}
                    updateQuery={updateSequenceQuery}
                    isDemo={isDemo}
                  />
                )}
                {process.type === "regex" && (
                  <RegexFilterForm
                    index={pIndex}
                    query={process}
                    updateQuery={updateSequenceQuery}
                    isDemo={disabled}
                  />
                )}
                {process.type === "regexMatchCompare" && (
                  <RegexMatchCompareForm
                    index={pIndex}
                    query={process}
                    updateQuery={updateSequenceQuery}
                    displayTraceNumber={displayTraceNumber}
                    queries={sequencialQueries}
                    isDemo={disabled}
                  />
                )}
                {process.type === "identicalTrace" && (
                  <IdenticalTraceFilterForm
                    index={pIndex}
                    query={process}
                    updateQuery={updateSequenceQuery}
                    isDemo={disabled}
                  />
                )}
                {process.type === "keyValueMatch" && (
                  <KeyValueMatchForm
                    index={pIndex}
                    query={process}
                    updateQuery={updateSequenceQuery}
                    isDemo={disabled}
                  />
                )}
                {process.type === "parallelTransform" && (
                  <ParallelTransformForm
                    index={pIndex}
                    query={process}
                    updateQuery={updateSequenceQuery}
                    isDemo={disabled}
                  />
                )}
                {process.type === "length" && (
                  <LengthForm
                    index={pIndex}
                    query={process}
                    updateQuery={updateSequenceQuery}
                    isDemo={disabled}
                  />
                )}
                {process.type === "candidateCharacters" && (
                  <CandidateCharactersForm
                    index={pIndex}
                    query={process}
                    updateQuery={updateSequenceQuery}
                    isDemo={disabled}
                  />
                )}
                {process.type === "dictionaryMatch" && (
                  <DictionaryMatchForm
                    index={pIndex}
                    query={process}
                    updateQuery={updateSequenceQuery}
                    isDemo={disabled}
                  />
                )}
                {process.type === "conditionalTransform" && (
                  <ConditionalTransformForm
                    index={pIndex}
                    query={process}
                    updateQuery={updateSequenceQuery}
                    isDemo={disabled}
                  />
                )}
              </Box>
            )
          )}
        </>
      )}
    </>
  );
};
