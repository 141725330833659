import { TextField, Button } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import { compress } from "../../../../../../lib/compress";
import { GeneralSearchQuery } from "../../../../../../param/generalSearchQuery";
import { sequenceTypes } from "../recipe/data/generalSearchRecipe";

type Props = {
  query: string;
  setQuery: (query: string) => void;
  setQueries: (queries: GeneralSearchQuery[]) => void;
  outputUrl: string;
  setOutputUrl: (outputUrl: string) => void;
  jsonError: boolean;
  setJsonError: (jsonError: boolean) => void;
  isDemo: boolean;
};

export const JsonView: FC<Props> = ({
  query,
  setQuery,
  setQueries,
  outputUrl,
  setOutputUrl,
  jsonError,
  setJsonError,
  isDemo,
}) => {
  return (
    <>
      <Box maxWidth={"100%"}>
        <TextField
          label="JSON表示"
          variant="outlined"
          sx={{ width: "100%", mt: 2 }}
          multiline
          rows={10}
          value={query}
          error={jsonError}
          onChange={(e) => {
            try {
              setQueries(JSON.parse(e.target.value));
              setQuery(e.target.value);
              setOutputUrl("");
              setJsonError(false);
            } catch (error) {
              console.log(error);
              setJsonError(true);
              setQuery(e.target.value);
            }
          }}
          disabled={isDemo}
        />
        <TextField
          label="出力URL"
          variant="outlined"
          sx={{ width: "100%", mt: 2 }}
          value={outputUrl}
          error={jsonError}
          aria-readonly
          onFocus={async (e) => {
            const compressed = await compress(query);
            setOutputUrl(
              `${window.location.origin}/general-search?q=${compressed}`
            );
          }}
          disabled={isDemo}
        />
      </Box>
      <Button
        variant="contained"
        sx={{ mt: 2, mr: 2 }}
        disabled={jsonError}
        onClick={() => {
          setQuery(JSON.stringify(JSON.parse(query), null, 2));
        }}
      >
        JSONを整形
      </Button>
      <Button
        variant="contained"
        sx={{ mt: 2, mr: 2 }}
        disabled={jsonError}
        onClick={() => {
          const shortQuery = (JSON.parse(query) as GeneralSearchQuery[])
            .map((query) => {
              const newQuery = { ...query };
              if (newQuery.enabled === true) {
                delete newQuery.enabled;
              }
              return newQuery;
            })
            .map((query) => {
              if (
                query.type === "sequence" &&
                sequenceTypes[query.sequenceType].singleDirection
              ) {
                query.processes =
                  sequenceTypes[query.sequenceType].defaultQuery.processes;
              }
              return query;
            })
            .filter((query) => query.enabled !== false);
          setQuery(JSON.stringify(shortQuery));
          setQueries(shortQuery);
        }}
      >
        JSONを短縮
      </Button>
      <Button
        variant="contained"
        sx={{ mt: 2, mr: 2 }}
        onClick={() => {
          navigator.clipboard.writeText(query);
        }}
      >
        クリップボードにコピー
      </Button>
      <Button
        variant="contained"
        sx={{ mt: 2, mr: 2 }}
        onClick={async () => {
          navigator.clipboard.writeText(outputUrl);
        }}
        disabled={outputUrl === ""}
      >
        出力URLをクリップボードにコピー
      </Button>
    </>
  );
};
