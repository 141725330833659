import { Checkbox, FormControlLabel } from "@mui/material";
import { FC } from "react";
import { RegexReplace } from "../../../../../../param/generalSearchTransform";
import { RegexInputWithCompletion } from "../../../../commonStudio/RegexInputWithCompletion";
import { ReplacementInput } from "../../../../commonStudio/ReplacementInput";
import { BasicFormProps } from "../FormProps";

export const RegexReplaceForm: FC<BasicFormProps<RegexReplace>> = ({
  index,
  query,
  updateQuery,
  isDemo,
  onBlur,
}) => {
  return (
    <>
      <RegexInputWithCompletion
        pattern={query.pattern ?? ""}
        onPatternChange={(newPattern) =>
          updateQuery(index, "pattern", newPattern)
        }
        disabled={isDemo}
        onBlur={onBlur}
      />
      <ReplacementInput
        replacement={query.replacement}
        onReplacementChange={(newReplacement) =>
          updateQuery(index, "replacement", newReplacement)
        }
        disabled={isDemo}
        onBlur={onBlur}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={query.matchOnly || false}
            onChange={(e) => updateQuery(index, "matchOnly", e.target.checked)}
            onBlur={onBlur}
          />
        }
        sx={{ ml: 1 }}
        label="マッチのみ抽出"
        disabled={isDemo}
      />
    </>
  );
};
