import { Container } from "@mui/system";
import { useContext } from "react";
import { WordList } from "./WordList";
import { EnigmaStudioContext } from "../../context/EnigmaStudioContext";
import { Title } from "../../common/Title";

export const WordGeneratorContainer = () => {
  const ctx = useContext(EnigmaStudioContext);

  const plan = ctx.plan;
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Title>ワードジェネレータ {plan === "normal" ? "Lite" : "Full"}</Title>
      <WordList />
    </Container>
  );
};
