import { App } from "../../imaginate/mainView/Apps";
import { FileTypeIcon } from "../PuzsqTypeIcon";

//PuzzleContestFolder下のapp
const puzsqContestAppsData = [
  /*{
    fileType: "folder",
    name: "フォルダ",
    description: "アイテムを格納します",
  },
  {
    fileType: "image-library",
    name: "公開アルバム",
    description: "画像を格納します",
  },
  {
    fileType: "image",
    name: "画像",
    description: "jpg, png, gif, svgなどの画像ファイル",
  },*/
  {
    fileType: "puzsq-contest-problem-folder",
    name: "コンテスト用問題フォルダ",
    description: "問題や解答を格納します",
  },
  {
    fileType: "puzsq-contest-manager",
    name: "コンテスト管理",
    description: "コンテストの設定や管理を行います",
  },
];

type AppProps = {
  createDocument: (type: string) => void;
};

export const PuzsqContestApp = (props: AppProps) => {
  return (
    <App
      {...props}
      appsData={puzsqContestAppsData}
      FileTypeIcon={FileTypeIcon}
    />
  );
};
