import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { ChangeEvent, FC, useCallback, useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useStatePersist } from "use-state-persist";
import { useWorker } from "../../../../hooks/useWorker";
import { FunctionSearchProgress } from "../../../../param/generalSearchProgress";
import { GeneralSearchQuery } from "../../../../param/generalSearchQuery";
import { Title } from "../../common/Title";
import { DictionaryRadioSelector } from "../../commonStudio/DictionaryRadioSelector";
import { DictionarySelector } from "../../commonStudio/DictionarySelector";
import { GeneralSearchResultList } from "../../commonStudio/GeneralSearchResult";
import { RegexInputWithCompletion } from "../../commonStudio/RegexInputWithCompletion";
import { EnigmaStudioContext } from "../../context/EnigmaStudioContext";
import { ReplacementInput } from "../../commonStudio/ReplacementInput";
import { GeneralSearchInput } from "../../../../param/generalSearchInput";

export const CommonSearch: FC = () => {
  const ctx = useContext(EnigmaStudioContext);
  ctx.setEnableLoad(true);
  const [dictionary, setDictionary] = useState("");
  const [searching, setSearching] = useState(false);
  const [result, setResult] = useState<string[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const planKey = searchParams.get("plan") ?? "normal";

  const [queries, setQueries] = useState<GeneralSearchQuery[]>([
    {
      type: "regex",
      pattern: "",
      enabled: true,
    },
  ]);

  const [editableItems, setEditableItems] = useStatePersist<
    { name: string; value: string }[]
  >("enigma-studio-function-search-editable-items", []);

  const [progress, setProgress] = useState<FunctionSearchProgress>({
    startTime: 0,
    currentTime: 0,
    queryProgress: [],
  });
  const [dictionaryFree, setDictionaryFree] = useState("");
  const [minLength, setMinLength] = useState("");
  const [maxLength, setMaxLength] = useState("");
  const [inputTabValue, setInputTabValue] = useState("dictionary");
  const { functionSearch, abort } = useWorker();
  const onSearch = useCallback(() => {
    setResult([]);
    setSearching(true);

    const searchQueries = queries.concat({
      type: "length",
      enabled: true,
      minLength: minLength !== "" ? parseInt(minLength) : "",
      maxLength: maxLength !== "" ? parseInt(maxLength) : "",
    });

    searchQueries.forEach((query) => {
      if (query.type === "regex") {
        query.pattern = query.pattern ?? "";
      }
      if (query.type === "candidateCharacters") {
        query.characters = query.characters ?? "";
      }
    });

    const presetItems: {
      name: string;
      value: string;
    }[] = [];

    const input = {
      queries: searchQueries,
      inputDictionary: inputTabValue === "dictionary" ? dictionary : "free",
      inputDictionaryFree: dictionaryFree,
      listDefinitions: presetItems.concat(editableItems).map((item) => ({
        name: item.name,
        content: item.value,
        loop: true,
      })),
    };
    functionSearch(
      { input: input as GeneralSearchInput },
      (result) => {
        if (result) {
          console.log(result);
          setResult(result);
          setSearching(false);
        }
      },
      (progress) => {
        if (progress) {
          setProgress(progress);
        }
      }
    );
  }, [
    queries,
    minLength,
    maxLength,
    inputTabValue,
    dictionary,
    dictionaryFree,
    editableItems,
    functionSearch,
  ]);

  const onSearchStop = useCallback(() => {
    abort();
  }, [abort]);

  const handleChangeDictionary = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      console.log(event.target.value);
      setDictionary((event.target as HTMLInputElement).value);
    },
    []
  );

  // Input Tab

  const handleInputTabChange = (
    _e: React.MouseEvent<HTMLElement, MouseEvent>,
    newValue: string
  ) => {
    setInputTabValue(newValue);
  };

  const updateQuery = (index: number, field: string, value: any) => {
    const updatedQueries = [...queries] as any[];
    updatedQueries[index][field] = value;
    setQueries(updatedQueries as GeneralSearchQuery[]);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Title>一般検索</Title>
      <Box>
        {queries.map((query, index) => (
          <>
            <Box
              key={index}
              mb={0.5}
              sx={{
                p: 2,
                backgroundColor: "palette.background.paper",
                opacity: query.enabled === false ? 0.4 : 1,
              }}
            >
              <FormControl variant="outlined" sx={{ mb: 2 }}>
                <InputLabel>タイプ</InputLabel>
                <Select
                  value={query.type || ""}
                  onChange={(e) => updateQuery(index, "type", e.target.value)}
                  sx={{
                    width: "200px",
                  }}
                >
                  <MenuItem value="regex">正規表現検索</MenuItem>
                  <MenuItem value="candidateCharacters">候補文字検索</MenuItem>
                  <MenuItem value="regexReplaceSearch">
                    正規表現置換検索
                  </MenuItem>
                </Select>
              </FormControl>

              {query.type === "regex" && (
                <RegexInputWithCompletion
                  pattern={query.pattern}
                  onPatternChange={(newPattern) =>
                    updateQuery(index, "pattern", newPattern)
                  }
                />
              )}
              {query.type === "rhyme" && (
                <>
                  <TextField
                    label=""
                    variant="outlined"
                    value={query.keyword || ""}
                    fullWidth
                    onChange={(e) =>
                      updateQuery(index, "keyword", e.target.value)
                    }
                    sx={{ ml: 1, mb: 2 }}
                  />
                </>
              )}
              {query.type === "candidateCharacters" && (
                <>
                  <TextField
                    label="文字列"
                    variant="outlined"
                    value={query.characters || ""}
                    fullWidth
                    onChange={(e) =>
                      updateQuery(index, "characters", e.target.value)
                    }
                    sx={{ ml: 1, mb: 2 }}
                  />
                  <FormControl
                    variant="outlined"
                    sx={{ ml: 1, width: "200px", mb: 2 }}
                  >
                    <InputLabel>候補タイプ</InputLabel>
                    <Select
                      value={query.candidateType || ""}
                      onChange={(e) =>
                        updateQuery(index, "candidateType", e.target.value)
                      }
                    >
                      <MenuItem value="candidate">から構成される</MenuItem>
                      <MenuItem value="included">を全て含む</MenuItem>
                      <MenuItem value="excluded">を含まない</MenuItem>
                      <MenuItem value="supersequence">
                        がこの順で含まれる
                      </MenuItem>
                      <MenuItem value="subsequence">
                        からこの順で取り出す
                      </MenuItem>
                    </Select>
                  </FormControl>
                </>
              )}
              {query.type === "regexReplaceSearch" && (
                <>
                  <Box>
                    <RegexInputWithCompletion
                      pattern={query.pattern}
                      onPatternChange={(newPattern) =>
                        updateQuery(index, "pattern", newPattern)
                      }
                    />
                    <ReplacementInput
                      replacement={query.replacement || ""}
                      onReplacementChange={(newReplacement) =>
                        updateQuery(index, "replacement", newReplacement)
                      }
                      sx={{ ml: 1, width: "300px" }}
                    />
                  </Box>
                  <Box>
                    <DictionarySelector
                      labelName="置換後辞書"
                      dictionary={query.dictionary}
                      setDictionary={(value) =>
                        updateQuery(index, "dictionary", value)
                      }
                      isDemo={false}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={query.anagramMatch || false}
                          onChange={(e) =>
                            updateQuery(index, "anagramMatch", e.target.checked)
                          }
                        />
                      }
                      sx={{ ml: 1 }}
                      label="アナグラムマッチ"
                    />
                  </Box>
                </>
              )}
            </Box>
          </>
        ))}
      </Box>
      <Box sx={{ mt: 2, ml: 2 }}>
        <TextField
          label="最低文字数"
          variant="outlined"
          type="number"
          value={minLength || ""}
          onChange={(e) => setMinLength(e.target.value)}
          sx={{ width: "120px" }}
        />
        <TextField
          label="最大文字数"
          variant="outlined"
          type="number"
          value={maxLength || ""}
          onChange={(e) => setMaxLength(e.target.value)}
          sx={{ ml: 2, width: "120px" }}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <ToggleButtonGroup
          color="primary"
          value={inputTabValue}
          exclusive
          onChange={handleInputTabChange}
        >
          <ToggleButton value="dictionary">辞書選択</ToggleButton>
          <ToggleButton value="free">自由入力</ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {inputTabValue === "dictionary" && (
        <DictionaryRadioSelector
          dictionary={dictionary}
          onChange={handleChangeDictionary}
        />
      )}
      {inputTabValue === "free" && (
        <Box>
          <TextField
            label="単語リスト"
            variant="outlined"
            value={dictionaryFree}
            onChange={(e) => setDictionaryFree(e.target.value)}
            sx={{ mt: 2, width: "80vw", mb: 2 }}
            multiline
            rows={10}
          />
        </Box>
      )}
      <Box>
        <Button
          variant="contained"
          onClick={onSearch}
          disabled={
            !ctx.loaded ||
            searching ||
            (dictionary === "" && inputTabValue === "dictionary")
          }
        >
          検索
        </Button>
        <Button
          variant="contained"
          onClick={onSearchStop}
          disabled={!searching}
          sx={{ ml: 2 }}
        >
          停止
        </Button>
      </Box>
      <GeneralSearchResultList
        items={result}
        loading={searching}
        progress={progress}
        queries={queries}
        simple={true}
        setFreeInput={(value) => {
          setDictionary("free");
          setDictionaryFree(value);
        }}
      />
    </Container>
  );
};
