import CasinoIcon from "@mui/icons-material/Casino";
import FilterListIcon from "@mui/icons-material/FilterList";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { BarChart, BarSeriesType } from "@mui/x-charts";
import { useSnackbar } from "notistack";
import {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { useStatePersist } from "use-state-persist";
import { useWorker } from "../../../hooks/useWorker";
import { splitIgnoringBraces } from "../../../lib/splitIgnoringBraces";
import { FunctionSearchProgress } from "../../../param/generalSearchProgress";
import { GeneralSearchQuery } from "../../../param/generalSearchQuery";
import { EnigmaStudioContext } from "../context/EnigmaStudioContext";
import FileCopyIcon from "@mui/icons-material/FileCopy";

const ProgressChart = ({
  progress,
  size,
}: {
  progress: FunctionSearchProgress;
  size: number;
}) => {
  if (!progress) {
    return <></>;
  }
  if (progress.startTime === 0) {
    return <></>;
  }
  if (!progress.queryProgress) {
    return <></>;
  }
  const arr = Array(size)
    .fill(0)
    .map((_, i) => i);

  const series = [
    {
      stack: "",
      data: progress.queryProgress.map((q) => q.currentWordCount),
    },
    {
      stack: "",
      data: progress.queryProgress.map(
        (q) => q.totalWordCount - q.currentWordCount
      ),
    },
  ] as BarSeriesType[];

  return (
    <BarChart
      series={series}
      layout="horizontal"
      height={100 + size * 80}
      width={400}
      margin={{ top: 70 }}
      yAxis={[
        {
          id: "queries",
          data: arr.map((_, i) => i + 1),
          scaleType: "band",
          valueFormatter: (value) => value.toString(),
        },
      ]}
      xAxis={[
        {
          scaleType: "linear",
        },
      ]}
    />
  );
};

const renderRow = (props: ListChildComponentProps<string[] | ReactNode[]>) => {
  const { index, style, data } = props;

  return (
    <ListItem
      style={style}
      key={index}
      component="div"
      disablePadding
      sx={{ my: 0, py: 0 }}
    >
      <ListItemText
        primary={data[index]}
        sx={{ my: 0, py: 0, whiteSpace: "nowrap" }}
      />
    </ListItem>
  );
};

type ResultProps = {
  items: string[];
  loading?: boolean;
  progress: FunctionSearchProgress;
  queries: GeneralSearchQuery[];
  simple?: boolean;
  setFreeInput?: (value: string) => void;
};

type SortType = "asc" | "desc" | "asclen" | "desclen";

export const GeneralSearchResultList: FC<ResultProps> = ({
  items,
  loading,
  progress,
  queries,
  simple,
  setFreeInput,
}) => {
  const ctx = useContext(EnigmaStudioContext);
  const worker = useWorker();
  const { enqueueSnackbar } = useSnackbar();

  const loadCustom = (items: string[]) => {
    worker.loadCustomArr(filteredItems, (result: string[] | undefined) => {
      if (result?.sort().join(",") === ctx.enabledDictionary.sort().join(",")) {
        ctx.setLoaded(true);
      }
    });
  };

  const [displayMonitor, setDisplayMonitor] = useStatePersist(
    "grid-result-display-monitor",
    false
  );
  const [pattern, setPattern] = useState("");
  const [filteredItems, setFilteredItems] = useState(items);
  const [sortKey, setSortKey] = useState("1");
  const [sortType, setSortType] = useState("asc" as SortType);
  const [displayFilter, setDisplayFilter] = useStatePersist(
    "grid-result-display-filter",
    false
  );
  const [displayFile, setDisplayFile] = useStatePersist(
    "grid-result-display-file",
    false
  );
  const [resultDisplayFilter, setResultDisplayFilter] = useState<boolean[]>([]);

  const [download, setDownload] = useState("");
  const handleDownload = useCallback(() => {
    setDownload(
      "data:text/plain;charset=utf-8," +
        encodeURIComponent(filteredItems.join("\n"))
    );
  }, [filteredItems]);

  useEffect(() => {
    if (!items) {
      return;
    }
    const reg = new RegExp(pattern, "ug");
    let filtered = items.filter((item) => reg.test(item as string));
    if (sortKey === "") {
      setFilteredItems(filtered);
      return;
    }
    filtered = filtered.sort((a, b) => {
      const aKey = splitIgnoringBraces(a)[parseInt(sortKey) - 1] ?? a;
      const bKey = splitIgnoringBraces(b)[parseInt(sortKey) - 1] ?? b;
      if (sortType === "asclen") {
        const aLen = aKey.length;
        const bLen = bKey.length;
        if (aLen !== bLen) {
          return aLen - bLen;
        }
      } else if (sortType === "desclen") {
        const aLen = aKey.length;
        const bLen = bKey.length;
        if (aLen !== bLen) {
          return bLen - aLen;
        }
      }
      if (["asc", "asclen", "desclen"].includes(sortType)) {
        return aKey.localeCompare(bKey);
      } else {
        return bKey.localeCompare(aKey);
      }
    });

    filtered = filtered.map((item) => {
      const arr = splitIgnoringBraces(item);
      return arr
        .filter((_, i) => {
          if (resultDisplayFilter[i] === false) {
            return false;
          }
          return true;
        })
        .join(",");
    });
    setFilteredItems(filtered);
  }, [pattern, sortKey, sortType, items, resultDisplayFilter]);

  const handleOpenRandomWordSnack = useCallback(() => {
    if (!filteredItems || filteredItems.length === 0) {
      return;
    }
    console.log("enqueueSnackbar");
    enqueueSnackbar(
      filteredItems[Math.floor(Math.random() * filteredItems.length)]
    );
  }, [enqueueSnackbar, filteredItems]);

  const handleSetFreeInput = useCallback(() => {
    if (!filteredItems || filteredItems.length === 0) {
      return;
    }
    console.log(filteredItems.join("\n"));
    setFreeInput?.(filteredItems.join("\n"));
  }, [filteredItems, setFreeInput]);

  if (loading || items == null) {
    return (
      <>
        <Typography mt={2} variant="body1">
          検索中...{" "}
          {((progress.currentTime - progress.startTime) / 1000).toFixed(1)}s
        </Typography>
        {(loading || displayMonitor) &&
          progress &&
          progress.queryProgress &&
          progress.queryProgress.length > 0 && (
            <ProgressChart progress={progress} size={queries.length} />
          )}
      </>
    );
  }

  return (
    <>
      {
        <>
          <Typography mt={2} variant="h4">
            結果
          </Typography>
          <Typography mt={2} variant="body1">
            {!simple && !loading && items.length > 0 && (
              <ToggleButton
                value="monitor"
                selected={displayFilter}
                size="small"
                color="primary"
                onChange={() => {
                  setDisplayFilter(!displayFilter);
                }}
                sx={{ mr: 1 }}
              >
                <FilterListIcon />
              </ToggleButton>
            )}
            {!loading && items.length > 0 && (
              <ToggleButton
                value="random"
                selected={displayFile}
                size="small"
                color="primary"
                onChange={() => {
                  setDisplayFile(!displayFile);
                }}
                sx={{ mr: 1 }}
              >
                <FileCopyIcon />
              </ToggleButton>
            )}
            {!simple && !(loading || !progress || progress.startTime === 0) && (
              <ToggleButton
                value="monitor"
                selected={displayMonitor}
                size="small"
                color="primary"
                onChange={() => {
                  setDisplayMonitor(!displayMonitor);
                }}
                sx={{ mr: 1 }}
              >
                <MonitorHeartIcon />
              </ToggleButton>
            )}
            {!loading && items.length > 0 && (
              <ToggleButton
                value="random"
                size="small"
                color="primary"
                onChange={() => {
                  handleOpenRandomWordSnack();
                }}
                sx={{ mr: 1 }}
              >
                <CasinoIcon />
              </ToggleButton>
            )}
            {items.length}件
          </Typography>
        </>
      }
      {!loading && displayFile && (
        <Typography mt={2} variant="body1">
          {!loading && items.length > 0 && (
            <a href={download} download="download.txt" onClick={handleDownload}>
              ダウンロード
            </a>
          )}
          <Button
            variant="contained"
            sx={{ mt: 2, ml: 2, mr: 2 }}
            onClick={() => {
              navigator.clipboard.writeText(filteredItems.join("\n"));
            }}
          >
            クリップボードにコピー
          </Button>
          <Button
            variant="contained"
            sx={{ mt: 2, mr: 2 }}
            onClick={() => loadCustom(filteredItems as string[])}
          >
            カスタム辞書に設定
          </Button>
          {setFreeInput && (
            <Button
              variant="contained"
              sx={{ mt: 2, mr: 2 }}
              onClick={handleSetFreeInput}
            >
              自由入力に設定
            </Button>
          )}
        </Typography>
      )}

      <Box sx={{ mb: 2 }}>
        {!loading &&
          displayMonitor &&
          (progress?.queryProgress ?? []).map((q, i) => {
            const time = ((q.endTime ?? q.startTime) - q.startTime) / 1000;
            return (
              <Box key={i} sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body1">
                  Query{i + 1}: {q.currentWordCount}
                  {"words "}
                </Typography>
                <Typography variant="body1" sx={{ ml: 2 }}>
                  {time.toFixed(3)}s
                </Typography>
              </Box>
            );
          })}
      </Box>
      {!loading && items.length > 0 && displayFilter && (
        <>
          <Box sx={{ mb: 2 }}>
            {!simple && (
              <FormControl variant="outlined" sx={{ mb: 2 }}>
                <InputLabel>ソートキー</InputLabel>
                <Select
                  value={sortKey || ""}
                  onChange={(e) => setSortKey(e.target.value as string)}
                  sx={{
                    width: "130px",
                  }}
                >
                  {splitIgnoringBraces(items[0]).map((_, i) => (
                    <MenuItem key={i} value={i + 1}>
                      トレース:{i + 1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <FormControl variant="outlined" sx={{ mb: 2 }}>
              <InputLabel>ソートタイプ</InputLabel>
              <Select
                value={sortType || ""}
                onChange={(e) => setSortType(e.target.value as SortType)}
                sx={{
                  width: "130px",
                }}
              >
                <MenuItem value="asc">昇順</MenuItem>
                <MenuItem value="desc">降順</MenuItem>
                <MenuItem value="asclen">文字数昇順</MenuItem>
                <MenuItem value="desclen">文字数降順</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="検索"
              variant="outlined"
              value={pattern || ""}
              onChange={(e) => setPattern(e.target.value)}
              sx={{ ml: 1, mb: 2 }}
            />
          </Box>
          {!simple && (
            <Box sx={{ mb: 2 }}>
              トレース表示:
              {splitIgnoringBraces(items[0]).map((_, i) => (
                <Checkbox
                  key={i}
                  checked={
                    resultDisplayFilter[i] === undefined
                      ? true
                      : resultDisplayFilter[i]
                  }
                  size="small"
                  onChange={(e) => {
                    const checked =
                      resultDisplayFilter[i] === undefined
                        ? true
                        : resultDisplayFilter[i];
                    const newResultDisplayFilter = [...resultDisplayFilter];
                    newResultDisplayFilter[i] = !checked;
                    setResultDisplayFilter(newResultDisplayFilter);
                  }}
                />
              ))}
            </Box>
          )}
        </>
      )}
      {!loading && (
        <Box
          sx={{
            width: "100%",
            height: 600,
            bgcolor: "background.paper",
          }}
        >
          <FixedSizeList
            height={600}
            width={"100%"}
            itemSize={24}
            itemCount={filteredItems.length}
            overscanCount={20}
            itemData={filteredItems}
          >
            {renderRow}
          </FixedSizeList>
        </Box>
      )}
    </>
  );
};
