import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MessageIcon from "@mui/icons-material/Message";
import { IconButton, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC, useState } from "react";
import { Draggable } from "../../../../../lib/react-smooth-dnd";
import { GeneralSearchQuery } from "../../../../../param/generalSearchQuery";
import { QueryTypeChip } from "./QueryTypeChip";
import { CandidateCharactersForm } from "./form/CandidateCharactersForm";
import { ConditionalTransformForm } from "./form/ConditionalTransformForm";
import { DictionaryMatchForm } from "./form/DictionaryMatchForm";
import { FishboneFilterForm } from "./form/FishBoneFilterForm";
import { IdenticalTraceFilterForm } from "./form/IdenticalTraceFilterForm";
import { KeyValueMatchForm } from "./form/KeyValueMatchForm";
import { LengthForm } from "./form/LengthForm";
import { ParallelTransformForm } from "./form/ParallelTransformForm";
import { RegexFilterForm } from "./form/RegexFilterForm";
import { RegexMatchCompareForm } from "./form/RegexMatchCompareForm";
import { RegexReplaceForm } from "./form/RegexReplaceForm";
import { SequenceForm } from "./form/SequenceForm";
import { TraceGroupingForm } from "./form/TraceGroupingForm";

type QueryFormProps = {
  index: number;
  query: GeneralSearchQuery;
  updateQuery: (index: number, field: string, value: any) => void;
  removeQuery: (index: number) => void;
  displayTraceNumber: boolean;
  queries: GeneralSearchQuery[];
  sequencialQueries: GeneralSearchQuery[];
  traceCounter: number[];
  setQueries: (queries: GeneralSearchQuery[]) => void;
  setQuery: (query: string) => void;
  displayTooltip: boolean;
  isDemo: boolean;
  onSelect: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    contentIndex: number
  ) => void;
  setHoveredContentsIndex: (index: number) => void;
  hoveredContentsIndex: number;
};

export const QueryForm: FC<QueryFormProps> = ({
  index,
  query,
  updateQuery,
  removeQuery,
  displayTraceNumber,
  queries,
  sequencialQueries,
  traceCounter,
  setQueries,
  setQuery,
  displayTooltip,
  isDemo,
  onSelect,
  setHoveredContentsIndex,
  hoveredContentsIndex,
}) => {
  const [displayMessageEdit, setDisplayMessageEdit] = useState(false);
  return (
    <>
      {displayTraceNumber &&
        traceCounter[index] !== traceCounter[index - 1] && (
          <Typography
            variant="body2"
            sx={{
              color: "#ab47bc",
              my: 0,
              mb: 0.5,
              py: 0,
            }}
          >
            トレース: [{traceCounter[index]}]
          </Typography>
        )}
      <Draggable>
        <Box
          key={index}
          mb={0.5}
          sx={{
            p: 1,
            backgroundColor: "palette.background.paper",
            opacity: query?.enabled === false ? 0.4 : 1,
          }}
          border={1}
          borderColor="#ccc"
          className="edit-box"
        >
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              my: 0,
              py: 0,
              cursor: "move",
            }}
            className="drag-handle"
            onMouseDown={(e) => {
              onSelect(e, index);
            }}
            onMouseEnter={() => {
              setHoveredContentsIndex(index);
            }}
            onMouseLeave={() => {
              setHoveredContentsIndex(-1);
            }}
          >
            <DragIndicatorIcon
              sx={{
                position: "relative",
                mx: "auto",
                transform: "rotate(90deg)",
              }}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <IconButton
              edge="start"
              size="small"
              onClick={() => {
                // toggle enabled
                const updatedQueries = [...queries];
                updatedQueries[index].enabled =
                  updatedQueries[index].enabled === undefined ||
                  updatedQueries[index].enabled === true
                    ? false
                    : true;
                setQueries(updatedQueries);
                setQuery(JSON.stringify(updatedQueries));
              }}
            >
              <VisibilityIcon fontSize="inherit" />
            </IconButton>

            <IconButton
              edge="start"
              size="small"
              disabled={index === 0 || isDemo}
              onClick={() => {
                const updatedQueries = [...queries];
                const tmp = { ...updatedQueries[index] };
                updatedQueries[index] = updatedQueries[index - 1];
                updatedQueries[index - 1] = tmp;
                setQueries(updatedQueries);
                setQuery(JSON.stringify(updatedQueries));
              }}
            >
              <KeyboardArrowUpIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              edge="start"
              size="small"
              onClick={() => {}}
              disabled={index === queries.length - 1 || isDemo}
            >
              <KeyboardArrowDownIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              edge="start"
              size="small"
              onClick={() => {
                const updatedQueries = [...queries];
                updatedQueries.splice(index + 1, 0, { ...query });
                setQueries(updatedQueries);
                setQuery(JSON.stringify(updatedQueries));
              }}
            >
              <ContentCopyIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              edge="start"
              size="small"
              onClick={() => {
                removeQuery(index);
              }}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              edge="start"
              size="small"
              color={displayMessageEdit ? "primary" : "inherit"}
              onClick={() => {
                setDisplayMessageEdit(!displayMessageEdit);
              }}
            >
              <MessageIcon fontSize="inherit" />
            </IconButton>
          </Box>
          <>
            {displayMessageEdit ? (
              <TextField
                fullWidth
                multiline
                label="コメント"
                value={query?.comment ?? ""}
                onChange={(e) => {
                  updateQuery(index, "comment", e.target.value);
                }}
                size="small"
                sx={{
                  my: 0,
                  mb: 0.5,
                  py: 0,
                  fontSize: "0.8rem",
                }}
              />
            ) : (
              query?.comment && (
                <Typography
                  variant="body2"
                  sx={{
                    color: "#444",
                    my: 0,
                    mb: 0.5,
                    py: 0,
                  }}
                >
                  {query.comment.split("\n").map((line, i) => (
                    <span key={i}>
                      {line}
                      <br />
                    </span>
                  ))}
                </Typography>
              )
            )}
          </>

          <QueryTypeChip query={query} isDemo={isDemo} />
          {query?.type === "regex" && (
            <RegexFilterForm
              index={index}
              query={query}
              updateQuery={updateQuery}
              isDemo={isDemo}
            />
          )}
          {query?.type === "dictionaryMatch" && (
            <DictionaryMatchForm
              index={index}
              query={query}
              updateQuery={updateQuery}
              isDemo={isDemo}
            />
          )}
          {query?.type === "keyValueMatch" && (
            <KeyValueMatchForm
              index={index}
              query={query}
              updateQuery={updateQuery}
              isDemo={isDemo}
            />
          )}
          {query?.type === "traceGrouping" && (
            <TraceGroupingForm
              index={index}
              query={query}
              updateQuery={updateQuery}
              isDemo={isDemo}
              traceCount={traceCounter[index]}
            />
          )}
          {query?.type === "identicalTrace" && (
            <IdenticalTraceFilterForm
              index={index}
              query={query}
              updateQuery={updateQuery}
              isDemo={isDemo}
            />
          )}
          {query?.type === "length" && (
            <LengthForm
              index={index}
              query={query}
              updateQuery={updateQuery}
              isDemo={isDemo}
            />
          )}

          {query?.type === "candidateCharacters" && (
            <CandidateCharactersForm
              index={index}
              query={query}
              updateQuery={updateQuery}
              isDemo={isDemo}
            />
          )}
          {query?.type === "regexMatchCompare" && (
            <RegexMatchCompareForm
              index={index}
              query={query}
              updateQuery={updateQuery}
              displayTraceNumber={displayTraceNumber}
              queries={sequencialQueries}
              isDemo={isDemo}
            />
          )}
          {query?.type === "regexReplace" && (
            <RegexReplaceForm
              index={index}
              query={query}
              updateQuery={updateQuery}
              isDemo={isDemo}
            />
          )}
          {query?.type === "parallelTransform" && (
            <ParallelTransformForm
              index={index}
              query={query}
              updateQuery={updateQuery}
              isDemo={isDemo}
            />
          )}
          {query?.type === "conditionalTransform" && (
            <ConditionalTransformForm
              index={index}
              query={query}
              updateQuery={updateQuery}
              isDemo={isDemo}
            />
          )}
          {query?.type === "fishBone" && (
            <FishboneFilterForm
              index={index}
              query={query}
              updateQuery={updateQuery}
              isDemo={isDemo}
            />
          )}

          {query?.type === "sequence" && (
            <SequenceForm
              index={index}
              query={query}
              sequencialQueries={sequencialQueries}
              displayTraceNumber={displayTraceNumber}
              updateQuery={updateQuery}
              onDelete={() => removeQuery(index)}
              onClick={() => {
                // toggle enabled
                const updatedQueries = [...queries];
                updatedQueries[index].enabled =
                  updatedQueries[index].enabled === undefined ||
                  updatedQueries[index].enabled === true
                    ? false
                    : true;
                setQueries(updatedQueries);
                setQuery(JSON.stringify(updatedQueries));
              }}
              displayTooltip={displayTooltip}
              isDemo={isDemo}
            />
          )}
        </Box>
      </Draggable>
    </>
  );
};
