export const defaultAppsData = [
  {
    fileType: "folder",
    name: "フォルダ",
    description: "アイテムを格納します",
  },
  {
    fileType: "image-library",
    name: "公開アルバム",
    description: "画像を格納します",
  },
  {
    fileType: "image",
    name: "画像",
    description: "jpg, png, gif, svgなどの画像ファイル",
  },
  {
    fileType: "rich-text",
    name: "文書作成",
    description: "",
  },
  {
    fileType: "word-generator",
    name: "ワードジェネレータ",
    description: "万能テキスト生成・変換・加工ツール",
  },
  {
    fileType: "general-search",
    name: "汎用検索",
    description: "高機能ワードサーチツール",
  },
  {
    fileType: "gridKit",
    name: "GridKit",
    description: "盤面系謎解き・パズル等の制作ツール",
  },
  {
    fileType: "viz",
    name: "ネットワークグラフ",
    description: "",
  },
  {
    fileType: "stack-space",
    name: "StackSpace",
    description: "直列型サイト作成システム",
  },
  {
    fileType: "excalidraw",
    name: "Excalidraw",
    description: "手書き図形作成ツール",
  },
  {
    fileType: "answerkit",
    name: "AnswerKit",
    description: "汎用解答判定システム",
  },
  {
    fileType: "puzsq-contest-folder",
    name: "コンテストフォルダ",
    description: "パズルに特化したコンテスト・イベント管理システム",
  },
  {
    fileType: "gridsheet",
    name: "GridSheet",
    description: "表計算ツール",
  },
];
