import { Button, FormControlLabel, Switch } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import { PlainText } from "../../../common/PlainText";
import { Pos, WhiteLineCandidate } from "../gridToolType";
import { ModeType } from "../Menu/ModeSelector";

type SkeletonMenuProps = {
  mode: ModeType | null;
  whiteList: Pos[];
  whiteLineCandidateList: WhiteLineCandidate[][];
  setWhiteLineCandidateList: React.Dispatch<
    React.SetStateAction<WhiteLineCandidate[][]>
  >;
  solveSkeleton: () => void;
  realtimeSkeletonSearch: boolean;
  setRealtimeSkeletonSearch: React.Dispatch<React.SetStateAction<boolean>>;
  displaySkeletonList: boolean;
  setDisplaySkeletonList: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SkeletonMenu: FC<SkeletonMenuProps> = ({
  mode,
  whiteList,
  whiteLineCandidateList,
  setWhiteLineCandidateList,
  solveSkeleton,
  realtimeSkeletonSearch,
  setRealtimeSkeletonSearch,
  displaySkeletonList,
  setDisplaySkeletonList,
}) => {
  if (mode !== "skeleton") {
    return <></>;
  }

  return (
    <>
      {whiteList.length === 0 && (
        <PlainText>白マスツールで盤面を作成してください</PlainText>
      )}
      {
        <Box>
          <Button
            variant="contained"
            onClick={solveSkeleton}
            disabled={whiteList.length === 0}
            sx={{ mr: 1 }}
          >
            スケルトン解答補助
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setWhiteLineCandidateList([]);
            }}
            disabled={whiteLineCandidateList.length === 0}
          >
            リセット
          </Button>
        </Box>
      }
      <Box>
        <FormControlLabel
          control={
            <Switch
              checked={realtimeSkeletonSearch}
              onChange={(e) => setRealtimeSkeletonSearch(e.target.checked)}
            />
          }
          sx={{ ml: 0, mt: 1 }}
          label="リアルタイム解答"
        />
      </Box>
      <Box>
        <FormControlLabel
          control={
            <Switch
              checked={displaySkeletonList}
              onChange={(e) => setDisplaySkeletonList(e.target.checked)}
            />
          }
          sx={{ ml: 0, mt: 1 }}
          label="リストを表示"
        />
      </Box>
    </>
  );
};
