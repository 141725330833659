import {
  Card,
  CardActionArea,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { FileTypeIcon as DefaultFileTypeIcon } from "../TypeIcon";
import { defaultAppsData } from "./apps/data/appsData";

export type AppProps = {
  createDocument: (type: string) => void;
  appsData?: { fileType: string; name: string; description: string }[];
  FileTypeIcon?: FC<{ fileType: string; sx?: any }>;
};

export const App: FC<AppProps> = ({
  createDocument,
  appsData = defaultAppsData,
  FileTypeIcon = DefaultFileTypeIcon,
}) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        verticalAlign: "bottom",
        mb: 2,
      }}
      useFlexGap
      flexWrap={"wrap"}
    >
      {appsData.map((app) => {
        return (
          <Card sx={{ width: 240 }} key={app.fileType}>
            <CardActionArea
              onClick={() => {
                createDocument(app.fileType);
              }}
            >
              <CardContent>
                <FileTypeIcon
                  fileType={app.fileType}
                  sx={{
                    fontSize: 80,
                  }}
                />
                <Typography gutterBottom variant="h6" component="div">
                  {app.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {app.description}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        );
      })}
    </Stack>
  );
};
