import { TextField } from "@mui/material";
import { FC } from "react";
import { BasicFormProps } from "../FormProps";
import { LengthFilter } from "../../../../../../param/generalSearchFilter";

export const LengthForm: FC<BasicFormProps<LengthFilter>> = ({
  index,
  query,
  updateQuery,
  isDemo,
}) => {
  return (
    <>
      <TextField
        label="最低長"
        variant="outlined"
        type="number"
        value={query.minLength || ""}
        disabled={isDemo}
        onChange={(e) => updateQuery(index, "minLength", e.target.value)}
        sx={{ ml: 1, mb: 2 }}
      />
      <TextField
        label="最大長"
        variant="outlined"
        type="number"
        value={query.maxLength || ""}
        disabled={isDemo}
        onChange={(e) => updateQuery(index, "maxLength", e.target.value)}
        sx={{ ml: 1, mb: 2 }}
      />
    </>
  );
};
