import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Sequence } from "../../../../../../../param/generalSearchQuery";
import { RegexReplace } from "../../../../../../../param/generalSearchTransform";
import { BasicFormProps } from "../../FormProps";

export const KanaToRomaForm: FC<BasicFormProps<Sequence>> = (props) => {
  const { index, query, updateQuery, isDemo, onBlur } = props;

  const replacementToRomaType = (replacement: string) => {
    const match = replacement.match(/roma\([^,]*,([a-z]*)\)/);
    if (!match) return;
    return match[1].includes("t")
      ? match[1].includes("k")
        ? "kt"
        : "ht"
      : match[1].includes("k")
      ? "k"
      : "h";
  };

  const replacementToLongVowel = (replacement: string) => {
    const match = replacement.match(/roma\([^,]*,[^l]*(l*)[^l]*\)/);
    if (!match) return;
    return match[1];
  };

  const [romaType, setRomaType] = useState(
    replacementToRomaType(
      (query.processes[0] as RegexReplace)?.replacement ?? ""
    ) || "h"
  );
  const [longVowel, setLongVowel] = useState(
    replacementToLongVowel(
      (query.processes[0] as RegexReplace)?.replacement ?? ""
    ) || ""
  );

  useEffect(() => {
    query.processes = query.processes || [
      {
        type: "regexReplace",
        pattern: "",
        replacement: "",
      },
    ];
    const process = query.processes[0];
    if (process.type !== "regexReplace") return;

    if (process.replacement === `roma($1,${romaType}${longVowel})`) return;
    process.pattern = "";
    process.replacement = `roma($1,${romaType}${longVowel})`;
    updateQuery(index, "processes", query.processes);
  }, [romaType, longVowel, query, updateQuery, index]);

  return (
    <Box>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={(e) => {
            setRomaType(e.target.value);
          }}
          value={romaType}
        >
          <FormControlLabel
            value="h"
            control={<Radio />}
            label="ヘボン式"
            disabled={isDemo}
            onBlur={onBlur}
          />
          <FormControlLabel
            value="k"
            control={<Radio />}
            label="訓令式"
            disabled={isDemo}
            onBlur={onBlur}
          />
          <FormControlLabel
            value="ht"
            control={<Radio />}
            label="ヘボン風タイピング"
            disabled={isDemo}
            onBlur={onBlur}
          />
          <FormControlLabel
            value="kt"
            control={<Radio />}
            label="訓令風タイピング"
            disabled={isDemo}
            onBlur={onBlur}
          />
        </RadioGroup>
      </FormControl>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={longVowel === "l"}
              onChange={(e) => {
                if (e.target.checked) {
                  setLongVowel("l");
                } else {
                  setLongVowel("");
                }
              }}
              onBlur={onBlur}
            />
          }
          label="長音の母音化"
          disabled={isDemo}
        />
      </FormGroup>
    </Box>
  );
};
