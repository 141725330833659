import { TextField } from "@mui/material";
import { FC } from "react";
import { BasicFormProps } from "../FormProps";
import { KeyValueMatchFilter } from "../../../../../../param/generalSearchFilter";

export const KeyValueMatchForm: FC<BasicFormProps<KeyValueMatchFilter>> = ({
  index,
  query,
  updateQuery,
  isDemo,
}) => {
  return (
    <>
      <TextField
        label="Key"
        variant="outlined"
        value={query.key || ""}
        disabled={isDemo}
        onChange={(e) => updateQuery(index, "key", e.target.value)}
        sx={{ ml: 1, mb: 2 }}
      />
      <TextField
        label="Value"
        variant="outlined"
        value={query.value || ""}
        disabled={isDemo}
        onChange={(e) => updateQuery(index, "value", e.target.value)}
        sx={{ ml: 1, mb: 2 }}
      />
    </>
  );
};
