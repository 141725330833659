import {
  Box,
  Button,
  Container,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { FC, useCallback, useState } from "react";
import { Label, Layer, Rect, Stage, Text } from "react-konva";
import { useStatePersist } from "use-state-persist";
import { hiraganaToKatakana } from "../../../../lib/wordGenerator/convert";
import { PlainText } from "../../common/PlainText";
import { Title } from "../../common/Title";
import {
  ArtboardMenu,
  ArtboardRange,
  useArtboard,
  useArtboardHandler,
} from "./ArtBoard";
import { BlackListCell } from "./Square/BlackList";
import { CharTypeSelector } from "./Char/CharType";
import { GridLine } from "./Grid";
import { ModeSelector, useMode } from "./Menu/ModeSelector";
import { SaveMenu } from "./Save";
import { SkeletonCandidateView } from "./Skeleton/SkeletonCandidateView";
import { SkeletonListView, useSkeletonList } from "./Skeleton/SkeletonList";
import { SkeletonMenu } from "./Skeleton/SkeletonMenu";
import { useSolveSkeleton } from "./Skeleton/useSkeletonSolver";
import { SquareMenu } from "./Square/SquareMenu";
import { TextMenu } from "./Text/TextMenu";
import { useTextDrag } from "./Text/useTextDrag";
import { WhiteListView, useHandleWhiteList } from "./Square/WhiteList";
import { WordListView } from "./Text/WordListView";
import { Pos } from "./gridToolType";
import { useCrosswordNumber } from "./useCrosswordNumber";
import { useHistory } from "./useHistory";
import { useKeydownHandler } from "./useKeydownHandler";
import { useLine } from "./Text/useLine";
import { useWordList } from "./Text/useWordList";
import { useWordListToGrid } from "./Text/wordListToGrid";
import { SquareView } from "./Square/SquareView";
import { WhiteSquareMenu } from "./Square/WhiteSquareMenu";

const getPosFromEvent = (e: any) => {
  if (e && e.evt && e.evt.touches && e.evt.touches.length > 0) {
    return { x: e.evt.touches[0].clientX, y: e.evt.touches[0].clientY };
  }
  return { x: e.evt.clientX, y: e.evt.clientY };
};

const maxWidth = 868;
const maxHeight = 1636;

const col = 32;
const row = 64;
const size = 48;

const gridOffsetX = 100;
const gridOffsetY = 100;
const scale = 0.5;

const posToCoord = (x: number, y: number, scale: number = 1) => {
  return {
    x: Math.floor((x - gridOffsetX) / size / scale),
    y: Math.floor((y - gridOffsetY) / size / scale),
  };
};

export type CharTypeValue = "hiragana" | "katakana" | "alphabet";

export const GridTool: FC = () => {
  /**
   * State
   */
  const { rawWordList, setRawWordList, wordList, setWordList } = useWordList();
  const [dragging, setDragging] = useState<boolean>(false);

  const { artboard, setArtboard } = useArtboard(col, row);
  const {
    history,
    setHistory,
    historyIndex,
    setHistoryIndex,
    setIsUndoRedoAction,
  } = useHistory({
    dragging,
    rawWordList,
    wordList,
  });

  const [initialCursor, setInitialCursor] = useState<Pos>({
    x: 0,
    y: 0,
  });

  const [initialPos, setInitialPos] = useState<Pos>({
    x: 0,
    y: 0,
  });

  const [initialPosList, setInitialPosList] = useState<Pos[]>([]);

  const [blackList, setBlackList] = useStatePersist<Pos[]>(
    "grid-tool-black-list",
    []
  );
  const [whiteList, setWhiteList] = useStatePersist<Pos[]>(
    "grid-tool-white-list",
    []
  );

  const { whiteLines, rightIndexCrossPointTable, downIndexCrossPointTable } =
    useLine(whiteList);

  const [realtimeSkeletonSearch, setRealtimeSkeletonSearch] = useState(false);

  const [cursor, setCursor] = useState<Pos>({
    x: 0,
    y: 0,
  });

  const [displayText, setDisplayText] = useState<boolean>(false);

  const [cursorText, setCursorText] = useStatePersist<{
    [key: string]: string;
  }>("grid-tool-cursor-text", {});

  const [cursorTextKana, setCursorTextKana] = useStatePersist<{
    [key: string]: string;
  }>("grid-tool-cursor-text-kana", {});
  const [whiteInputMode, setWhiteInputMode] = useState<"add" | "delete">("add");

  const {
    whiteLineCandidateList,
    wordCandidateList,
    solveSkeleton,
    setWhiteLineCandidateList,
    setWordCandidateList,
  } = useSolveSkeleton({
    whiteLines,
    wordList,
    rightIndexCrossPointTable,
    downIndexCrossPointTable,
    dragging,
    cursorText,
    cursorTextKana,
    realtimeSkeletonSearch,
  });

  /**
   * Handler
   */

  // Undo 処理
  const undo = useCallback(() => {
    if (historyIndex > 0) {
      setIsUndoRedoAction(true);
      const prevState = history[historyIndex - 1];
      setRawWordList(prevState.raw);
      setWordList(prevState.word);
      setHistoryIndex((prev) => prev - 1);
    }
  }, [history, historyIndex, setHistoryIndex, setRawWordList, setWordList]);

  // Redo 処理
  const redo = useCallback(() => {
    if (historyIndex < history.length - 1) {
      setIsUndoRedoAction(true);
      const nextState = history[historyIndex + 1];
      setRawWordList(nextState.raw);
      setWordList(nextState.word);
      setHistoryIndex((prev) => prev + 1);
    }
  }, [history, historyIndex, setHistoryIndex, setRawWordList, setWordList]);

  const [directionValue, setDirectionValue] = useState("two");

  const [displayCrosswordNumberType, setDisplayCrosswordNumberType] = useState<
    "horizontal" | "vertical" | "none"
  >("none");

  const { mode, handleMode } = useMode();
  const { crosswordNumber } = useCrosswordNumber({
    artboard,
    blackList,
    displayCrosswordNumberType,
  });

  const handleDirectionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDirectionValue((event.target as HTMLInputElement).value);
  };

  const handleDisplayCrosswordNumberTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDisplayCrosswordNumberType(
      (event.target as HTMLInputElement).value as
        | "horizontal"
        | "vertical"
        | "none"
    );
  };

  const handleTextDrag = useTextDrag({
    wordList,
    setWordList,
    size,
    getPosFromEvent,
    initialCursor,
    initialPos,
    setInitialCursor,
    setInitialPos,
    setInitialPosList,
    dragging,
    setDragging,
  });

  const { handleWhiteListStart, handleWhiteListMove, handleWhiteListEnd } =
    useHandleWhiteList({
      whiteList,
      setWhiteList,
      dragging,
      setDragging,
      posToCoord,
    });

  const { wordListToGrid } = useWordListToGrid({ artboard });
  const [displayDiceNumber, setDisplayDiceNumber] = useState<boolean>(false);
  const [displaySkeletonList, setDisplaySkeletonList] =
    useState<boolean>(false);

  const { skeletonList } = useSkeletonList({
    displaySkeletonList,
    whiteLineCandidateList,
    wordCandidateList,
    wordList,
  });

  const {
    handleArtboardDragStart,
    handleArtboardDragMove,
    handleArtboardDragEnd,
  } = useArtboardHandler({
    artboard,
    setArtboard,
    dragging,
    setDragging,
    size,
    posToCoord,
    initialCursor,
    setInitialCursor,
  });

  const [charTypeValue, setCharTypeValue] = useState<CharTypeValue>("hiragana");

  const handleCharTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCharTypeValue((event.target as HTMLInputElement).value as CharTypeValue);
  };

  useKeydownHandler({
    mode,
    cursor,
    setCursor,
    whiteList,
    setWhiteList,
    blackList,
    setBlackList,
    cursorText,
    setCursorText,
    cursorTextKana,
    setCursorTextKana,
    whiteInputMode,
    setWhiteInputMode,
    charTypeValue,
    row,
    col,
  });

  const handleSquareClick = (e: any) => {
    const cursor = e.target.getStage()?.getPointerPosition();
    if (!cursor) {
      return;
    }
    const x = Math.floor((cursor.x - 50) / size / scale);
    const y = Math.floor((cursor.y - 50) / size / scale);
    if (mode === "square") {
      const newBlackList = [...blackList];

      if (x < 0 || x >= col || y < 0 || y >= row) {
        return;
      }

      if (newBlackList.some((b) => b.x === x && b.y === y)) {
        newBlackList.splice(
          newBlackList.findIndex((b) => b.x === x && b.y === y),
          1
        );
      } else {
        newBlackList.push({ x, y });
      }
      setBlackList(newBlackList);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Title>方眼ツール</Title>
      <ModeSelector mode={mode} handleMode={handleMode} />
      <Box
        sx={{
          my: 2,
        }}
      >
        <ArtboardMenu
          mode={mode}
          setArtboard={setArtboard}
          col={col}
          row={row}
          whiteList={whiteList}
          blackList={blackList}
        />
        <SquareMenu
          mode={mode}
          blackList={blackList}
          whiteList={whiteList}
          artboard={artboard}
          displayCrosswordNumberType={displayCrosswordNumberType}
          setBlackList={setBlackList}
          handleDisplayCrosswordNumberTypeChange={
            handleDisplayCrosswordNumberTypeChange
          }
        />
        <SaveMenu
          mode={mode}
          wordList={wordList}
          blackList={blackList}
          whiteList={whiteList}
          artboard={artboard}
          cursorText={cursorText}
          cursorTextKana={cursorTextKana}
          setWordList={setWordList}
          setBlackList={setBlackList}
          setWhiteList={setWhiteList}
          setArtboard={setArtboard}
          setCursorText={setCursorText}
          setCursorTextKana={setCursorTextKana}
          setRawWordList={setRawWordList}
          wordListToGrid={wordListToGrid}
          col={col}
          row={row}
        />
        <WhiteSquareMenu
          mode={mode}
          artboard={artboard}
          whiteList={whiteList}
          blackList={blackList}
          setWhiteList={setWhiteList}
          setWhiteLineCandidateList={setWhiteLineCandidateList}
          setWordCandidateList={setWordCandidateList}
        />
        <TextMenu
          mode={mode}
          undo={undo}
          redo={redo}
          historyIndex={historyIndex}
          history={history}
          setRawWordList={setRawWordList}
          setWordList={setWordList}
          setHistory={setHistory}
          setHistoryIndex={setHistoryIndex}
          rawWordList={rawWordList}
          displayText={displayText}
          setDisplayText={setDisplayText}
          directionValue={directionValue}
          handleDirectionChange={handleDirectionChange}
        />
        {mode === "char" && (
          <Box>
            <PlainText>
              マスに文字を直接入力できます。半角英数モードで、カナはローマ字で入力します
            </PlainText>
            <Box>
              <Button
                variant="contained"
                onClick={() => {
                  setCursorText({});
                  setCursorTextKana({});
                }}
                sx={{ mr: 2 }}
              >
                文字を初期化
              </Button>
            </Box>
            <CharTypeSelector
              charTypeValue={charTypeValue}
              handleCharTypeChange={handleCharTypeChange}
            />
          </Box>
        )}
        {mode === "dice" && (
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={displayDiceNumber}
                  onChange={(e) => setDisplayDiceNumber(e.target.checked)}
                />
              }
              sx={{ ml: 0, mt: 1 }}
              label="ダイスの目を表示"
            />
          </Box>
        )}
        <SkeletonMenu
          mode={mode}
          whiteList={whiteList}
          whiteLineCandidateList={whiteLineCandidateList}
          setWhiteLineCandidateList={setWhiteLineCandidateList}
          solveSkeleton={solveSkeleton}
          realtimeSkeletonSearch={realtimeSkeletonSearch}
          setRealtimeSkeletonSearch={setRealtimeSkeletonSearch}
          displaySkeletonList={displaySkeletonList}
          setDisplaySkeletonList={setDisplaySkeletonList}
        />
        <SkeletonListView
          visible={displaySkeletonList}
          skeletonList={skeletonList}
        />
      </Box>
      <Box>
        {[
          "artboard",
          "square",
          "white-square",
          "text",
          "dice",
          "char",
          "select",
          "skeleton",
          "drag",
          "save",
        ].includes(mode ?? "text") && (
          <Stage
            width={maxWidth}
            height={maxHeight}
            scaleX={scale}
            scaleY={scale}
          >
            <Layer>
              <Rect
                x={0}
                y={0}
                width={maxWidth / scale}
                height={maxHeight / scale}
                fill="#d8d8d8"
              />
              <Rect
                x={gridOffsetX + artboard.x * size}
                y={gridOffsetY + artboard.y * size}
                width={artboard.width * size}
                height={artboard.height * size}
                fill="#fff"
              />
              <WhiteListView
                mode={mode}
                whiteList={whiteList}
                setWhiteList={setWhiteList}
                size={size}
                gridOffsetX={gridOffsetX}
                gridOffsetY={gridOffsetY}
              />
              <GridLine
                row={row}
                col={col}
                size={size}
                gridOffsetX={gridOffsetX}
                gridOffsetY={gridOffsetY}
                artboard={artboard}
              />
              <BlackListCell
                mode={mode}
                size={size}
                blackList={blackList}
                setBlackList={setBlackList}
                gridOffsetX={gridOffsetX}
                gridOffsetY={gridOffsetY}
              />
              <WordListView
                displayText={displayText}
                wordList={wordList}
                setWordList={setWordList}
                mode={mode}
                size={size}
                handleTextDrag={handleTextDrag}
                gridOffsetX={gridOffsetX}
                gridOffsetY={gridOffsetY}
                directionValue={directionValue}
              />
              <SkeletonCandidateView
                size={size}
                gridOffsetX={gridOffsetX}
                gridOffsetY={gridOffsetY}
                whiteLineCandidateList={whiteLineCandidateList}
                whiteLines={whiteLines}
                wordList={wordList}
                rightIndexCrossPointTable={rightIndexCrossPointTable}
                downIndexCrossPointTable={downIndexCrossPointTable}
              />
              {displayCrosswordNumberType &&
                Object.entries(crosswordNumber).map(([key, value]) => {
                  const [x, y] = key.split("-").map((e) => parseInt(e));
                  return (
                    <Label
                      keys={`crossword-number-${key}`}
                      x={(artboard.x + x) * size + gridOffsetX + 4} // 画面上での座標
                      y={(artboard.y + y) * size + gridOffsetY + 4} // 画面上での座標
                      width={size}
                      height={size}
                    >
                      <Text
                        fontFamily="Arial"
                        fontSize={size * 0.4}
                        align="left"
                        verticalAlign="top"
                        width={size}
                        height={size}
                        fill={mode === "square" ? "#222" : "#888"}
                        text={value.toString()}
                      ></Text>
                    </Label>
                  );
                })}
              {
                // cursor text
                Object.entries(
                  charTypeValue === "alphabet" ? cursorText : cursorTextKana
                ).map(([key, value]) => {
                  const [x, y] = key.split("-").map((e) => parseInt(e));
                  return (
                    <Label
                      keys={`cursor-text-${key}`}
                      x={(x - 0.25) * size + gridOffsetX} // 画面上での座標
                      y={y * size + gridOffsetY} // 画面上での座標
                      width={size * 1.5}
                      height={size}
                    >
                      <Text
                        fontFamily="Arial"
                        fontSize={size * 0.9}
                        align="center"
                        verticalAlign="bottom"
                        width={size * 1.5}
                        height={size}
                        fill={"#494"}
                        fontStyle="bold"
                        text={
                          charTypeValue === "katakana"
                            ? hiraganaToKatakana(value)
                            : value
                        }
                      />
                    </Label>
                  );
                })
              }
              {
                // cursor
                mode === "char" && (
                  <Rect
                    x={cursor.x * size + gridOffsetX}
                    y={cursor.y * size + gridOffsetY}
                    width={size}
                    height={size}
                    stroke="#B00020"
                    strokeWidth={5}
                  />
                )
              }
              {mode === "char" && (
                <Rect
                  x={0}
                  y={0}
                  width={maxWidth / scale}
                  height={maxHeight / scale}
                  onClick={(e) => {
                    const cursor = e.target.getStage()?.getPointerPosition();
                    if (!cursor) {
                      return;
                    }
                    const x = Math.floor((cursor.x - 50) / size / scale);
                    const y = Math.floor((cursor.y - 50) / size / scale);
                    setCursor({ x, y });
                  }}
                />
              )}
              <ArtboardRange
                mode={mode}
                maxWidth={maxWidth}
                maxHeight={maxHeight}
                scale={scale}
                handleArtboardDragStart={handleArtboardDragStart}
                handleArtboardDragMove={handleArtboardDragMove}
                handleArtboardDragEnd={handleArtboardDragEnd}
              />
              <SquareView
                mode={mode}
                maxWidth={maxWidth}
                maxHeight={maxHeight}
                scale={scale}
                handleWhiteListStart={handleWhiteListStart}
                handleWhiteListMove={handleWhiteListMove}
                handleWhiteListEnd={handleWhiteListEnd}
                handleSquareClick={handleSquareClick}
              />
            </Layer>
          </Stage>
        )}
      </Box>
    </Container>
  );
};
