import { workerInstance } from "../components/EnigmaHouse/context/EnigmaStudioContext";
import { DictionaryKey } from "../param/dictionaries";
import { GeneralSearchInput } from "../param/generalSearchInput";
import { FunctionSearchProgress } from "../param/generalSearchProgress";
import { AnagramProgress } from "../worker/anagram";
import { CryptoArithmeticSearchProgress } from "../worker/cryptoarismetic";
import { AnagramPayload } from "../worker/messageType";

export const useWorker = () => {
  const load = (
    dictionary: DictionaryKey,
    hasAll: boolean,
    onComplete: (result: string[] | undefined) => void
  ) => {
    workerInstance.postMessage({
      type: "load",
      payload: {
        dictionary,
        hasAll,
      },
    });
    workerInstance.onmessage = (ev: MessageEvent<string[] | undefined>) => {
      const result = ev.data;
      if (onComplete) {
        onComplete(result);
      }
    };
  };

  const loadCustom = (
    text: string,
    onComplete: (result: string[] | undefined) => void
  ) => {
    workerInstance.postMessage({
      type: "loadCustom",
      payload: {
        text,
      },
    });
    workerInstance.onmessage = (ev: MessageEvent<string[] | undefined>) => {
      const result = ev.data;
      if (onComplete) {
        onComplete(result);
      }
    };
  };

  const loadCustomArr = (
    items: string[],
    onComplete: (result: string[] | undefined) => void
  ) => {
    workerInstance.postMessage({
      type: "loadCustomArr",
      payload: {
        items,
      },
    });
    workerInstance.onmessage = (ev: MessageEvent<string[] | undefined>) => {
      const result = ev.data;
      if (onComplete) {
        onComplete(result);
      }
    };
  };

  const unload = (dictionary: DictionaryKey, hasAll?: boolean) => {
    workerInstance.postMessage({
      type: "unload",
      payload: { dictionary, hasAll },
    });
  };

  const wordMatching = (
    keywords: string,
    wordLength: number,
    dictionary: DictionaryKey,
    onComplete: (result: string[] | undefined) => void
  ) => {
    console.log(keywords, wordLength, dictionary);
    workerInstance.postMessage({
      type: "wordMatching",
      payload: {
        keywords,
        wordLength,
        dictionary,
      },
    });
    workerInstance.onmessage = (ev: MessageEvent<string[] | undefined>) => {
      const result = ev.data;
      onComplete(result);
    };
  };

  const regexSearch = (
    keyword: string,
    dictionary: DictionaryKey,
    onComplete: (result: string[] | undefined) => void
  ) => {
    workerInstance.postMessage({
      type: "regexSearch",
      payload: {
        keyword,
        dictionary,
      },
    });
    workerInstance.onmessage = (ev: MessageEvent<string[] | undefined>) => {
      const result = ev.data;
      onComplete(result);
    };
  };

  const numToWordSearch = (
    value: number,
    pattern: string,
    dictionary: DictionaryKey,
    onComplete: (result: string[] | undefined) => void
  ) => {
    workerInstance.postMessage({
      type: "numToWordSearch",
      payload: {
        value,
        pattern,
        dictionary,
      },
    });
    workerInstance.onmessage = (ev: MessageEvent<string[] | undefined>) => {
      const result = ev.data;
      onComplete(result);
    };
  };

  const chimataSearch = (
    payload: {
      keyword: string;
      addSize: number;
      dictionary: DictionaryKey;
    },
    onComplete: (result: string[] | undefined) => void
  ) => {
    console.log("start chimata");
    workerInstance.postMessage({
      type: "chimataSearch",
      payload,
    });
    workerInstance.onmessage = (
      ev: MessageEvent<{ result: string[] | undefined }>
    ) => {
      const { result } = ev.data;
      console.log(result);
      onComplete(result);
    };
  };

  const cryptoArithmetic = (
    input1: string,
    solutionCount: number,
    onComplete: (result: string[] | undefined) => void,
    onProgress: (progress: CryptoArithmeticSearchProgress) => void
  ) => {
    workerInstance.postMessage({
      type: "cryptoArithmetic",
      payload: {
        input1,
        solutionCount,
      },
    });
    workerInstance.onmessage = (
      ev: MessageEvent<{
        result: string[] | undefined;
        progress: CryptoArithmeticSearchProgress | undefined;
        abort: boolean | undefined;
      }>
    ) => {
      const { result, progress } = ev.data;
      if (result) {
        onComplete(result);
      }
      if (progress) {
        onProgress(progress);
      }
    };
  };

  const multipleAnagram = (
    payload: AnagramPayload,
    onComplete: (result: string[] | undefined) => void,
    onProgress: (progress: AnagramProgress) => void
  ) => {
    workerInstance.postMessage({
      type: "multipleAnagram",
      payload,
    });
    workerInstance.onmessage = (
      ev: MessageEvent<{
        result: string[] | undefined;
        progress: AnagramProgress | undefined;
        abort: boolean | undefined;
      }>
    ) => {
      const { result, progress } = ev.data;
      if (result) {
        onComplete(result);
      }
      if (progress) {
        onProgress(progress);
      }
    };
  };

  const functionSearch = (
    payload: {
      input: GeneralSearchInput;
    },
    onComplete: (result: string[] | undefined) => void,
    onProgress: (progress: FunctionSearchProgress | undefined) => void
  ) => {
    workerInstance.postMessage({
      type: "functionSearch",
      payload,
    });
    workerInstance.onmessage = (
      ev: MessageEvent<{
        result: string[] | undefined;
        progress: FunctionSearchProgress | undefined;
        abort: boolean | undefined;
      }>
    ) => {
      const { result, progress } = ev.data;
      if (result) {
        onComplete(result);
      }
      if (progress) {
        onProgress(progress);
      }
    };
  };

  const abort = () => {
    workerInstance.postMessage({
      type: "abort",
    });
  };

  return {
    load,
    loadCustom,
    loadCustomArr,
    unload,
    chimataSearch,
    wordMatching,
    regexSearch,
    cryptoArithmetic,
    numToWordSearch,
    multipleAnagram,
    functionSearch,
    abort,
  };
};
