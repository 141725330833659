export const symbolRegexPatternReplaceTable: {
  [key: string]: string;
} = {
  "！": "!",
  "＆": "&",
  "（": "(",
  "）": ")",
  "＋": "+",
  "，": ",",
  "、": ",",
  "－": "-",
  "．": ".",
  "。": ".",
  "／": "/",
  "：": ":",
  "；": ";",
  "＜": "<",
  "＝": "=",
  "＞": ">",
  "？": "?",
  "＠": "@",
  "［": "[",
  "］": "]",
  "「": "[",
  "」": "]",
  "＾": "^",
  "＿": "_",
  "｛": "{",
  "｜": "|",
  "｝": "}",
  "～": "~",
  "％": "%",
  "＃": "#",
  "＄": "$",
  "＊": "*",
};

export const symbolRegexReplacementReplaceTable: {
  [key: string]: string;
} = {
  "＄": "$",
  "＜": "<",
  "＞": ">",
  "（": "(",
  "）": ")",
  "：": ":",
  "；": ";",
  "＆": "&",
  "～": "~",
  "，": ",",
  "、": ",",
};
