"use client";
import { useState, useEffect } from "react";
import { useFirebaseConfig } from "../../EniverseProvider";
import { User } from "firebase/auth";

export const useFirebaseLogin = () => {
  const [user, setUser] = useState<User | null>(null);
  const [{ firebaseAuth }] = useFirebaseConfig();

  useEffect(() => {
    if (!firebaseAuth) return;
    console.log("firebaseAuth", firebaseAuth);
    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      setUser(user);
    });
    return () => {
      unsubscribe();
    };
  }, [firebaseAuth]);

  return { user };
};
