export type DataSource = {
  buta: string[] | null;
  common: string[] | null;
  cefr: string[] | null;
  neologd_user: string[] | null;
  naist_jdic: string[] | null;
  unidic: string[] | null;
  english: string[] | null;
  common_hepburn: string[] | null;
  // poke: string[] | null;
  nicoime: string[] | null;
  nico_intersection_pixiv: string[] | null;
  hatena: string[] | null;
  // idolmaster: string[] | null;
  // kaikei: string[] | null;
  // mildic: string[] | null;
  // gundam: string[] | null;
  // dqdic: string[] | null;
  juku_skk_l: string[] | null;
  jukugo_on_wikipedia: string[] | null;
  all: string[] | null;
  food_illust: string[] | null;
  animal_koto: string[] | null;
  pref: string[] | null;
  illust1: string[] | null;
  illust1_2: string[] | null;
  illust1_3: string[] | null;
  illust1_4: string[] | null;
  illust1_5: string[] | null;
  custom: string[] | null;
};

type DictionaryParam = {
  id: string;
  key: string;
  name: string;
  index: number;
  words: number;
  disabled: () => boolean;
};

export type DictionaryKey = keyof DataSource;

export const dictionaries: Record<DictionaryKey, DictionaryParam> = {
  buta: {
    id: "switch-list-label-buta",
    key: "buta",
    name: "豚辞書",
    index: 1,
    words: 201092,
    disabled: () => false,
  },
  common: {
    id: "switch-list-label-common",
    key: "common",
    name: "一般語",
    index: 2,
    words: 31247,
    disabled: () => false,
  },

  naist_jdic: {
    id: "switch-list-label-naist_jdic",
    key: "naist_jdic",
    name: "naist_jdic (名詞)",
    words: 142916,
    index: 1,
    disabled: () => false,
  },
  unidic: {
    id: "switch-list-label-unidic",
    key: "unidic",
    name: "UniDic(名詞)",
    index: 2,
    words: 122232,
    disabled: () => false,
  },
  neologd_user: {
    id: "switch-list-label-neologd_user",
    key: "neologd_user",
    name: "NEologd (固有名詞)",
    index: 0,
    words: 1557581,
    disabled: () => false,
  },
  nico_intersection_pixiv: {
    id: "switch-list-label-dic_nico_intersection_pixiv",
    key: "nico_intersection_pixiv",
    name: "ニコニコ大百科∧PIXIV大百科",
    index: 3,
    words: 80171,
    disabled: () => false,
  },
  nicoime: {
    id: "switch-list-label-nicoime",
    key: "nicoime",
    name: "ニコニコ大百科",
    index: 4,
    words: 244369,
    disabled: () => false,
  },
  hatena: {
    id: "switch-list-label-hatena",
    key: "hatena",
    name: "はてなダイアリーキーワード",
    index: 5,
    words: 345137,
    disabled: () => false,
  },
  cefr: {
    id: "switch-list-label-cefr",
    key: "cefr",
    name: "CEFR-J WordList (英語一般語)",
    index: 7,
    words: 7984,
    disabled: () => false,
  },
  english: {
    id: "switch-list-label-english",
    key: "english",
    name: "英語",
    index: 6,
    words: 235885,
    disabled: () => false,
  },

  common_hepburn: {
    id: "switch-list-label-common-hepburn",
    key: "common_hepburn",
    name: "一般語ローマ字",
    index: 8,
    words: 31247,
    disabled: () => false,
  },
  food_illust: {
    id: "switch-list-label-food-illust",
    key: "food_illust",
    name: "食べ物",
    index: 9,
    words: 3373,
    disabled: () => false,
  },
  animal_koto: {
    id: "switch-list-label-animal-koto",
    key: "animal_koto",
    name: "動物",
    index: 10,
    words: 8980,
    disabled: () => false,
  },
  juku_skk_l: {
    id: "switch-list-label-juku-skk-l",
    key: "juku_skk_l",
    name: "SKK辞書L熟語",
    index: 11,
    words: 152370,
    disabled: () => false,
  },
  jukugo_on_wikipedia: {
    id: "switch-list-label-jukugo-on-wikipedia",
    key: "jukugo_on_wikipedia",
    name: "Wikipedia熟語",
    index: 12,
    words: 698223,
    disabled: () => false,
  },
  pref: {
    id: "switch-list-label-japan-pref",
    key: "pref",
    name: "都道府県",
    index: 11,
    words: 47,
    disabled: () => false,
  },
  illust1: {
    id: "switch-list-label-illust1",
    key: "illust1",
    name: "イラスト辞書Lv.1",
    index: 12,
    words: 4276,
    disabled: () => false,
  },
  illust1_2: {
    id: "switch-list-label-illust1-2",
    key: "illust1_2",
    name: "イラスト辞書Lv.1-2",
    index: 13,
    words: 5396,
    disabled: () => false,
  },
  illust1_3: {
    id: "switch-list-label-illust1-3",
    key: "illust1_3",
    name: "イラスト辞書Lv.1-3",
    index: 14,
    words: 5860,
    disabled: () => false,
  },
  illust1_4: {
    id: "switch-list-label-illust1-4",
    key: "illust1_4",
    name: "イラスト辞書Lv.1-4",
    index: 15,
    words: 6303,
    disabled: () => false,
  },
  illust1_5: {
    id: "switch-list-label-illust1-5",
    key: "illust1_5",
    name: "イラスト辞書Lv.1-5",
    index: 16,
    words: 6673,
    disabled: () => false,
  },
  /*
  kaikei: {
    id: "switch-list-label-kaikei",
    key: "kaikei",
    name: "会計",
    index: 12,
    disabled: () => false,
  },
  mildic: {
    id: "switch-list-label-mildic",
    key: "mildic",
    name: "軍事",
    index: 13,
    disabled: () => false,
  },
  poke: {
    id: "switch-list-label-poke",
    key: "poke",
    name: "ポケモン",
    index: 14,
    disabled: () => false,
  },
  gundam: {
    id: "switch-list-label-gundam",
    key: "gundam",
    name: "ガンダム",
    index: 15,
    disabled: () => false,
  },
  dqdic: {
    id: "switch-list-label-dqdic",
    key: "dqdic",
    name: "ドラゴンクエスト",
    index: 16,
    disabled: () => false,
  },
  idolmaster: {
    id: "switch-list-label-idolmaster",
    key: "idolmaster",
    name: "THE IDOLM@STER",
    index: 17,
    disabled: () => false,
  },*/
  custom: {
    id: "switch-list-label-custom",
    key: "custom",
    name: "カスタム辞書",
    index: 18,
    words: -1,
    disabled: () => false,
  },
  all: {
    id: "switch-list-label-all",
    key: "all",
    name: "一括検索",
    index: 19,
    words: -1,
    disabled: () => false,
  },
};
