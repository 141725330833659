import { Container } from "@mui/system";
import { FC } from "react";
import { useParams } from "react-router";
import { useSiteConfig } from "../../../_lib/eniverse/EniverseProvider";
import { Main } from "../../../_lib/eniverse/imaginate/Main";
import { ImaginateProvider } from "../../EnigmaHouse/Studio/InnerImaginate/ImaginateProvider";
import { InnerImaginate } from "../../EnigmaHouse/Studio/InnerImaginate/InnerImaginate";
import { getProjectPath } from "./Projects";

export const ProjectContainer: FC = () => {
  const { projectId } = useParams<"projectId">();
  const [{ serviceId }] = useSiteConfig();

  if (!projectId) {
    return <Container>Project not found</Container>;
  }

  return (
    <ImaginateProvider initialImaginateConfig={{ projectId }}>
      <InnerImaginate
        initialPath={getProjectPath(serviceId, projectId)}
        mainComponent={Main}
      />
    </ImaginateProvider>
  );
};
