import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import {
  blue,
  brown,
  cyan,
  deepPurple,
  green,
  indigo,
  lightGreen,
  orange,
  purple,
  red,
  yellow,
} from "@mui/material/colors";
import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Sequence } from "../../../../../../../param/generalSearchQuery";
import { BasicFormProps } from "../../FormProps";
import { textLength } from "../../../../../../../lib/wordGenerator/textLength";
import { splitGrapheme } from "../../../../../../../lib/wordGenerator/splitGrapheme";

const numberColors: {
  [key: number]: string;
} = {
  1: red[500],
  2: indigo[500],
  3: green[500],
  4: orange[500],
  5: purple[500],
  6: cyan[500],
  7: yellow[700],
  8: brown[500],
  9: blue[500],
  10: lightGreen[500],
};

const captureText: {
  [key: number]: string;
} = {
  1: "①",
  2: "②",
  3: "③",
  4: "④",
  5: "⑤",
  6: "⑥",
  7: "⑦",
  8: "⑧",
  9: "⑨",
  10: "⑩",
};

const captureRegex: {
  [key: number]: string;
} = {
  1: "\\1",
  2: "\\2",
  3: "\\3",
  4: "\\4",
  5: "\\5",
  6: "\\6",
  7: "\\7",
  8: "\\8",
  9: "\\9",
  10: "\\10",
};

type CharacterObject = {
  character: string;
  min?: string;
  max?: string;
  capture?: boolean;
};

type SearchObject = {
  characterObjects: CharacterObject[];
  matchMode: "match" | "contain" | "start" | "end";
  mode: "simple" | "detail";
};

export const SimpleSearchForm: FC<BasicFormProps<Sequence>> = ({
  index,
  query,
  updateQuery,
  isDemo,
  onBlur,
}) => {
  const [sampleText, setSampleText] = useState<string>(
    query.option?.characterObjects
      ?.map((c: CharacterObject) => c.character)
      .join("") ?? ""
  );
  const [searchObject, setSearchObject] = useState<SearchObject>(
    query.option ?? {
      characterObjects: [],
      matchMode: "match",
      mode: "simple",
    }
  );

  const [cursor, setCursor] = useState<number>(0);
  const cursorPos = (cursor / 2) | 0;
  const cursorMode = cursor % 2;
  const captureList = useMemo(() => {
    const ret: number[] = [];
    let captureNumber = 0;
    searchObject.characterObjects.forEach((c, i) => {
      if (c.capture) {
        captureNumber++;
        ret[i] = captureNumber;
      } else {
        ret[i] = -1;
      }
    });
    return ret;
  }, [searchObject.characterObjects]);
  const captureCount = useMemo(() => {
    return captureList.filter((c) => c !== -1).length;
  }, [captureList]);
  const cursorCharacterObject = searchObject.characterObjects[cursorPos];

  const getCharacterText = (character: string) => {
    if (character === ".") return "";
    if (Object.values(captureRegex).includes(character)) {
      return captureText[parseInt(character.slice(1))];
    }
    if (textLength(character) >= 5) {
      return character.slice(0, 3) + "...";
    }
    return character;
  };

  const [notMatch, setNotMatch] = useState<boolean>(false);
  const update = useCallback(() => {
    const { matchMode } = searchObject;

    const regex =
      (matchMode === "match" || matchMode === "start" ? "^" : "") +
      searchObject.characterObjects
        .map((c) => {
          let chars = "";
          if (c.character === ".") chars = c.character;
          if (Object.values(captureRegex).includes(c.character)) {
            chars = c.character;
          } else if (textLength(c.character) === 1) {
            chars = c.character;
          } else {
            chars = "[" + c.character + "]";
          }
          if (c.capture) {
            chars = "(" + chars + ")";
          }
          let rangeChar = "";
          const min = c.min ?? "1";
          const max = c.max ?? "1";
          if (min === "0" && max === "1") {
            rangeChar = "?";
          } else if (min === "1" && max === "1") {
            rangeChar = "";
          } else if (min === "1" && max === "") {
            rangeChar = "+";
          } else if (min === "0" && max === "") {
            rangeChar = "*";
          } else {
            rangeChar = "{" + (min === "" ? 0 : min) + "," + max + "}";
          }
          return chars + rangeChar;
        })
        .join("") +
      (matchMode === "match" || matchMode === "end" ? "$" : "");

    updateQuery(index, "processes", [
      {
        type: "regex",
        pattern: regex,
        notMatch,
      },
    ]);
    updateQuery(index, "option", searchObject);
    if (onBlur) onBlur();
  }, [searchObject, updateQuery, index, notMatch, onBlur]);

  useEffect(() => {
    if (!query.processes || query.processes.length === 0) {
      update();
    }
  }, [query.processes, update]);

  useEffect(() => {
    update();
  }, [cursor, searchObject]);

  const [isSelectRange, setIsSelectRange] = useState<boolean>(false);

  return (
    <>
      {searchObject.mode === "simple" && (
        <Box
          textAlign={"center"}
          alignContent={"center"}
          alignItems={"center"}
          justifyContent={"center"}
          margin={"auto"}
        >
          <TextField
            label="簡易検索"
            variant="outlined"
            value={sampleText || ""}
            disabled={isDemo}
            onChange={(e) => {
              setSampleText(e.target.value);
              const number: { [key: string]: number } = {};
              const characterObjects = splitGrapheme(e.target.value).map(
                (c) => {
                  let character = ["？", "?"].includes(c) ? "." : c;
                  if (
                    ["1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(
                      character
                    )
                  ) {
                    number[character] = (number[character] ?? 0) + 1;
                    if (number[character] > 1) {
                      character = "\\" + character;
                    } else {
                      return {
                        character: ".",
                        capture: true,
                      };
                    }
                  }
                  return {
                    character,
                  };
                }
              );

              setSearchObject((prev) => {
                return {
                  ...prev,
                  characterObjects,
                };
              });
            }}
            onBlur={update}
            sx={{ mt: 1, mb: 0 }}
            helperText={
              <>
                ？で任意の1文字
                <br />
                同じ数字は同じ文字 (例:う12さ12→うおうさおう)
              </>
            }
          />
          {false && (
            <Button
              variant="outlined"
              onClick={() => {
                setSearchObject((prev) => {
                  return {
                    ...prev,
                    mode: "detail",
                  };
                });
                update();
              }}
              disabled={isDemo || sampleText === ""}
              sx={{
                mt: 2,
                mb: 2,
                ml: 1,
              }}
            >
              詳細
            </Button>
          )}
        </Box>
      )}
      <Box sx={{ my: 2, mx: "auto" }}>
        {searchObject.mode === "detail" && (
          <>
            <Stack
              direction="row"
              margin={"auto"}
              textAlign={"center"}
              alignContent={"center"}
              alignItems={"center"}
              justifyContent={"center"}
              useFlexGap
              flexWrap="wrap"
            >
              {searchObject.characterObjects.map((c, i) => {
                const isCursor = cursorPos === i;
                return (
                  <Fragment key={i}>
                    <Stack key={i + "-1"} sx={{ mt: 1, ml: "2px" }}>
                      <Box
                        sx={{
                          width: "40px",
                          height: "40px",
                          py: "8px",
                          borderWidth: "2.5px",
                          borderColor:
                            isCursor && cursorMode === 0
                              ? deepPurple[500]
                              : "#aaa",
                          color: Object.values(captureRegex).includes(
                            c.character
                          )
                            ? numberColors[parseInt(c.character.slice(1))]
                            : "#555",
                          borderStyle: "solid",
                          fontSize:
                            textLength(getCharacterText(c.character)) === 1
                              ? "1.5rem"
                              : "0.75rem",
                          fontWeight: "bold",
                          lineHeight: "0.9",
                          backgroundColor:
                            c.character === "." ? "#f0f0f0" : "#fff",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (isDemo) {
                            return;
                          }
                          setCursor(i * 2);
                        }}
                      >
                        {getCharacterText(c.character)}
                      </Box>
                      <Divider
                        sx={{
                          px: "5px",
                          py: 0.5,
                          borderColor: "#ccc",
                          borderStyle: "dashed",
                          ...(c.capture
                            ? {
                                borderColor: numberColors[captureList[i]],
                                borderStyle: "solid",
                              }
                            : {}),
                          cursor: "pointer",
                        }}
                        variant="fullWidth"
                        onClick={() => {
                          if (captureCount >= 10 && !c.capture) {
                            return;
                          }
                          if (isDemo) {
                            return;
                          }
                          setSearchObject((prev) => {
                            const newCharacterObjects = [
                              ...prev.characterObjects,
                            ];
                            newCharacterObjects[i] = {
                              ...newCharacterObjects[i],
                              capture: !newCharacterObjects[i].capture,
                            };
                            return {
                              ...prev,
                              characterObjects: newCharacterObjects,
                            };
                          });
                        }}
                      />
                      <Typography
                        sx={{
                          width: "100%",
                          pt: 0.5,
                          fontSize: "0.8rem",
                          fontWeight: "bold",
                          lineHeight: "0.9",
                          ...(c.capture
                            ? {
                                color: numberColors[captureList[i]],
                              }
                            : {
                                color: "#ccc",
                                visibility: "hidden",
                              }),
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (isDemo) {
                            return;
                          }
                          if (captureCount >= 10 && !c.capture) {
                            return;
                          }

                          setSearchObject((prev) => {
                            const newCharacterObjects = [
                              ...prev.characterObjects,
                            ];
                            newCharacterObjects[i] = {
                              ...newCharacterObjects[i],
                              capture: !newCharacterObjects[i].capture,
                            };
                            return {
                              ...prev,
                              characterObjects: newCharacterObjects,
                            };
                          });
                        }}
                      >
                        {captureList[i] === -1
                          ? "？"
                          : captureText[captureList[i]] ?? "？"}
                      </Typography>
                    </Stack>
                    <Stack key={i + "-2"} sx={{ mt: 1 }}>
                      <Box
                        sx={{
                          px: "4px",
                          height: "40px",
                          borderWidth: "2.5px",
                          borderColor:
                            isCursor && cursorMode === 1
                              ? deepPurple[500]
                              : "#aaa",
                          color:
                            (c.min ?? "1") === "1" && (c.max ?? "1") === "1"
                              ? "#aaa"
                              : "#444",
                          borderStyle: "solid",
                          fontSize: "0.8rem",
                          fontWeight: "bold",
                          lineHeight: "0.9",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (isDemo) {
                            return;
                          }
                          setCursor(i * 2 + 1);
                        }}
                      >
                        {c.min ?? 1}
                        <br />
                        ~
                        <br />
                        {c.max === "" ? "∞" : c.max ?? 1}
                      </Box>
                      <Divider
                        sx={{
                          mx: "5px",
                          py: 0.5,
                          color: purple[500],
                          borderColor: purple[500],
                          visibility: "hidden",
                        }}
                        variant="fullWidth"
                      />
                      <Typography
                        sx={{
                          pt: 0.5,
                          fontSize: "0.8rem",
                          fontWeight: "bold",
                          lineHeight: "0.9",
                          color: "#ccc",
                          visibility: "hidden",
                        }}
                      >
                        ？
                      </Typography>
                    </Stack>
                  </Fragment>
                );
              })}
            </Stack>

            <Stack
              direction="row"
              spacing={2}
              margin={"auto"}
              textAlign={"center"}
              alignContent={"center"}
              alignItems={"center"}
              justifyContent={"center"}
              sx={{
                mt: 1,
                mb: 2,
              }}
            >
              <ButtonGroup size="small">
                <Button
                  variant={"contained"}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    setCursor((prev) => {
                      return 0;
                    });
                  }}
                  disabled={cursor <= 0}
                  size="small"
                >
                  ≪
                </Button>
                <Button
                  variant={"contained"}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    setCursor((prev) => {
                      return prev - 1;
                    });
                  }}
                  disabled={cursor <= 0}
                  size="small"
                >
                  ＜
                </Button>
                <Button
                  variant={"contained"}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    setCursor((prev) => {
                      return prev + 1;
                    });
                  }}
                  disabled={
                    cursor >= searchObject.characterObjects.length * 2 - 1
                  }
                  size="small"
                >
                  ＞
                </Button>
                <Button
                  variant={"contained"}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    setCursor((prev) => {
                      return searchObject.characterObjects.length * 2 - 1;
                    });
                    update();
                  }}
                  disabled={
                    cursor >= searchObject.characterObjects.length * 2 - 1
                  }
                  size="small"
                >
                  ≫
                </Button>
              </ButtonGroup>
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              margin={"auto"}
              textAlign={"center"}
              alignContent={"center"}
              alignItems={"center"}
              justifyContent={"center"}
              sx={{
                mt: 1,
                mb: 2,
              }}
            >
              <ButtonGroup size="small">
                <Button
                  variant={"contained"}
                  size="small"
                  onMouseDown={(e) => {
                    e.preventDefault();
                    // move
                    setSearchObject((prev) => {
                      const newCharacterObjects = [...prev.characterObjects];
                      const temp = newCharacterObjects[cursorPos];
                      newCharacterObjects[cursorPos] =
                        newCharacterObjects[cursorPos - 1];
                      newCharacterObjects[cursorPos - 1] = temp;
                      return {
                        ...prev,
                        characterObjects: newCharacterObjects,
                      };
                    });
                    setCursor((prev) => {
                      return prev - 2;
                    });
                    update();
                  }}
                  disabled={isDemo || cursorPos <= 0}
                >
                  ←
                </Button>
                <Button
                  variant={"contained"}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    // move
                    setSearchObject((prev) => {
                      const newCharacterObjects = [...prev.characterObjects];
                      const temp = newCharacterObjects[cursorPos];
                      newCharacterObjects[cursorPos] =
                        newCharacterObjects[cursorPos + 1];
                      newCharacterObjects[cursorPos + 1] = temp;
                      return {
                        ...prev,
                        characterObjects: newCharacterObjects,
                      };
                    });
                    setCursor((prev) => {
                      return prev + 2;
                    });
                    update();
                  }}
                  disabled={
                    isDemo ||
                    cursorPos >= searchObject.characterObjects.length * 2 - 2
                  }
                  size="small"
                >
                  →
                </Button>
                <Button
                  variant={"contained"}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    // add
                    setSearchObject((prev) => {
                      const newCharacterObjects = [...prev.characterObjects];
                      newCharacterObjects.splice(cursorPos + 1, 0, {
                        character: "",
                      });
                      return {
                        ...prev,
                        characterObjects: newCharacterObjects,
                      };
                    });
                    setCursor((prev) => {
                      return prev + 2;
                    });
                    update();
                  }}
                  disabled={isDemo}
                  size="small"
                >
                  ＋
                </Button>
                <Button
                  variant={"contained"}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    // delete
                    setSearchObject((prev) => {
                      const newCharacterObjects = [...prev.characterObjects];
                      newCharacterObjects.splice(cursorPos, 1);
                      return {
                        ...prev,
                        characterObjects: newCharacterObjects,
                      };
                    });
                    update();
                  }}
                  disabled={isDemo}
                  size="small"
                >
                  DEL
                </Button>
              </ButtonGroup>
            </Stack>
            {cursorMode === 0 && (
              <Stack>
                <FormControl sx={{ my: 2, textAlign: "center" }}>
                  <RadioGroup
                    row
                    aria-labelledby="radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={
                      searchObject.characterObjects[cursorPos]?.character ?? "."
                    }
                    onChange={(e, v) => {
                      setSearchObject((prev) => {
                        const newCharacterObjects = [...prev.characterObjects];
                        newCharacterObjects[cursorPos] = {
                          ...newCharacterObjects[cursorPos],
                          character: v,
                        };
                        return {
                          ...prev,
                          characterObjects: newCharacterObjects,
                        };
                      });
                      update();
                    }}
                    onBlur={update}
                  >
                    <Stack direction="row">
                      <FormControlLabel
                        checked={
                          !["."]
                            .concat(Object.values(captureRegex))
                            .includes(
                              searchObject.characterObjects[cursorPos]
                                ?.character ?? ""
                            )
                        }
                        disabled={isDemo}
                        onClick={(e) => {
                          // if checked
                          if (
                            ["."]
                              .concat(Object.values(captureRegex))
                              .includes(
                                searchObject.characterObjects[cursorPos]
                                  ?.character ?? ""
                              )
                          ) {
                            setSearchObject((prev) => {
                              const newCharacterObjects = [
                                ...prev.characterObjects,
                              ];
                              newCharacterObjects[cursorPos] = {
                                ...newCharacterObjects[cursorPos],
                                character: "",
                              };
                              return {
                                ...prev,
                                characterObjects: newCharacterObjects,
                              };
                            });
                          }
                          update();
                        }}
                        control={<Radio />}
                        label={
                          <TextField
                            label="候補文字"
                            variant="outlined"
                            value={
                              !["."]
                                .concat(Object.values(captureRegex))
                                .includes(
                                  searchObject.characterObjects[cursorPos]
                                    ?.character ?? ""
                                )
                                ? searchObject.characterObjects[cursorPos]
                                    ?.character
                                : ""
                            }
                            onBlur={update}
                            onClick={(e) => {
                              (e.target as HTMLInputElement).focus();
                            }}
                            disabled={isDemo}
                            onChange={(e) =>
                              setSearchObject((prev) => {
                                const newCharacterObjects = [
                                  ...prev.characterObjects,
                                ];
                                newCharacterObjects[cursorPos] = {
                                  ...newCharacterObjects[cursorPos],
                                  character: e.target.value,
                                };
                                return {
                                  ...prev,
                                  characterObjects: newCharacterObjects,
                                };
                              })
                            }
                            sx={{ ml: 1, mb: 0 }}
                            fullWidth
                          />
                        }
                      />
                    </Stack>
                    <Stack direction="row">
                      <FormControlLabel
                        value={"."}
                        disabled={isDemo}
                        onClick={update}
                        control={<Radio />}
                        label={"任意[.]"}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      sx={{
                        width: "100%",
                      }}
                      useFlexGap
                      flexWrap="wrap"
                    >
                      {Array.from({
                        length: captureList.filter(
                          (c, i) => c !== -1 && i < cursorPos
                        ).length,
                      }).map((_, i) => {
                        return (
                          <FormControlLabel
                            value={"\\" + (i + 1)}
                            disabled={isDemo}
                            onClick={update}
                            onBlur={update}
                            control={<Radio />}
                            label={captureText[i + 1]}
                          />
                        );
                      })}
                    </Stack>
                  </RadioGroup>
                </FormControl>
              </Stack>
            )}

            {cursorMode === 1 && (
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="length-radio"
                  name="length-radio"
                  value={() => {
                    if (isSelectRange) return "range";
                    const c = searchObject.characterObjects[cursorPos];
                    const min = c.min === "" ? "0" : c.min ?? "1";
                    const max = c.max === "" ? "" : c.max ?? "1";
                    if (min === "1" && max === "1") return "0";
                    if (min === "0" && max === "1") return "1";
                    if (min === "1" && max === "") return "2";
                    if (min === "0" && max === "") return "3";
                    return "range";
                  }}
                  onChange={(e, v: string) => {
                    const { min, max } = (() => {
                      switch (v) {
                        case "0":
                          return { min: "1", max: "1" };
                        case "1":
                          return { min: "0", max: "1" };
                        case "2":
                          return { min: "1", max: "" };
                        case "3":
                          return { min: "0", max: "" };
                        default:
                          return { min: "", max: "" };
                      }
                    })();
                    setSearchObject((prev) => {
                      const newCharacterObjects = [...prev.characterObjects];
                      newCharacterObjects[cursorPos] = {
                        ...newCharacterObjects[cursorPos],
                        min: min,
                        max: max,
                      };
                      return {
                        ...prev,
                        characterObjects: newCharacterObjects,
                      };
                    });
                    update();
                  }}
                  onBlur={update}
                >
                  <Stack
                    direction="row"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <FormControlLabel
                      disabled={isDemo}
                      checked={
                        !isSelectRange &&
                        (searchObject.characterObjects[cursorPos]?.min ??
                          "1") === "1" &&
                        (searchObject.characterObjects[cursorPos]?.max ??
                          "1") === "1"
                      }
                      value={"0"}
                      onFocus={() => {
                        setIsSelectRange(false);
                      }}
                      onClick={update}
                      onBlur={update}
                      control={<Radio />}
                      label={"1"}
                    />
                    <FormControlLabel
                      disabled={isDemo}
                      checked={
                        !isSelectRange &&
                        (searchObject.characterObjects[cursorPos]?.min ??
                          "1") === "0" &&
                        (searchObject.characterObjects[cursorPos]?.max ??
                          "1") === "1"
                      }
                      value={"1"}
                      onFocus={() => {
                        setIsSelectRange(false);
                      }}
                      onClick={update}
                      onBlur={update}
                      control={<Radio />}
                      label={"0~1[?]"}
                    />
                    <FormControlLabel
                      disabled={isDemo}
                      checked={
                        !isSelectRange &&
                        (cursorCharacterObject.min ?? "1") === "1" &&
                        (cursorCharacterObject.max ?? "1") === ""
                      }
                      value={"2"}
                      onClick={update}
                      onBlur={update}
                      onFocus={() => {
                        setIsSelectRange(false);
                      }}
                      control={<Radio />}
                      label={"1~∞[+]"}
                    />
                    <FormControlLabel
                      checked={
                        !isSelectRange &&
                        (cursorCharacterObject?.min ?? "1") === "0" &&
                        (cursorCharacterObject.max ?? "1") === ""
                      }
                      value={"3"}
                      onClick={update}
                      onBlur={update}
                      onFocus={() => {
                        setIsSelectRange(false);
                      }}
                      control={<Radio />}
                      label={"0~∞[*]"}
                    />
                  </Stack>
                  <Stack direction="row" sx={{ mt: 1 }}>
                    <FormControlLabel
                      disabled={isDemo}
                      checked={isSelectRange}
                      onFocus={() => {
                        setIsSelectRange(true);
                      }}
                      onBlur={() => {
                        setIsSelectRange(false);
                      }}
                      onClick={update}
                      value={"range"}
                      control={<Radio />}
                      label={
                        <>
                          <TextField
                            label="最低数"
                            variant="outlined"
                            value={
                              searchObject.characterObjects[cursorPos]?.min ??
                              ""
                            }
                            inputMode="numeric"
                            disabled={isDemo}
                            onBlur={update}
                            onFocus={() => {
                              setIsSelectRange(true);
                            }}
                            onChange={(e) =>
                              setSearchObject((prev) => {
                                const newCharacterObjects = [
                                  ...prev.characterObjects,
                                ];
                                newCharacterObjects[cursorPos] = {
                                  ...newCharacterObjects[cursorPos],
                                  min: e.target.value,
                                };
                                return {
                                  ...prev,
                                  characterObjects: newCharacterObjects,
                                };
                              })
                            }
                            sx={{ maxWidth: "40%", ml: 1, mb: 2 }}
                          />
                          <TextField
                            label="最大数"
                            variant="outlined"
                            value={
                              searchObject.characterObjects[cursorPos]?.max ??
                              ""
                            }
                            onFocus={(e) => {
                              setIsSelectRange(true);
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              setIsSelectRange(true);
                              (e.target as HTMLInputElement).focus();
                            }}
                            inputMode="numeric"
                            disabled={isDemo}
                            onBlur={update}
                            onChange={(e) => {
                              setSearchObject((prev) => {
                                const newCharacterObjects = [
                                  ...prev.characterObjects,
                                ];
                                newCharacterObjects[cursorPos] = {
                                  ...newCharacterObjects[cursorPos],
                                  max: e.target.value,
                                };
                                return {
                                  ...prev,
                                  characterObjects: newCharacterObjects,
                                };
                              });
                            }}
                            sx={{ maxWidth: "40%", ml: 1, mb: 2 }}
                          />
                        </>
                      }
                    />
                  </Stack>
                </RadioGroup>
              </FormControl>
            )}
            <Stack>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      searchObject.characterObjects[cursorPos]?.capture ?? false
                    }
                    disabled={isDemo}
                    onChange={(e) => {
                      if (captureCount >= 10 && e.target.checked) {
                        return;
                      }
                      setSearchObject((prev) => {
                        const newCharacterObjects = [...prev.characterObjects];
                        newCharacterObjects[cursorPos] = {
                          ...newCharacterObjects[cursorPos],
                          capture: e.target.checked,
                        };
                        return {
                          ...prev,
                          characterObjects: newCharacterObjects,
                        };
                      });
                      update();
                    }}
                    onBlur={update}
                  />
                }
                sx={{ ml: 1 }}
                label={`丸数字キャプチャ[(...)]`}
                disabled={
                  isDemo ||
                  (captureCount >= 10 &&
                    !searchObject.characterObjects[cursorPos]?.capture)
                }
              />
            </Stack>
          </>
        )}
        <Divider sx={{ mt: 2 }} />
        <ToggleButtonGroup
          color="primary"
          value={
            searchObject.matchMode as "match" | "contain" | "start" | "end"
          }
          size="small"
          exclusive
          onChange={(e, value) => {
            setSearchObject((prev) => {
              return {
                ...prev,
                matchMode: value as "match" | "contain" | "start" | "end",
              };
            });
            update();
          }}
          disabled={isDemo}
          onBlur={update}
          aria-label="Platform"
          sx={{
            mt: 2,
          }}
        >
          <ToggleButton value={"match"}>に一致[^...$]</ToggleButton>
          <ToggleButton value={"contain"}>を含む</ToggleButton>
          <ToggleButton value={"start"}>で始まる[^]</ToggleButton>
          <ToggleButton value={"end"}>で終わる[$]</ToggleButton>
        </ToggleButtonGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={notMatch}
              onChange={(e) => {
                setNotMatch(e.target.checked);
                update();
              }}
              onBlur={update}
            />
          }
          sx={{ ml: 1 }}
          label="否定マッチ"
          disabled={isDemo}
        />
      </Box>
    </>
  );
};
