// @ts-nocheck
"use client";
import { Stack, Typography } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { EditCommonForm } from "./editItems2/EditCommonForm";

type Props = {
  index: number;
  content: any;
  setContentHeavy: (index: number, content: any, version: number) => void;
  selected: boolean;
  version: number;
  editorDataTable: any;
};
export const StackEditItem: FC<Props> = ({
  index,
  content,
  setContentHeavy,
  selected,
  version,
  editorDataTable,
}) => {
  const [localContent, setLocalContent] = useState(content);
  const [localVersion, setLocalVersion] = useState(version);

  const setContent = useCallback(
    (newContent: any, force?: boolean) => {
      setLocalContent({ ...newContent });
      if (force) {
        setLocalVersion(version + 1);
        setContentHeavy(index, newContent, version + 1);
      }
    },
    [index, setContentHeavy, version]
  );

  const syncContentParent = useCallback(() => {
    setContentHeavy(index, localContent, version);
    setLocalVersion(version);
  }, [setContentHeavy, index, localContent, version]);

  useEffect(() => {
    if (version > localVersion) {
      setLocalContent(content);
      setLocalVersion(version);
    }
  }, [version, localVersion, content]);

  useEffect(() => {
    if (!selected && content !== localContent) {
      setContentHeavy(index, localContent, version + 1);
      setLocalVersion(version + 1);
    }
  }, [selected, version, localContent]);

  const editorData =
    editorDataTable[localContent.type ?? "default"] ?? editorDataTable.default;
  return (
    <Stack>
      <Typography variant="caption" align="left">
        {editorData.label}
      </Typography>
      <EditCommonForm
        content={localContent}
        setContent={setContent}
        editorData={editorData}
        selected={selected}
        syncContentParent={syncContentParent}
      />
    </Stack>
  );
};
