import {
  BarChart,
  Calculate,
  Keyboard,
  LibraryBooks,
  Microwave,
  NextPlan,
  PlusOne,
  Schema,
  Search,
  Shuffle,
  TextRotateVertical,
} from "@mui/icons-material";
import { CommonMenuProps } from "./CommonMenu";

export const searchMenuItems: CommonMenuProps = {
  title: "辞書検索",
  menuItems: [
    {
      title: "一般検索",
      description: "一般的な検索機能です。",
      path: "/common-search",
      buttonName: "開く",
      proTool: false,
      Icon: Search,
    },
    {
      category: "Pro限定",
      title: "汎用検索",
      description:
        "絞り込みや変換など、複数のコマンドを組み合わせて検索処理を行う高度なモードです。",
      path: "/general-search",
      buttonName: "開く",
      proTool: true,
      Icon: Schema,
    },
    {
      title: "チマタグラム検索",
      description:
        "ある単語にn文字足したり引いたりして並び替えてできる単語を出力します。",
      path: "/chimata",
      buttonName: "開く",
      proTool: false,
      Icon: PlusOne,
    },
    {
      title: "複数アナグラム検索",
      category: "Pro限定",
      description:
        "使用文字を1度ずつ使ってできる単語の組み合わせを検索します。",
      path: "/anagram",
      buttonName: "開く",
      proTool: true,
      Icon: Shuffle,
    },
    {
      title: "ワードマッチング",
      category: "Pro限定",
      description: "複数の単語から1文字ずつ拾ってできる単語を入力します。",
      path: "/word-matching",
      buttonName: "開く",
      proTool: true,
      Icon: Microwave,
    },
    {
      title: "辞書設定",
      description: "辞書設定",
      path: "/dictionary-settings",
      buttonName: "開く",
      proTool: false,
      Icon: LibraryBooks,
    },
  ],
};

export const toolMenuItems: CommonMenuProps = {
  title: "ツール",
  menuItems: [
    {
      title: "ワードジェネレータ",
      description: "ワードジェネレータ",
      path: "/word-generator",
      buttonName: "謎解きに使われる単語のリストとテキスト整形ツール",
      proTool: false,
      Icon: LibraryBooks,
    },
    {
      title: "覆面算ソルバー",
      description: "覆面算ソルバー",
      path: "/cryptoarithmetic",
      buttonName: "複数の数式を入力すると、当てはまる数字の組を出力",
      proTool: false,
      Icon: Calculate,
    },
    {
      title: "文字数カウント",
      description: "入力したテキストの文字数を表示します。",
      path: "/text-counter",
      buttonName: "文字数カウント",
      proTool: false,
      lab: true,
      Icon: BarChart,
    },
    {
      title: "原稿文字数カウント",
      description: "入力したテキストを原稿にしたときの文字数を表示します。",
      path: "/writing-counter",
      buttonName: "原稿文字数カウント",
      proTool: false,
      lab: true,
      Icon: BarChart,
    },
    /*
    {
      title: "文字種カウント",
      category: "開発中",
      description: "文字種カウント",
      path: "/counter",
      buttonName: "文字種カウント",
      proTool: false,
      lab: true,
      Icon: BarChart,
    },*/
    {
      title: "倍数番目の文字",
      category: "Pro限定",
      description: "文字列を倍数ごとに拾いやすくするためのツールです。",
      path: "/nth-char",
      buttonName: "倍数番目の文字",
      proTool: true,
      Icon: TextRotateVertical,
    },
    {
      title: "シーザーリスト",
      category: "Pro限定",
      description: "単語をリストに沿って進めたり戻したりします。",
      path: "/caesar",
      buttonName: "シーザーリスト",
      proTool: true,
      Icon: NextPlan,
    },
    {
      title: "漢字分解検索",
      category: "Pro先行公開",
      description: "部品から漢字を検索します。",
      path: "/kanji-search",
      buttonName: "漢字検索",
      proTool: true,
      lab: true,
      Icon: Keyboard,
    },
  ],
};

export const oldToolItems: CommonMenuProps = {
  title: "旧ツール",
  menuItems: [
    {
      title: "クロスワードソルバーNEO",
      category: "Pro限定",
      media: "https://scrapbox.io/files/65fee054b52b4100259fc7b9.png",
      path: "https://www.fanbox.cc/manage/posts/6628865",
      description: "複数の条件に当てはまる単語の組を出力します。",
      openExternal: true,
    },
    {
      title: "和同開珎検索",
      media: "https://scrapbox.io/files/65fee0fe19e69d0024eb6572.png",
      path: "https://wandsbox125.web.app/nazo-tools/wado/",
      description: "和同開珎(熟語パズル)の当てはまる単語の組を出力します。",
      openExternal: true,
    },
    {
      title: "使用文字カウンタ",
      media: "https://scrapbox.io/files/65fee04d53a35a0026a20024.png",
      path: "https://wandsbox125.web.app/nazo-tools/counter/",
      description: "テキスト1とテキスト2の使用文字の差分を出力します。",
      openExternal: true,
    },
  ],
};
