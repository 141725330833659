import BackspaceIcon from "@mui/icons-material/Backspace";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
} from "@mui/material";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { useCallback, useContext, useMemo, useState } from "react";
import { hiraganaToKatakana } from "../../../../lib/wordGenerator/convert";
import { kanjiKata } from "../../../../data/kanji/kanji_kata";
import { kanjiParts as _kanjiParts } from "../../../../data/kanji/kanji_parts";
import { PlainText } from "../../common/PlainText";
import { Title } from "../../common/Title";
import { DemoModeMessage } from "../../commonStudio/DemoModeMessage";
import { EnigmaStudioContext } from "../../context/EnigmaStudioContext";
import { splitGrapheme } from "../../../../lib/wordGenerator/splitGrapheme";

type KanjiPartsValue = {
  parent: string[];
  child: {
    parts: string[];
    cutConnect: boolean;
    cutOneStroke: boolean;
  }[];
  allParent: Set<string>;
  allChild: Set<string>;
  childList: string[];
  index: number;
};
type KanjiParts = {
  [key: string]: KanjiPartsValue;
};

type CandidateType = "candidate" | "included";

function buildGraph(kanjiParts: KanjiParts): Map<string, string[]> {
  const graph = new Map<string, string[]>();

  Object.entries(kanjiParts).forEach(([key, value]) => {
    if (!graph.has(key)) {
      graph.set(key, []);
    }

    value.child.forEach((child) => {
      child.parts.forEach((part) => {
        graph.get(key)?.push(part);
      });
    });
  });

  return graph;
}

function topologicalSort(graph: Map<string, string[]>): string[] {
  const visited = new Set<string>();
  const stack: string[] = [];

  const visit = (node: string) => {
    if (visited.has(node)) return;
    visited.add(node);

    const neighbors = graph.get(node);
    if (neighbors) {
      neighbors.forEach(visit);
    }

    stack.unshift(node);
  };

  graph.forEach((_, node) => visit(node));

  return stack;
}

export const KanjiSearch = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const [partsSearchValue, setPartsSearchValue] = useState("");
  const [kataSearchValue, setKataSearchValue] = useState("");

  const kanjiParts: KanjiParts = useMemo(() => {
    const ret: KanjiParts = {};
    Object.keys(_kanjiParts).forEach((key) => {
      const value = _kanjiParts[key];
      if (ret[key] === undefined) {
        ret[key] = {
          parent: [],
          child: [],
          allParent: new Set<string>(),
          allChild: new Set<string>(),
          childList: [],
          index: -1,
        };
      }
      ret[key].child = value;
    });

    Object.keys(ret).forEach((key) => {
      const value = ret[key];
      value.child.forEach((child) => {
        child.parts.forEach((part) => {
          if (ret[part] === undefined) {
            ret[part] = {
              parent: [],
              child: [],
              allParent: new Set<string>(),
              allChild: new Set<string>(),
              childList: [],
              index: -1,
            };
          }
          ret[part].parent.push(key);
        });
      });
    });

    Object.keys(ret).forEach((key) => {
      const value = ret[key];
      value.allParent = new Set<string>();
      value.allChild = new Set<string>();

      const addParent = (key: string) => {
        value.allParent.add(key);
        ret[key].parent.forEach((parent) => {
          addParent(parent);
        });
      };

      const addChild = (key: string) => {
        value.allChild.add(key);
        ret[key].child.forEach((child) => {
          child.parts.forEach((part) => {
            addChild(part);
          });
        });
      };

      addParent(key);
      addChild(key);
    });

    const graph = buildGraph(ret);
    const sortedParts = topologicalSort(graph);

    sortedParts.forEach((part, index) => {
      ret[part].index = index;
    });

    Object.entries(ret)
      .sort(([aKey, aValue], [bKey, bValue]) => {
        return -aValue.index + bValue.index || -aKey.localeCompare(bKey);
      })
      .forEach(([key, value]) => {
        function combineAndFlatten(arr: string[][][]): string[][] {
          const combine = (acc: string[][], current: string[]): string[][] => {
            if (acc.length === 0) return current.map((item) => [item]);
            let result: string[][] = [];
            acc.forEach((accItem) => {
              current.forEach((currentItem) => {
                result.push([...accItem, currentItem]);
              });
            });
            return result;
          };

          return arr.flatMap((subArray) => {
            return subArray.reduce(
              (acc, current) => combine(acc, current),
              [] as string[][]
            );
          });
        }

        const childList = value.child.some((c) => c.parts.length === 1)
          ? value.child.map((child) => {
              return child.parts.map((part) => {
                return ret[part].childList;
              });
            })
          : [
              [[key]],
              ...value.child.map((child) => {
                return child.parts.map((part) => {
                  return ret[part].childList;
                });
              }),
            ];
        value.childList = [
          ...new Set(
            combineAndFlatten(childList).map((child) => {
              return splitGrapheme(child.join(""))
                .sort(
                  (a, b) =>
                    (ret[a]?.index ?? -1) - (ret[b]?.index ?? -1) ||
                    a.localeCompare(b)
                )
                .join("");
            })
          ),
        ];
      });

    return ret;
  }, []);

  const kanjiElement = useMemo(() => {
    return Object.entries(kanjiParts)
      .filter(([_key, value]) => {
        return (
          /*value.parent.length > 0 &&*/
          value.child.length === 0 || value.child[0].parts.length === 0
        );
      })
      .map(([key]) => {
        return key;
      });
  }, [kanjiParts]);

  const [partsChildren, setPartsChildren] = useState<string[]>([]);
  const [partsParent, setPartsParent] = useState<[string, KanjiPartsValue][]>(
    []
  );
  const [partsConstructionList, setPartsConstructionList] = useState<string[]>(
    []
  );
  const [partsSearchType, setPartsSearchType] = useState<
    "tree" | "parts" | "construction"
  >("parts");

  const [candidateType, setCandidateType] = useState<CandidateType>("included");
  let callCount = 0;

  const indexSort = (a: string, b: string) => {
    return (
      (kanjiParts[a]?.index ?? -1) - (kanjiParts[b]?.index ?? -1) ||
      a.localeCompare(b)
    );
  };
  const kanjiTree = (chars: string) => {
    if (chars.length === 0) {
      return <></>;
    }

    if (chars.length === 1) {
      return kanjiParts[chars]?.child
        .filter((child) => child.parts.length > 0)
        .map((child) => {
          ++callCount;
          return child.parts ? (
            <TreeItem
              nodeId={child.parts.join("") + "-" + callCount}
              label={child.parts.join("")}
              key={child.parts.join("") + "-" + callCount}
            >
              {kanjiTree(child.parts.join(""))}
            </TreeItem>
          ) : (
            <></>
          );
        });
    }

    return splitGrapheme(chars).map((char: string) => {
      return (
        <TreeItem
          nodeId={char + callCount}
          label={char}
          key={char + "-" + callCount}
        >
          {kanjiParts[char]?.child
            .filter((child) => child.parts.length > 0)
            .map((child) => {
              ++callCount;
              return (
                child.parts.length > 0 && (
                  <TreeItem
                    nodeId={child.parts.join("") + callCount + "-2"}
                    label={child.parts.join("")}
                    key={child.parts.join("") + callCount + "-2"}
                  >
                    {kanjiTree(child.parts.join(""))}
                  </TreeItem>
                )
              );
            })}
        </TreeItem>
      );
    });
  };

  const partsSearch = useCallback(
    (_searchValue?: string) => {
      if (_searchValue !== undefined) {
        setPartsSearchValue(_searchValue);
      }
      const searchValue = _searchValue ?? partsSearchValue ?? "";

      setPartsSearchType("parts");

      setPartsChildren(
        splitGrapheme(searchValue).map((input) => {
          return [...(kanjiParts[input]?.allChild.values() ?? [])]
            .sort(indexSort)
            .join("");
        })
      );
      setPartsParent(
        Object.entries(kanjiParts)
          .filter(([_key, value]) => {
            return splitGrapheme(searchValue).every((e) => {
              return value.allChild.has(e);
            });
          })
          .sort(([aKey, aValue], [bKey, bValue]) => {
            return -aValue.index + bValue.index || -aKey.localeCompare(bKey);
          })
      );
    },
    [partsSearchValue, kanjiParts]
  );
  const ctx = useContext(EnigmaStudioContext);
  const plan = ctx.plan;
  const isDemo = plan === "normal";

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Title>合体漢字検索</Title>
      {/*
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="合体漢字検索" {...a11yProps(0)} />
          <Tab label="カタカナに見える漢字" {...a11yProps(1)} />
        </Tabs>
      </Box>
  */}
      <PlainText>
        部分検索:
        指定したパーツの分解、指定したパーツを全て含む漢字を検索します。
      </PlainText>
      <PlainText>
        合体検索: 指定したパーツを含む合体漢字の組を検索します
      </PlainText>
      <DemoModeMessage isDemo={isDemo} />
      {tabValue === 0 && (
        <>
          <Box>
            <TextField
              label="漢字パーツ"
              id="Search"
              size={"medium"}
              sx={{
                mt: 2,
              }}
              value={partsSearchValue}
              onChange={(e) => {
                callCount = 0;
                setPartsSearchType("tree");
                setPartsSearchValue(e.target.value);
              }}
              disabled={isDemo}
            />
            <IconButton
              aria-label="backspace"
              disabled={isDemo || partsSearchValue.length === 0}
              color="primary"
              size="large"
              sx={{
                mt: 2,
              }}
              onClick={() => {
                setPartsSearchValue(partsSearchValue.slice(0, -1));
              }}
            >
              <BackspaceIcon />
            </IconButton>
          </Box>
          <Box>
            {kanjiElement.sort().map((element) => {
              return (
                <IconButton
                  aria-label="backspace"
                  color="inherit"
                  size="small"
                  key={element}
                  onClick={() => {
                    setPartsSearchValue(partsSearchValue + element);
                  }}
                  disabled={isDemo}
                >
                  {element}
                </IconButton>
              );
            })}
          </Box>
          <Box>
            <Button
              variant="contained"
              sx={{
                mt: 2,
                ml: 2,
              }}
              onClick={() => {
                setPartsSearchType("tree");
              }}
              disabled={isDemo}
            >
              ツリー表示
            </Button>
            <Button
              variant="contained"
              sx={{
                mt: 2,
                ml: 2,
              }}
              onClick={() => {
                partsSearch();
              }}
              disabled={isDemo}
            >
              部分検索
            </Button>
            <Button
              variant="contained"
              sx={{
                mt: 2,
                ml: 2,
              }}
              onClick={() => {
                setPartsSearchType("construction");

                const normalizedPartsSearchValue = splitGrapheme(partsSearchValue)
                  .map((c) => {
                    if (!kanjiParts[c]) {
                      return c;
                    }
                    const normalized = kanjiParts[c].child.filter(
                      (c) => c.parts.length === 1
                    );
                    if (normalized.length === 0) {
                      return c;
                    }
                    return normalized[0].parts[0];
                  })
                  .sort(indexSort);

                const regex = new RegExp(
                  normalizedPartsSearchValue.join(".*"),
                  "g"
                );

                const candidateParts = Object.entries(kanjiParts).filter(
                  ([_key, value]) => {
                    return normalizedPartsSearchValue.every((e) => {
                      return value.allChild.has(e);
                    });
                  }
                );

                const ret: string[] = [];
                candidateParts.forEach(([key, value]) => {
                  const childList = value.childList.filter((child) => {
                    return child.match(regex);
                  });

                  const minLength = Math.min(
                    ...childList.map((child) => child.length)
                  );
                  const childList2 = childList
                    .filter((child) => child.length === minLength)
                    .map((child) => splitGrapheme(child).sort(indexSort).join(""));

                  if (childList2.length > 0) {
                    ret.push(key + ": " + childList2[0]);
                  }
                });
                setPartsConstructionList(ret);
              }}
              disabled={isDemo}
            >
              合体部分検索
            </Button>

            <Button
              variant="contained"
              sx={{
                mt: 2,
                ml: 2,
              }}
              onClick={() => {
                setPartsSearchType("construction");

                const normalizedPartsSearchValue = splitGrapheme(partsSearchValue)
                  .map((c) => {
                    if (!kanjiParts[c]) {
                      return c;
                    }
                    const normalized = kanjiParts[c].child.filter(
                      (c) => c.parts.length === 1
                    );
                    if (normalized.length === 0) {
                      return c;
                    }
                    return normalized[0].parts[0];
                  })
                  .sort(indexSort);
                const normalizedPartsSearchValueStr =
                  normalizedPartsSearchValue.join("");

                const candidateParts = Object.entries(kanjiParts).filter(
                  ([_key, value]) => {
                    return normalizedPartsSearchValue.some((e) => {
                      return value.allChild.has(e);
                    });
                  }
                );

                const ret: string[] = [];
                candidateParts.forEach(([key, value]) => {
                  const childList = value.childList.filter((child) => {
                    return normalizedPartsSearchValueStr.match(
                      new RegExp(splitGrapheme(child).join(".*"), "g")
                    );
                  });

                  const minLength = Math.min(
                    ...childList.map((child) => child.length)
                  );
                  const childList2 = childList
                    .filter((child) => child.length === minLength)
                    .map((child) => splitGrapheme(child).sort(indexSort).join(""));

                  if (childList2.length > 0) {
                    ret.push(key + ": " + childList2[0]);
                  }
                });
                setPartsConstructionList(ret);
              }}
              disabled={isDemo}
            >
              合体候補検索
            </Button>
          </Box>

          {partsSearchType === "tree" && (
            <TreeView
              aria-label="file system navigator"
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              sx={{
                mt: 2,
                flexGrow: 1,
                maxWidth: 400,
                overflowY: "auto",
              }}
            >
              {kanjiTree(partsSearchValue)}
            </TreeView>
          )}

          {partsSearchType === "parts" && (
            <>
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                }}
              >
                {partsChildren.map((child, i) => {
                  return (
                    <ListItem key={"parent" + i} disablePadding dense>
                      <ListItemText id={"parent-text-" + i} primary={child} />
                    </ListItem>
                  );
                })}
              </List>
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                }}
              >
                {partsParent.map(([key, value]) => {
                  const labelId = `checkbox-list-label-${value}`;
                  return (
                    <ListItem key={"child" + key} disablePadding dense>
                      <ListItemText
                        id={labelId}
                        primary={
                          key +
                          " : " +
                          value.child
                            .map((child) => {
                              return child.parts.join("");
                            })
                            .join(" / ")
                        }
                      />
                    </ListItem>
                  );
                })}
              </List>
            </>
          )}
          {partsSearchType === "construction" && (
            <List
              sx={{
                width: "100%",
                maxWidth: 360,
                bgcolor: "background.paper",
              }}
            >
              {partsConstructionList.map((value, i) => {
                return (
                  <ListItem key={"parent" + i} dense>
                    <ListItemText id={"parent-" + i} primary={value} />
                  </ListItem>
                );
              })}
            </List>
          )}
        </>
      )}
      {tabValue === 1 && (
        <>
          <TextField
            label="Search"
            id="Search"
            size={"medium"}
            sx={{
              mt: 2,
            }}
            value={kataSearchValue}
            onChange={(e) => {
              setKataSearchValue(e.target.value);
            }}
            disabled={isDemo}
          />
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            {Object.entries(kanjiKata)
              .filter(([key, value]) => {
                const kataValue = hiraganaToKatakana(kataSearchValue);
                return splitGrapheme(kataValue).every((e) =>
                  value
                    .split("/")
                    .some(
                      (v) =>
                        ((key + v).match(new RegExp(e, "g")) || []).length >=
                        splitGrapheme(kataValue).filter((c) => e === c).length
                    )
                );
              })
              .map(([key, value]) => {
                const labelId = `checkbox-list-label-${value}`;

                return (
                  <ListItem key={key} disablePadding>
                    <ListItemButton role={undefined} dense>
                      <ListItemText
                        id={labelId}
                        primary={key + " : " + value}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
          </List>
        </>
      )}
    </Container>
  );
};
