import { FC } from "react";
import { TextField, FormControl, Select, MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import { BasicFormProps } from "../FormProps";
import { IdenticalTraceFilter } from "../../../../../../param/generalSearchFilter";

export const IdenticalTraceFilterForm: FC<
  BasicFormProps<IdenticalTraceFilter>
> = ({ index, query, updateQuery, isDemo }) => {
  return (
    <Box
      sx={{
        verticalAlign: "middle",
        height: "auto",
        mt: 2,
      }}
    >
      {(query.leftTraceType ?? "current") !== "current" && (
        <TextField
          label="No."
          variant="outlined"
          value={query.leftTraceIndex ?? 1}
          disabled={isDemo}
          type="number"
          onChange={(e) => {
            if (e.target.value !== "") {
              updateQuery(index, "leftTraceIndex", parseInt(e.target.value));
            } else {
              updateQuery(index, "leftTraceIndex", undefined);
            }
          }}
          sx={{ ml: 1, mb: 2, width: "70px" }}
        />
      )}
      <FormControl variant="outlined" sx={{ ml: 1, width: "200px", mb: 2 }}>
        <Select
          value={query.leftTraceType ?? "current"}
          onChange={(e) => updateQuery(index, "leftTraceType", e.target.value)}
          disabled={isDemo}
        >
          <MenuItem value="current">現在のトレース</MenuItem>
          <MenuItem value="previous">個前のトレース</MenuItem>
          <MenuItem value="index">番のトレース</MenuItem>
        </Select>
      </FormControl>
      が
      <TextField
        label="No."
        variant="outlined"
        value={query.rightTraceIndex ?? 1}
        type="number"
        disabled={isDemo}
        onChange={(e) => {
          if (e.target.value !== "") {
            updateQuery(index, "rightTraceIndex", parseInt(e.target.value));
          } else {
            updateQuery(index, "rightTraceIndex", undefined);
          }
        }}
        sx={{ ml: 1, mb: 2, width: "70px" }}
      />
      <FormControl variant="outlined" sx={{ ml: 1, width: "200px", mb: 2 }}>
        <Select
          value={query.rightTraceType ?? "previous"}
          onChange={(e) => updateQuery(index, "rightTraceType", e.target.value)}
          disabled={isDemo}
        >
          <MenuItem value="previous">個前のトレース</MenuItem>
          <MenuItem value="index">番のトレース</MenuItem>
        </Select>
      </FormControl>
      と
      <FormControl variant="outlined" sx={{ ml: 1, width: "100px", mb: 2 }}>
        <Select
          value={query.compare ?? "nonIdentical"}
          disabled={isDemo}
          onChange={(e) => updateQuery(index, "compare", e.target.value)}
        >
          <MenuItem value="nonIdentical">異なる</MenuItem>
          <MenuItem value="identical">等しい</MenuItem>
        </Select>
      </FormControl>
      ものを抽出
    </Box>
  );
};
