import DescriptionIcon from "@mui/icons-material/Description";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Box,
  Button,
  Container,
  FormControlLabel,
  Switch,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useStatePersist } from "use-state-persist";
import { If } from "../../../../../_lib/eniverse/util/Condition";
import { useWorker } from "../../../../../hooks/useWorker";
import { decompress } from "../../../../../lib/compress";
import { GeneralSearchInput } from "../../../../../param/generalSearchInput";
import { FunctionSearchProgress } from "../../../../../param/generalSearchProgress";
import { GeneralSearchQuery } from "../../../../../param/generalSearchQuery";
import { PlainText } from "../../../common/PlainText";
import { a11yProps } from "../../../common/TabPanel";
import { Title } from "../../../common/Title";
import { DemoModeMessage } from "../../../commonStudio/DemoModeMessage";
import { GeneralSearchResultList } from "../../../commonStudio/GeneralSearchResult";
import { EnigmaStudioContext } from "../../../context/EnigmaStudioContext";
import { HelpLink } from "./HelpLink";
import { ListDefininition } from "./ListDefininition";
import { QueryContainer } from "./QueryContainer";
import { queryTypeArray } from "./QueryTypeChip";
import { presetItems } from "./list/presetItems";
import { sequenceTypes } from "./recipe/data/generalSearchRecipe";
import { GeneralSeachDictionarySelector } from "./tool/GeneralSearchDictionarySelector";
import { JsonView } from "./tool/JsonView";

export const GeneralSearch: FC = () => {
  const ctx = useContext(EnigmaStudioContext);
  ctx.setEnableLoad(true);
  const param = new URLSearchParams(window.location.search);
  const queryKey = param.get("q");

  const [query, setQuery] = useStatePersist<string>(
    "general-search-query",
    `[]`
  );

  const [dictionary, setDictionary] = useState("");
  const [searching, setSearching] = useState(false);
  const [result, setResult] = useState<string[]>([]);

  const [queries, setQueries] = useStatePersist<GeneralSearchQuery[]>(
    "general-search-queries",
    []
  );
  const plan = ctx.plan;
  const isDemo = plan === "normal";

  if (queryKey) {
    decompress(queryKey).then((decompressed) => {
      try {
        setQueries(JSON.parse(decompressed));
        setQuery(decompressed);
        setJsonError(false);
      } catch (error) {
        console.log(error);
        setJsonError(true);
        setQuery(decompressed);
      }
      param.delete("q");
      if (param.toString() !== "") {
        window.history.replaceState({}, "", "?" + param.toString());
      } else {
        window.history.replaceState({}, "", "/general-search");
      }
    });
  }

  const sequencialQueries = useMemo(() => {
    return queries
      .map((query) => {
        if (query?.type === "sequence") {
          return query.processes;
        } else {
          return query;
        }
      })
      .flat();
  }, [queries]);

  const [pattern, setPattern] = useStatePersist<string>(
    "word-list-pattern",
    ""
  );
  const [patternHistory, setPatternHistory] = useStatePersist<string[]>(
    "word-list-pattern-history",
    []
  );
  const [patternHistoryIndex, setPatternHistoryIndex] = useStatePersist<number>(
    "word-list-pattern-history-index",
    -1
  );

  const [editableItems, setEditableItems] = useStatePersist<
    { name: string; value: string }[]
  >("enigma-studio-function-search-editable-items", []);

  const [displaySettings, setDisplaySettings] = useStatePersist(
    "general-search-display-settings",
    false
  );
  const [replaceFullsizeSymbolToHalfsize, setReplaceFullsizeSymbolToHalfSize] =
    useStatePersist(
      "general-search-replace-fullsize-symbol-to-halfsize",
      false
    );

  const [inputName, setInputName] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");
  const [jsonError, setJsonError] = useState(false);
  const [progress, setProgress] = useState<FunctionSearchProgress>({
    startTime: 0,
    currentTime: 0,
    queryProgress: [],
  });

  const traceCounter = useMemo(() => {
    const counter: number[] = [];
    let count = 1;
    let plus = 0;
    sequencialQueries.forEach((query, i) => {
      if (!query) {
        return;
      }
      counter.push(count);
      if (query.enabled ?? true) {
        if (
          query.type === "regexReplace" ||
          query.type === "parallelTransform" ||
          (query.type === "dictionaryMatch" && query.anagramMatch) ||
          query.type === "keyValueMatch" ||
          query.type === "conditionalTransform"
        ) {
          count += 1 + plus;
          plus = 0;
        }
        if (query.type === "regexMatchCompare") {
          plus += (
            (query.trace ?? []).filter(
              (_, i) => i < query.replacements.length
            ) ?? []
          ).reduce((sum: number, trace: boolean) => sum + (trace ? 1 : 0), 0);
        }
      }
    });
    counter.push(count);
    return counter;
  }, [sequencialQueries]);

  const { functionSearch, abort } = useWorker();

  const [dictionaries, setDictionaries] = useStatePersist<string[]>(
    "enigma-studio-dictionaries",
    []
  );

  const [inputTabValue, setInputTabValue] = useState("dictionary");
  const [frypanSearch, setFrypanSearch] = useState(false);

  const onSearch = useCallback(() => {
    setResult([]);
    setSearching(true);

    const input = {
      queries,
      inputDictionary:
        inputTabValue === "frypan"
          ? "frypan"
          : inputTabValue === "dictionary"
            ? dictionaries
            : "free",
      inputDictionaryFree: pattern,
      listDefinitions: presetItems.concat(editableItems).map((item: any) => ({
        name: item.name,
        content: item.value,
        loop: true,
      })),
    };

    if (inputTabValue === "frypan") {
      setFrypanSearch(true);
    }
    functionSearch(
      { input: input as GeneralSearchInput },
      (result) => {
        if (result) {
          setResult(result);
          setSearching(false);
        }
      },
      (progress) => {
        if (progress) {
          setProgress(progress);
        }
      }
    );
  }, [
    queries,
    inputTabValue,
    dictionaries,
    pattern,
    editableItems,
    functionSearch,
  ]);

  const onSearchStop = useCallback(() => {
    abort();
  }, [abort]);

  const handleChangeDictionary = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      console.log(event.target.value);
      setDictionary((event.target as HTMLInputElement).value);
    },
    []
  );

  const handleAdd = () => {
    setEditableItems([
      ...editableItems,
      { name: inputName, value: inputValue },
    ]);
    setInputName("");
    setInputValue("");
  };

  const handleDelete = (index: number) => {
    setEditableItems(editableItems.filter((_, i) => i !== index));
  };

  //Tabs
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  //Queries
  const removeQuery = useCallback(
    (index: number) => {
      const updatedQueries = [...queries].filter((_, i) => i !== index);
      setQueries(updatedQueries);
      setQuery(JSON.stringify(updatedQueries));
      setJsonError(false);
    },
    [queries, setQueries, setQuery]
  );

  const updateQuery = useCallback(
    (index: number, field: string, value: any) => {
      const updatedQueries = [...queries] as any[];
      updatedQueries[index][field] = value;
      setQueries(updatedQueries as GeneralSearchQuery[]);
      setQuery(JSON.stringify(updatedQueries));
      setJsonError(false);
    },
    [queries, setQueries, setQuery, setJsonError]
  );

  const [displayTraceNumber, setDisplayTraceNumber] = useStatePersist(
    "enigma-studio-function-search-display-trace-number",
    false
  );

  const [displayList, setDisplayList] = useStatePersist(
    "general-search-display-list",
    false
  );

  const [displayAddQuery, setDisplayAddQuery] = useStatePersist<string[]>(
    "general-search-display-add-query",
    ["addQuery", "addRecipe"]
  );

  const [displayTooltip, setDisplayTooltip] = useStatePersist(
    "general-search-display-tooltip",
    true
  );

  const [displayHelpLink, setDisplayHelpLink] = useStatePersist(
    "general-search-display-help-link",
    true
  );

  const handleDisplayAddQueryChange = (
    _e: React.MouseEvent<HTMLElement, MouseEvent>,
    newValue: string
  ) => {
    setDisplayAddQuery((prev) => {
      if (prev.includes(newValue)) {
        return prev.filter((v) => v !== newValue);
      } else {
        return [...prev, newValue];
      }
    });
  };

  const setConverted = useCallback(
    (text: string) => {
      if (patternHistory[patternHistoryIndex - 1] === text) {
        return;
      }
      setPatternHistory((prev) => {
        let _patternHistoryIndex = patternHistoryIndex;
        if (prev[_patternHistoryIndex] !== pattern) {
          prev = prev.slice(0, _patternHistoryIndex + 1);
          prev.push(pattern);
          _patternHistoryIndex++;
        }
        if (prev[_patternHistoryIndex] !== text) {
          prev = prev.slice(0, _patternHistoryIndex + 1);
          prev.push(text);
        }
        setPatternHistoryIndex(prev.length - 1);
        return prev;
      });
      setPattern(text);
    },
    [
      patternHistory,
      patternHistoryIndex,
      setPatternHistory,
      setPattern,
      pattern,
      setPatternHistoryIndex,
    ]
  );
  const [outputUrl, setOutputUrl] = useState("");
  useEffect(() => {
    setOutputUrl("");
  }, [queries]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Title>汎用検索</Title>
      <PlainText>
        選択した辞書の入力文字列に対して文字列変換、絞り込み(フィルタ)など、複数の文字列処理を組み合わせて検索を行います。
      </PlainText>
      <DemoModeMessage isDemo={isDemo} search={true} />
      <Box>
        <Box mt={2} mb={3}>
          <Tooltip title={displayTooltip && "リスト定義"} arrow>
            <ToggleButton
              value="list"
              selected={displayList}
              size="small"
              color="primary"
              onChange={() => {
                setDisplayList(!displayList);
              }}
              sx={{ mr: 1 }}
            >
              <FormatListBulletedIcon />
            </ToggleButton>
          </Tooltip>
          <Tooltip title={displayTooltip && "設定オプション"} arrow>
            <ToggleButton
              value="list"
              selected={displaySettings}
              size="small"
              color="primary"
              onChange={() => {
                setDisplaySettings(!displaySettings);
              }}
              sx={{ mr: 1 }}
            >
              <SettingsIcon />
            </ToggleButton>
          </Tooltip>

          <Tooltip title={displayTooltip && "ヘルプ(リンク集を表示)"} arrow>
            <ToggleButton
              value="help"
              selected={displayHelpLink}
              size="small"
              color="primary"
              onChange={() => {
                setDisplayHelpLink(!displayHelpLink);
              }}
              sx={{ mr: 1 }}
            >
              <DescriptionIcon />
            </ToggleButton>
          </Tooltip>
          <Tooltip title={"説明表示ON/OFF"} arrow>
            <ToggleButton
              value="list"
              selected={displayTooltip}
              size="small"
              color="primary"
              onChange={() => {
                setDisplayTooltip(!displayTooltip);
              }}
              sx={{ mr: 1 }}
            >
              <LiveHelpIcon />
            </ToggleButton>
          </Tooltip>
          {displayList && (
            <ListDefininition
              editableItems={editableItems}
              handleDelete={handleDelete}
              handleAdd={handleAdd}
              inputName={inputName}
              setInputName={setInputName}
              inputValue={inputValue}
              setInputValue={setInputValue}
            />
          )}
          {displayHelpLink && <HelpLink />}
          {displaySettings && (
            <Box>
              <FormControlLabel
                control={
                  <Switch
                    checked={displayTraceNumber}
                    onChange={(e) => setDisplayTraceNumber(e.target.checked)}
                  />
                }
                sx={{ ml: 0, mt: 1 }}
                label="トレース番号表示"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={replaceFullsizeSymbolToHalfsize}
                    onChange={(e) =>
                      setReplaceFullsizeSymbolToHalfSize(e.target.checked)
                    }
                  />
                }
                sx={{ ml: 0, mt: 1 }}
                label="正規表現で全角記号を半角に自動変換"
              />
            </Box>
          )}
        </Box>
      </Box>

      <Button
        variant="contained"
        onClick={() => {
          setQuery(`[]`);
          setQueries([]);
        }}
        disabled={isDemo}
        sx={{ ml: 2 }}
      >
        新規作成
      </Button>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="フォームツール" {...a11yProps(0)} />
          <Tab label="JSON入出力" {...a11yProps(1)} />
        </Tabs>
      </Box>

      {tabValue === 0 && (
        <>
          <Box sx={{ mt: 2 }} />
          <Box>
            <QueryContainer
              updateQuery={updateQuery}
              removeQuery={removeQuery}
              displayTraceNumber={displayTraceNumber}
              queries={queries}
              traceCounter={traceCounter}
              setQueries={setQueries}
              setQuery={setQuery}
              sequencialQueries={sequencialQueries}
              displayTooltip={displayTooltip}
              isDemo={isDemo}
            />
            <>
              <ToggleButtonGroup
                color="primary"
                value={displayAddQuery}
                size="small"
                exclusive
                onChange={handleDisplayAddQueryChange}
                aria-label="Platform"
                sx={{
                  mt: 2,
                }}
              >
                <ToggleButton value="addQuery">コマンド追加</ToggleButton>
                <ToggleButton value="addRecipe">複合コマンド追加</ToggleButton>
              </ToggleButtonGroup>
              {displayAddQuery.includes("addQuery") && (
                <Box>
                  {queryTypeArray.map((query, index) => (
                    <Tooltip
                      title={displayTooltip && query.description}
                      arrow
                      key={index}
                    >
                      <Button
                        key={index}
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          const updatedQueries = [
                            ...queries,
                            { type: query.key },
                          ];
                          setQueries(updatedQueries as GeneralSearchQuery[]);
                          setQuery(JSON.stringify(updatedQueries));
                          setJsonError(false);
                        }}
                        sx={{ ml: 1, mt: 1 }}
                        disabled={isDemo}
                      >
                        {query.icon}
                        <span style={{ marginLeft: "4px" }}>{query.name}</span>
                      </Button>
                    </Tooltip>
                  ))}
                </Box>
              )}
              {displayAddQuery.includes("addRecipe") && (
                <Box>
                  {Object.entries(sequenceTypes).map(([key, sequenceType]) => (
                    <Tooltip
                      title={displayTooltip && sequenceType.description}
                      arrow
                    >
                      <Button
                        key={key}
                        variant="outlined"
                        color="success"
                        onClick={() => {
                          const updatedQueries = [
                            ...queries,
                            JSON.parse(
                              JSON.stringify(sequenceType.defaultQuery)
                            ),
                          ];
                          setQueries(updatedQueries as GeneralSearchQuery[]);
                          setQuery(JSON.stringify(updatedQueries));
                          setJsonError(false);
                        }}
                        sx={{ ml: 1, mt: 1 }}
                        disabled={isDemo}
                      >
                        {sequenceType.name}
                      </Button>
                    </Tooltip>
                  ))}
                </Box>
              )}
            </>
          </Box>
        </>
      )}
      <If condition={tabValue === 1}>
        <JsonView
          query={query}
          setQuery={setQuery}
          setQueries={setQueries}
          outputUrl={outputUrl}
          setOutputUrl={setOutputUrl}
          jsonError={jsonError}
          setJsonError={setJsonError}
          isDemo={isDemo}
        />
      </If>

      <GeneralSeachDictionarySelector
        inputTabValue={inputTabValue}
        setInputTabValue={setInputTabValue}
        dictionaries={dictionaries}
        setDictionaries={setDictionaries}
      />
      <Box>
        <Button
          variant="contained"
          onClick={onSearch}
          disabled={
            searching ||
            (inputTabValue === "dictionary" && dictionaries.length === 0)
          }
        >
          検索
        </Button>
        <Button
          variant="contained"
          onClick={onSearchStop}
          disabled={!searching}
          sx={{ ml: 2 }}
        >
          停止
        </Button>
      </Box>
      <GeneralSearchResultList
        items={result}
        loading={searching}
        progress={progress}
        queries={sequencialQueries}
        setFreeInput={(text: string) => {
          setInputTabValue("free");
          setConverted(text);
        }}
      />
    </Container>
  );
};
