import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import { useStatePersist } from "use-state-persist";
import { symbolRegexReplacementReplaceTable } from "../../../../../../lib/wordGenerator/replaceFullsizeSymbolToHalfSize";
import { DictionarySelector } from "../../../../commonStudio/DictionarySelector";
import { RegexInputWithCompletion } from "../../../../commonStudio/RegexInputWithCompletion";
import { ReplacementInput } from "../../../../commonStudio/ReplacementInput";
import { ConditionalTransform } from "../../../../../../param/generalSearchTransform";
import { splitGrapheme } from "../../../../../../lib/wordGenerator/splitGrapheme";

type ConditionalTransformFormProps = {
  index: number;
  query: ConditionalTransform;
  updateQuery: (index: number, field: string, value: any) => void;
  isDemo: boolean;
};

export const ConditionalTransformForm: FC<ConditionalTransformFormProps> = ({
  index,
  query,
  updateQuery,
  isDemo,
}) => {
  const [replaceFullsizeSymbolToHalfsize] = useStatePersist(
    "general-search-replace-fullsize-symbol-to-halfsize",
    false
  );
  return (
    <>
      <RegexInputWithCompletion
        pattern={query.pattern}
        onPatternChange={(newPattern) =>
          updateQuery(index, "pattern", newPattern)
        }
        disabled={isDemo}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={query.matchOnly || false}
            onChange={(e) => updateQuery(index, "matchOnly", e.target.checked)}
          />
        }
        sx={{ ml: 1 }}
        disabled={isDemo}
        label="マッチのみ抽出"
      />
      <FormControl variant="outlined" sx={{ ml: 1, width: "200px", mb: 2 }}>
        <InputLabel>比較方法</InputLabel>
        <Select
          value={query.compare || ""}
          onChange={(e) => updateQuery(index, "compare", e.target.value)}
          disabled={isDemo}
        >
          <MenuItem value="equal">すべて等しい</MenuItem>
          <MenuItem value="notEqual">等しくないものがある</MenuItem>
          <MenuItem value="allDifferent">すべて異なる</MenuItem>
          <MenuItem value="anagramEqual">アナグラム一致</MenuItem>
          <MenuItem value="dictionaryMatch">すべて辞書に含まれる</MenuItem>
          <MenuItem value="dictionaryAnagramMatch">
            すべてアナグラムで辞書に含まれる
          </MenuItem>
          <MenuItem value="include">上の文字列が下の文字列に含まれる</MenuItem>
          <MenuItem value="exclude">
            上の文字列が下の文字列に含まれない
          </MenuItem>
          <MenuItem value="less-equal">上の文字列＜＝下の文字列</MenuItem>
          <MenuItem value="less">上の文字列＜下の文字列</MenuItem>
        </Select>
      </FormControl>
      {(query.compare === "dictionaryMatch" ||
        query.compare === "dictionaryAnagramMatch") && (
        <DictionarySelector
          labelName="辞書"
          dictionary={query.dictionary}
          setDictionary={(value) => updateQuery(index, "dictionary", value)}
          isDemo={isDemo}
        />
      )}
      <Paper
        variant="outlined"
        sx={{
          ml: 1,
          p: 2,
          backgroundColor: "palette.background.paper",
          opacity: query.enabled === false ? 0.4 : 1,
        }}
      >
        {(query.replacements ?? []).map((match: any, mIndex: number) => (
          <Box key={mIndex} mb={3}>
            <Button
              variant="text"
              color="secondary"
              onClick={() => {
                const updatedMatches = [...(query.replacements || [])];
                updatedMatches.splice(mIndex, 1);
                updateQuery(index, "replacements", updatedMatches);
              }}
              disabled={isDemo}
            >
              削除
            </Button>
            <TextField
              label="比較文字列"
              variant="outlined"
              value={match || ""}
              disabled={isDemo}
              onChange={(e) => {
                const updatedMatches = [...(query.replacements || [])];
                updatedMatches[mIndex] = e.target.value;
                updateQuery(index, "replacements", updatedMatches);
              }}
              onCompositionEnd={(e) => {
                if (replaceFullsizeSymbolToHalfsize) {
                  const replacement = splitGrapheme(match as string)
                    .map((c: string) =>
                      symbolRegexReplacementReplaceTable[c]
                        ? symbolRegexReplacementReplaceTable[c]
                        : c
                    )
                    .join("");
                  const updatedMatches = [...(query.replacements || [])];
                  updatedMatches[mIndex] = replacement;
                  updateQuery(index, "replacements", updatedMatches);
                }
              }}
              sx={{ ml: 1, width: "300px" }}
            />
          </Box>
        ))}
        <Button
          variant="text"
          color="primary"
          disabled={isDemo}
          onClick={() => {
            const updatedMatches = [...(query.replacements || []), ""];
            updateQuery(index, "replacements", updatedMatches);
          }}
        >
          比較文字列を追加
        </Button>
      </Paper>
      <ReplacementInput
        replacement={query.replacementTrue}
        onReplacementChange={(newReplacement) =>
          updateQuery(index, "replacementTrue", newReplacement)
        }
        disabled={isDemo}
        label="条件適合時の置換文字列"
        sx={{ mt: 2 }}
      />
      <ReplacementInput
        replacement={query.replacementFalse}
        onReplacementChange={(newReplacement) =>
          updateQuery(index, "replacementFalse", newReplacement)
        }
        disabled={isDemo}
        label="条件不適合時の置換文字列"
        sx={{ mt: 2 }}
      />
    </>
  );
};
