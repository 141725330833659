import { Grid, TextField, Button } from "@mui/material";
import { FC } from "react";
import { useStatePersist } from "use-state-persist";
import { ConvertProps } from "./ConvertTemplate";
import { splitGrapheme } from "../../../../../../lib/wordGenerator/splitGrapheme";

export const AmidaGenerator: FC<ConvertProps> = (props) => {
  const { pattern, setConverted } = props;

  const [amidaUpperInput, setAmidaUpperInput] = useStatePersist<string>(
    "word-list-amida-upper-input",
    ""
  );
  const [amidaLowerInput, setAmidaLowerInput] = useStatePersist<string>(
    "word-list-amida-lower-input",
    ""
  );

  return (
    <>
      <Grid item>
        <TextField
          fullWidth
          label="上段"
          value={amidaUpperInput}
          onChange={(e) => setAmidaUpperInput(e.target.value)}
          sx={{ width: 400, mt: 1, mr: 2 }}
        />
        <TextField
          fullWidth
          label="サイズ"
          type="number"
          value={
            (/,/.test(amidaUpperInput)
              ? amidaUpperInput.split(",")
              : splitGrapheme(amidaUpperInput)
            ).length
          }
          onChange={(e) => {
            setAmidaUpperInput((prev) => {
              const commaSplit = /,/.test(prev);
              const arr = commaSplit ? prev.split(",") : splitGrapheme(prev);
              const size = Number(e.target.value);
              if (size > arr.length) {
                if (arr.length === 1 && arr[0] === "") {
                  arr[0] = "1";
                }
                return arr
                  .concat(
                    Array(size - arr.length)
                      .fill("")
                      .map((_, i) => "" + (i + arr.length + 1))
                  )
                  .join(",");
              } else if (size < arr.length) {
                return arr.slice(0, size).join(commaSplit ? "," : "");
              }
              return prev;
            });
            setAmidaLowerInput((prev) => {
              const commaSplit = /,/.test(prev) || prev === "";
              const arr = commaSplit ? prev.split(",") : splitGrapheme(prev);
              const size = Number(e.target.value);
              if (size > arr.length) {
                if (arr.length === 1 && arr[0] === "") {
                  arr[0] = "1";
                }
                return arr
                  .concat(
                    Array(size - arr.length)
                      .fill("")
                      .map((_, i) => "" + (i + arr.length + 1))
                  )
                  .join(",");
              } else if (size < arr.length) {
                return arr.slice(0, size).join(commaSplit ? "," : "");
              }
              return prev;
            });
          }}
          sx={{ width: 100, mt: 1 }}
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          label="下段"
          value={amidaLowerInput}
          onChange={(e) => setAmidaLowerInput(e.target.value)}
          sx={{ width: 400, mt: 1, mr: 2 }}
        />
        <Button
          variant="contained"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            setAmidaLowerInput((prev) => {
              // shuffle
              const commaSplit = /,/.test(prev) || prev === "";
              const arr = commaSplit ? prev.split(",") : splitGrapheme(prev);
              return arr
                .map((e) => {
                  return { value: e, sort: Math.random() };
                })
                .sort((a, b) => a.sort - b.sort)
                .map((e) => {
                  return e.value;
                })
                .join(commaSplit ? "," : prev === "" ? "," : "");
            });
          }}
        >
          シャッフル
        </Button>
        <Button
          variant="contained"
          sx={{ mt: 2, mr: 2 }}
          disabled={
            amidaUpperInput === "" ||
            (/,/.test(amidaUpperInput)
              ? amidaUpperInput.split(",")
              : splitGrapheme(amidaUpperInput)
            ).length !==
              (/,/.test(amidaLowerInput)
                ? amidaLowerInput.split(",")
                : splitGrapheme(amidaLowerInput)
              ).length ||
            (/,/.test(amidaUpperInput)
              ? amidaUpperInput.split(",")
              : splitGrapheme(amidaUpperInput)
            )
              .sort()
              .join(",") !==
              (/,/.test(amidaLowerInput)
                ? amidaLowerInput.split(",")
                : splitGrapheme(amidaLowerInput)
              )
                .sort()
                .join(",")
          }
          onClick={() => {
            const solve = (array: number[]) => {
              const ret: number[][] = [];
              while (!array.every((v, i) => v === i)) {
                const line: number[] = [];
                const lineCandidate: {
                  value: number;
                  score: number;
                }[] = [];
                for (let i = 0; i < array.length; i++) {
                  if (array[i] > array[i + 1]) {
                    lineCandidate.push({
                      value: i,
                      score: Math.max(
                        Math.abs(i - array[i]),
                        Math.abs(i + 1 - array[i + 1])
                      ),
                    });
                  }
                }
                for (const e of lineCandidate.sort(
                  (a, b) => b.score - a.score
                )) {
                  if (
                    !line.includes(e.value - 1) &&
                    !line.includes(e.value + 1)
                  ) {
                    line.push(e.value);
                    const tmp = array[e.value];
                    array[e.value] = array[e.value + 1];
                    array[e.value + 1] = tmp;
                  }
                }
                ret.push(line);
              }
              return ret;
            };

            const write = (array: number[][], size: number) => {
              let text = "";
              for (let i = 0; i < array.length; i++) {
                for (let j = 0; j < size; j++) {
                  if (array[i].includes(j)) {
                    text += "┣";
                  } else if (array[i].includes(j - 1)) {
                    text += "┫";
                  } else {
                    text += "┃";
                  }
                }
                text += "\n";
              }
              return text;
            };

            const upperArr = /,/.test(amidaUpperInput)
              ? amidaUpperInput.split(",")
              : splitGrapheme(amidaUpperInput);

            const countedUpperArr = upperArr.map((e, i) => ({
              value: e,
              count: upperArr.filter((e2, i2) => e === e2 && i2 < i).length,
            }));

            const lowerArr = /,/.test(amidaLowerInput)
              ? amidaLowerInput.split(",")
              : splitGrapheme(amidaLowerInput);
            const countedLowerArr = lowerArr.map((e, i) => ({
              value: e,
              count: lowerArr.filter((e2, i2) => e === e2 && i2 < i).length,
            }));

            const array = countedUpperArr.map((e) =>
              countedLowerArr.findIndex(
                (e2) => e2.value === e.value && e2.count === e.count
              )
            );
            const solveArray = solve(array);

            const upperArrString = upperArr
              .map((str) => {
                const c = str.charCodeAt(0);
                if (c >= 0x7f) {
                  return str[0];
                }
                if (str.length === 1) {
                  return str + "_";
                }
                const c2 = str.charCodeAt(1);
                if (c >= 0x7f) {
                  return str[0] + "_";
                }
                return str[0] + str[1];
              })
              .join("");
            const lowerArrString = lowerArr
              .map((str) => {
                const c = str.charCodeAt(0);
                if (c >= 0x7f) {
                  return str[0];
                }
                if (str.length === 1) {
                  return str + "_";
                }
                const c2 = str.charCodeAt(1);
                if (c >= 0x7f) {
                  return str[0] + "_";
                }
                return str[0] + str[1];
              })
              .join("");

            setConverted(
              upperArrString +
                "\n" +
                write(solveArray, array.length) +
                lowerArrString
            );
          }}
        >
          生成
        </Button>
      </Grid>
    </>
  );
};
