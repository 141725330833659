import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Chip } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import {
  GeneralSearchQuery,
} from "../../../../../param/generalSearchQuery";

export const queryTypeTable: {
  [key: string]: {
    name: string;
    icon: JSX.Element;
    description?: string;
  };
} = {
  regex: {
    name: "正規表現フィルタ",
    icon: <FilterAltIcon />,
    description: "正規表現にマッチする文字列のみ出力します",
  },
  dictionaryMatch: {
    name: "辞書マッチ",
    icon: <FilterAltIcon />,
    description: "辞書に含まれる単語のみ出力します",
  },
  length: {
    name: "長さフィルタ",
    icon: <FilterAltIcon />,
    description: "文字列の長さでフィルタします",
  },
  candidateCharacters: {
    name: "候補文字フィルタ",
    icon: <FilterAltIcon />,
    description: "候補文字を使用して候補を絞り込みます",
  },
  regexMatchCompare: {
    name: "正規表現マッチ比較",
    icon: <FilterAltIcon />,
    description: "正規表現にマッチした複数の文字列を比較します",
  },
  identicalTrace: {
    name: "トレース比較",
    icon: <FilterAltIcon />,
    description: "トレースを比較して条件に当てはまるもののみ出力します",
  },
  fishBone: {
    name: "魚の骨フィルタ",
    icon: <FilterAltIcon />,
    description: "複数の単語から1文字ずつ拾ってできる文字列を出力します",
  },
  keyValueMatch: {
    name: "Key-Valueマッチ",
    icon: <AccountTreeIcon />,
    description: "同じKeyのValue同士をペアにします",
  },
  traceGrouping: {
    name: "トレースグルーピング",
    icon: <AccountTreeIcon />,
    description: "トレースをグループ化します",
  },
  regexReplace: {
    name: "正規表現置換",
    icon: <ChangeCircleIcon />,
    description: "正規表現にマッチした文字列を置換します",
  },
  parallelTransform: {
    name: "並列変換",
    icon: <ChangeCircleIcon />,
    description: "複数の変換処理を分岐して適用します",
  },
  conditionalTransform: {
    name: "条件置換",
    icon: <ChangeCircleIcon />,
    description: "条件によって処理を分岐して正規表現置換処理を適用します",
  },
};

export const queryTypeArray = Object.keys(queryTypeTable).map((key) => ({
  key,
  name: queryTypeTable[key].name,
  icon: queryTypeTable[key].icon,
  description: queryTypeTable[key].description,
}));

type QueryTypeChipProps = {
  query: GeneralSearchQuery;
  onDelete?: () => void;
  onClick?: () => void;
  isDemo: boolean;
};

export const QueryTypeChip: FC<QueryTypeChipProps> = ({
  query,
  onClick,
  onDelete,
  isDemo,
}) => {
  return (
    queryTypeTable[query?.type as string] && (
      <Box
        sx={{
          display: "inline-block",
          verticalAlign: "middle",
          height: "auto",
          width: 200,
        }}
      >
        <Chip
          label={queryTypeTable[query.type as string].name || ""}
          icon={queryTypeTable[query.type as string].icon || <></>}
          color={
            query.enabled === undefined || query.enabled === true
              ? "primary"
              : "default"
          }
          sx={{
            mr: 1,
          }}
          onClick={onClick}
          onDelete={onDelete}
          disabled={isDemo}
        />
      </Box>
    )
  );
};
