export interface RomanMap {
  [key: string]: {
    hepburn: string | undefined;
    kunrei: string | undefined;
    hepburnTyping: string | undefined;
    kunreiTyping: string | undefined;
  };
}
export const kanaToRomanTable: RomanMap = {
  あ: {
    hepburn: "a",
    hepburnTyping: "a",
    kunrei: "a",
    kunreiTyping: "a",
  },
  い: {
    hepburn: "i",
    hepburnTyping: "i",
    kunrei: "i",
    kunreiTyping: "i",
  },
  う: {
    hepburn: "u",
    hepburnTyping: "u",
    kunrei: "u",
    kunreiTyping: "u",
  },
  え: {
    hepburn: "e",
    hepburnTyping: "e",
    kunrei: "e",
    kunreiTyping: "e",
  },
  お: {
    hepburn: "o",
    hepburnTyping: "o",
    kunrei: "o",
    kunreiTyping: "o",
  },
  か: {
    hepburn: "ka",
    hepburnTyping: "ka",
    kunrei: "ka",
    kunreiTyping: "ka",
  },
  き: {
    hepburn: "ki",
    hepburnTyping: "ki",
    kunrei: "ki",
    kunreiTyping: "ki",
  },
  く: {
    hepburn: "ku",
    hepburnTyping: "ku",
    kunrei: "ku",
    kunreiTyping: "ku",
  },
  け: {
    hepburn: "ke",
    hepburnTyping: "ke",
    kunrei: "ke",
    kunreiTyping: "ke",
  },
  こ: {
    hepburn: "ko",
    hepburnTyping: "ko",
    kunrei: "ko",
    kunreiTyping: "ko",
  },
  きゃ: {
    hepburn: "kya",
    hepburnTyping: "kya",
    kunrei: "kya",
    kunreiTyping: "kya",
  },
  きぃ: {
    hepburn: undefined,
    hepburnTyping: "kyi",
    kunrei: undefined,
    kunreiTyping: "kyi",
  },
  きゅ: {
    hepburn: "kyu",
    hepburnTyping: "kyu",
    kunrei: "kyu",
    kunreiTyping: "kyu",
  },
  きぇ: {
    hepburn: undefined,
    hepburnTyping: "kye",
    kunrei: undefined,
    kunreiTyping: "kye",
  },
  きょ: {
    hepburn: "kyo",
    hepburnTyping: "kyo",
    kunrei: "kyo",
    kunreiTyping: "kyo",
  },
  くゎ: {
    hepburn: undefined,
    hepburnTyping: "qwa",
    kunrei: undefined,
    kunreiTyping: "qwa",
  },
  くぁ: {
    hepburn: undefined,
    hepburnTyping: "kwa",
    kunrei: undefined,
    kunreiTyping: "kwa",
  },
  くぃ: {
    hepburn: undefined,
    hepburnTyping: "kwi",
    kunrei: undefined,
    kunreiTyping: "kwi",
  },
  くぅ: {
    hepburn: undefined,
    hepburnTyping: "kwu",
    kunrei: undefined,
    kunreiTyping: "kwu",
  },
  くぇ: {
    hepburn: undefined,
    hepburnTyping: "kwe",
    kunrei: undefined,
    kunreiTyping: "kwe",
  },
  くぉ: {
    hepburn: undefined,
    hepburnTyping: "kwo",
    kunrei: undefined,
    kunreiTyping: "kwo",
  },
  さ: {
    hepburn: "sa",
    hepburnTyping: "sa",
    kunrei: "sa",
    kunreiTyping: "sa",
  },
  し: {
    hepburn: "shi",
    hepburnTyping: "shi",
    kunrei: "si",
    kunreiTyping: "si",
  },
  す: {
    hepburn: "su",
    hepburnTyping: "su",
    kunrei: "su",
    kunreiTyping: "su",
  },
  せ: {
    hepburn: "se",
    hepburnTyping: "se",
    kunrei: "se",
    kunreiTyping: "se",
  },
  そ: {
    hepburn: "so",
    hepburnTyping: "so",
    kunrei: "so",
    kunreiTyping: "so",
  },
  しゃ: {
    hepburn: "sha",
    hepburnTyping: "sha",
    kunrei: "sya",
    kunreiTyping: "sya",
  },
  しぃ: {
    hepburn: undefined,
    hepburnTyping: "syi",
    kunrei: undefined,
    kunreiTyping: "syi",
  },
  しゅ: {
    hepburn: "shu",
    hepburnTyping: "shu",
    kunrei: "syu",
    kunreiTyping: "syu",
  },
  しぇ: {
    hepburn: undefined,
    hepburnTyping: "sye",
    kunrei: undefined,
    kunreiTyping: "sye",
  },
  しょ: {
    hepburn: "sho",
    hepburnTyping: "sho",
    kunrei: "syo",
    kunreiTyping: "syo",
  },
  すぁ: {
    hepburn: undefined,
    hepburnTyping: "swa",
    kunrei: undefined,
    kunreiTyping: "swa",
  },
  すぃ: {
    hepburn: undefined,
    hepburnTyping: "swi",
    kunrei: undefined,
    kunreiTyping: "swi",
  },
  すぅ: {
    hepburn: undefined,
    hepburnTyping: "swu",
    kunrei: undefined,
    kunreiTyping: "swu",
  },
  すぇ: {
    hepburn: undefined,
    hepburnTyping: "swe",
    kunrei: undefined,
    kunreiTyping: "swe",
  },
  すぉ: {
    hepburn: undefined,
    hepburnTyping: "swo",
    kunrei: undefined,
    kunreiTyping: "swo",
  },
  た: {
    hepburn: "ta",
    hepburnTyping: "ta",
    kunrei: "ta",
    kunreiTyping: "ta",
  },
  ち: {
    hepburn: "chi",
    hepburnTyping: "chi",
    kunrei: "ti",
    kunreiTyping: "ti",
  },
  つ: {
    hepburn: "tsu",
    hepburnTyping: "tsu",
    kunrei: "tu",
    kunreiTyping: "tu",
  },
  て: {
    hepburn: "te",
    hepburnTyping: "te",
    kunrei: "te",
    kunreiTyping: "te",
  },
  と: {
    hepburn: "to",
    hepburnTyping: "to",
    kunrei: "to",
    kunreiTyping: "to",
  },
  ちゃ: {
    hepburn: "cha",
    hepburnTyping: "cha",
    kunrei: "tya",
    kunreiTyping: "tya",
  },
  ちぃ: {
    hepburn: undefined,
    hepburnTyping: "tyi",
    kunrei: undefined,
    kunreiTyping: "tyi",
  },
  ちゅ: {
    hepburn: "chu",
    hepburnTyping: "chu",
    kunrei: "tyu",
    kunreiTyping: "tyu",
  },
  ちぇ: {
    hepburn: "che",
    hepburnTyping: "che",
    kunrei: "tye",
    kunreiTyping: "tye",
  },
  ちょ: {
    hepburn: "cho",
    hepburnTyping: "cho",
    kunrei: "tyo",
    kunreiTyping: "tyo",
  },
  つぁ: {
    hepburn: undefined,
    hepburnTyping: "tsa",
    kunrei: undefined,
    kunreiTyping: "tsa",
  },
  つぃ: {
    hepburn: undefined,
    hepburnTyping: "tsi",
    kunrei: undefined,
    kunreiTyping: "tsi",
  },
  つぇ: {
    hepburn: undefined,
    hepburnTyping: "tse",
    kunrei: undefined,
    kunreiTyping: "tse",
  },
  つぉ: {
    hepburn: undefined,
    hepburnTyping: "tso",
    kunrei: undefined,
    kunreiTyping: "tso",
  },
  てゃ: {
    hepburn: undefined,
    hepburnTyping: "tha",
    kunrei: undefined,
    kunreiTyping: "tha",
  },
  てぃ: {
    hepburn: undefined,
    hepburnTyping: "thi",
    kunrei: undefined,
    kunreiTyping: "thi",
  },
  てゅ: {
    hepburn: undefined,
    hepburnTyping: "thu",
    kunrei: undefined,
    kunreiTyping: "thu",
  },
  てぇ: {
    hepburn: undefined,
    hepburnTyping: "the",
    kunrei: undefined,
    kunreiTyping: "the",
  },
  てょ: {
    hepburn: undefined,
    hepburnTyping: "tho",
    kunrei: undefined,
    kunreiTyping: "tho",
  },
  とぁ: {
    hepburn: undefined,
    hepburnTyping: "twa",
    kunrei: undefined,
    kunreiTyping: "twa",
  },
  とぃ: {
    hepburn: undefined,
    hepburnTyping: "twi",
    kunrei: undefined,
    kunreiTyping: "twi",
  },
  とぅ: {
    hepburn: undefined,
    hepburnTyping: "twu",
    kunrei: undefined,
    kunreiTyping: "twu",
  },
  とぇ: {
    hepburn: undefined,
    hepburnTyping: "twe",
    kunrei: undefined,
    kunreiTyping: "twe",
  },
  とぉ: {
    hepburn: undefined,
    hepburnTyping: "two",
    kunrei: undefined,
    kunreiTyping: "two",
  },
  な: {
    hepburn: "na",
    hepburnTyping: "na",
    kunrei: "na",
    kunreiTyping: "na",
  },
  に: {
    hepburn: "ni",
    hepburnTyping: "ni",
    kunrei: "ni",
    kunreiTyping: "ni",
  },
  ぬ: {
    hepburn: "nu",
    hepburnTyping: "nu",
    kunrei: "nu",
    kunreiTyping: "nu",
  },
  ね: {
    hepburn: "ne",
    hepburnTyping: "ne",
    kunrei: "ne",
    kunreiTyping: "ne",
  },
  の: {
    hepburn: "no",
    hepburnTyping: "no",
    kunrei: "no",
    kunreiTyping: "no",
  },
  にゃ: {
    hepburn: "nya",
    hepburnTyping: "nya",
    kunrei: "nya",
    kunreiTyping: "nya",
  },
  にぃ: {
    hepburn: undefined,
    hepburnTyping: "nyi",
    kunrei: undefined,
    kunreiTyping: "nyi",
  },
  にゅ: {
    hepburn: "nyu",
    hepburnTyping: "nyu",
    kunrei: "nyu",
    kunreiTyping: "nyu",
  },
  にぇ: {
    hepburn: undefined,
    hepburnTyping: "nye",
    kunrei: undefined,
    kunreiTyping: "nye",
  },
  にょ: {
    hepburn: "nyo",
    hepburnTyping: "nyo",
    kunrei: "nyo",
    kunreiTyping: "nyo",
  },
  は: {
    hepburn: "ha",
    hepburnTyping: "ha",
    kunrei: "ha",
    kunreiTyping: "ha",
  },
  ひ: {
    hepburn: "hi",
    hepburnTyping: "hi",
    kunrei: "hi",
    kunreiTyping: "hi",
  },
  ふ: {
    hepburn: "fu",
    hepburnTyping: "fu",
    kunrei: "hu",
    kunreiTyping: "hu",
  },
  へ: {
    hepburn: "he",
    hepburnTyping: "he",
    kunrei: "he",
    kunreiTyping: "he",
  },
  ほ: {
    hepburn: "ho",
    hepburnTyping: "ho",
    kunrei: "ho",
    kunreiTyping: "ho",
  },
  ひゃ: {
    hepburn: "hya",
    hepburnTyping: "hya",
    kunrei: "hya",
    kunreiTyping: "hya",
  },
  ひぃ: {
    hepburn: undefined,
    hepburnTyping: "hyi",
    kunrei: undefined,
    kunreiTyping: "hyi",
  },
  ひゅ: {
    hepburn: "hyu",
    hepburnTyping: "hyu",
    kunrei: "hyu",
    kunreiTyping: "hyu",
  },
  ひぇ: {
    hepburn: undefined,
    hepburnTyping: "hye",
    kunrei: undefined,
    kunreiTyping: "hye",
  },
  ひょ: {
    hepburn: "hyo",
    hepburnTyping: "hyo",
    kunrei: "hyo",
    kunreiTyping: "hyo",
  },
  ふぁ: {
    hepburn: undefined,
    hepburnTyping: "fa",
    kunrei: undefined,
    kunreiTyping: "fa",
  },
  ふぃ: {
    hepburn: undefined,
    hepburnTyping: "fi",
    kunrei: undefined,
    kunreiTyping: "fi",
  },
  ふぇ: {
    hepburn: undefined,
    hepburnTyping: "fe",
    kunrei: undefined,
    kunreiTyping: "fe",
  },
  ふぉ: {
    hepburn: undefined,
    hepburnTyping: "fo",
    kunrei: undefined,
    kunreiTyping: "fo",
  },
  ふゃ: {
    hepburn: undefined,
    hepburnTyping: "fya",
    kunrei: undefined,
    kunreiTyping: "fya",
  },
  ふゅ: {
    hepburn: undefined,
    hepburnTyping: "fyu",
    kunrei: undefined,
    kunreiTyping: "fyu",
  },
  ふょ: {
    hepburn: undefined,
    hepburnTyping: "fyo",
    kunrei: undefined,
    kunreiTyping: "fyo",
  },
  ま: {
    hepburn: "ma",
    hepburnTyping: "ma",
    kunrei: "ma",
    kunreiTyping: "ma",
  },
  み: {
    hepburn: "mi",
    hepburnTyping: "mi",
    kunrei: "mi",
    kunreiTyping: "mi",
  },
  む: {
    hepburn: "mu",
    hepburnTyping: "mu",
    kunrei: "mu",
    kunreiTyping: "mu",
  },
  め: {
    hepburn: "me",
    hepburnTyping: "me",
    kunrei: "me",
    kunreiTyping: "me",
  },
  も: {
    hepburn: "mo",
    hepburnTyping: "mo",
    kunrei: "mo",
    kunreiTyping: "mo",
  },
  みゃ: {
    hepburn: "mya",
    hepburnTyping: "mya",
    kunrei: "mya",
    kunreiTyping: "mya",
  },
  みぃ: {
    hepburn: undefined,
    hepburnTyping: "myi",
    kunrei: undefined,
    kunreiTyping: "myi",
  },
  みゅ: {
    hepburn: "myu",
    hepburnTyping: "myu",
    kunrei: "myu",
    kunreiTyping: "myu",
  },
  みぇ: {
    hepburn: undefined,
    hepburnTyping: "mye",
    kunrei: undefined,
    kunreiTyping: "mye",
  },
  みょ: {
    hepburn: "myo",
    hepburnTyping: "myo",
    kunrei: "myo",
    kunreiTyping: "myo",
  },
  や: {
    hepburn: "ya",
    hepburnTyping: "ya",
    kunrei: "ya",
    kunreiTyping: "ya",
  },
  ゆ: {
    hepburn: "yu",
    hepburnTyping: "yu",
    kunrei: "yu",
    kunreiTyping: "yu",
  },
  よ: {
    hepburn: "yo",
    hepburnTyping: "yo",
    kunrei: "yo",
    kunreiTyping: "yo",
  },
  いぇ: {
    hepburn: undefined,
    hepburnTyping: "ye",
    kunrei: undefined,
    kunreiTyping: "ye",
  },
  ら: {
    hepburn: "ra",
    hepburnTyping: "ra",
    kunrei: "ra",
    kunreiTyping: "ra",
  },
  り: {
    hepburn: "ri",
    hepburnTyping: "ri",
    kunrei: "ri",
    kunreiTyping: "ri",
  },
  る: {
    hepburn: "ru",
    hepburnTyping: "ru",
    kunrei: "ru",
    kunreiTyping: "ru",
  },
  れ: {
    hepburn: "re",
    hepburnTyping: "re",
    kunrei: "re",
    kunreiTyping: "re",
  },
  ろ: {
    hepburn: "ro",
    hepburnTyping: "ro",
    kunrei: "ro",
    kunreiTyping: "ro",
  },
  りゃ: {
    hepburn: "rya",
    hepburnTyping: "rya",
    kunrei: "rya",
    kunreiTyping: "rya",
  },
  りぃ: {
    hepburn: undefined,
    hepburnTyping: "ryi",
    kunrei: undefined,
    kunreiTyping: "ryi",
  },
  りゅ: {
    hepburn: "ryu",
    hepburnTyping: "ryu",
    kunrei: "ryu",
    kunreiTyping: "ryu",
  },
  りぇ: {
    hepburn: undefined,
    hepburnTyping: "rye",
    kunrei: undefined,
    kunreiTyping: "rye",
  },
  りょ: {
    hepburn: "ryo",
    hepburnTyping: "ryo",
    kunrei: "ryo",
    kunreiTyping: "ryo",
  },
  わ: {
    hepburn: "wa",
    hepburnTyping: "wa",
    kunrei: "wa",
    kunreiTyping: "wa",
  },
  ゐ: {
    hepburn: "i",
    hepburnTyping: "i",
    kunrei: "i",
    kunreiTyping: "i",
  },
  ゑ: {
    hepburn: "e",
    hepburnTyping: "e",
    kunrei: "e",
    kunreiTyping: "e",
  },
  を: {
    hepburn: "o",
    hepburnTyping: "o",
    kunrei: "o",
    kunreiTyping: "o",
  },
  うぁ: {
    hepburn: undefined,
    hepburnTyping: "wha",
    kunrei: undefined,
    kunreiTyping: "wha",
  },
  うぃ: {
    hepburn: undefined,
    hepburnTyping: "wi",
    kunrei: undefined,
    kunreiTyping: "wi",
  },
  うぇ: {
    hepburn: undefined,
    hepburnTyping: "we",
    kunrei: undefined,
    kunreiTyping: "we",
  },
  うぉ: {
    hepburn: undefined,
    hepburnTyping: "who",
    kunrei: undefined,
    kunreiTyping: "who",
  },
  ゔ: {
    hepburn: "bu",
    hepburnTyping: "vu",
    kunrei: "bu",
    kunreiTyping: "vu",
  },
  ゔぁ: {
    hepburn: "ba",
    hepburnTyping: "va",
    kunrei: "ba",
    kunreiTyping: "va",
  },
  ゔぃ: {
    hepburn: "bi",
    hepburnTyping: "vi",
    kunrei: "bi",
    kunreiTyping: "vi",
  },
  ゔぇ: {
    hepburn: "be",
    hepburnTyping: "ve",
    kunrei: "be",
    kunreiTyping: "ve",
  },
  ゔぉ: {
    hepburn: "bo",
    hepburnTyping: "vo",
    kunrei: "bo",
    kunreiTyping: "vo",
  },
  が: {
    hepburn: "ga",
    hepburnTyping: "ga",
    kunrei: "ga",
    kunreiTyping: "ga",
  },
  ぎ: {
    hepburn: "gi",
    hepburnTyping: "gi",
    kunrei: "gi",
    kunreiTyping: "gi",
  },
  ぐ: {
    hepburn: "gu",
    hepburnTyping: "gu",
    kunrei: "gu",
    kunreiTyping: "gu",
  },
  げ: {
    hepburn: "ge",
    hepburnTyping: "ge",
    kunrei: "ge",
    kunreiTyping: "ge",
  },
  ご: {
    hepburn: "go",
    hepburnTyping: "go",
    kunrei: "go",
    kunreiTyping: "go",
  },
  ぎゃ: {
    hepburn: "gya",
    hepburnTyping: "gya",
    kunrei: "gya",
    kunreiTyping: "gya",
  },
  ぎぃ: {
    hepburn: undefined,
    hepburnTyping: "gyi",
    kunrei: undefined,
    kunreiTyping: "gyi",
  },
  ぎゅ: {
    hepburn: "gyu",
    hepburnTyping: "gyu",
    kunrei: "gyu",
    kunreiTyping: "gyu",
  },
  ぎぇ: {
    hepburn: undefined,
    hepburnTyping: "gye",
    kunrei: undefined,
    kunreiTyping: "gye",
  },
  ぎょ: {
    hepburn: "gyo",
    hepburnTyping: "gyo",
    kunrei: "gyo",
    kunreiTyping: "gyo",
  },
  ぐぁ: {
    hepburn: undefined,
    hepburnTyping: "gwa",
    kunrei: undefined,
    kunreiTyping: "gwa",
  },
  ぐぃ: {
    hepburn: undefined,
    hepburnTyping: "gwi",
    kunrei: undefined,
    kunreiTyping: "gwi",
  },
  ぐぅ: {
    hepburn: undefined,
    hepburnTyping: "gwu",
    kunrei: undefined,
    kunreiTyping: "gwu",
  },
  ぐぇ: {
    hepburn: undefined,
    hepburnTyping: "gwe",
    kunrei: undefined,
    kunreiTyping: "gwe",
  },
  ぐぉ: {
    hepburn: undefined,
    hepburnTyping: "gwo",
    kunrei: undefined,
    kunreiTyping: "gwo",
  },
  ざ: {
    hepburn: "za",
    hepburnTyping: "za",
    kunrei: "za",
    kunreiTyping: "za",
  },
  じ: {
    hepburn: "ji",
    hepburnTyping: "ji",
    kunrei: "zi",
    kunreiTyping: "zi",
  },
  ず: {
    hepburn: "zu",
    hepburnTyping: "zu",
    kunrei: "zu",
    kunreiTyping: "zu",
  },
  ぜ: {
    hepburn: "ze",
    hepburnTyping: "ze",
    kunrei: "ze",
    kunreiTyping: "ze",
  },
  ぞ: {
    hepburn: "zo",
    hepburnTyping: "zo",
    kunrei: "zo",
    kunreiTyping: "zo",
  },
  じゃ: {
    hepburn: "ja",
    hepburnTyping: "ja",
    kunrei: "zya",
    kunreiTyping: "zya",
  },
  じぃ: {
    hepburn: undefined,
    hepburnTyping: "jyi",
    kunrei: undefined,
    kunreiTyping: "zyi",
  },
  じゅ: {
    hepburn: "ju",
    hepburnTyping: "ju",
    kunrei: "zyu",
    kunreiTyping: "zyu",
  },
  じぇ: {
    hepburn: undefined,
    hepburnTyping: "jye",
    kunrei: undefined,
    kunreiTyping: "zye",
  },
  じょ: {
    hepburn: "jo",
    hepburnTyping: "jo",
    kunrei: "zyo",
    kunreiTyping: "zyo",
  },
  ずぁ: {
    hepburn: undefined,
    hepburnTyping: "zwa",
    kunrei: undefined,
    kunreiTyping: "zwa",
  },
  ずぃ: {
    hepburn: undefined,
    hepburnTyping: "zwi",
    kunrei: undefined,
    kunreiTyping: "zwi",
  },
  ずぅ: {
    hepburn: undefined,
    hepburnTyping: "zwu",
    kunrei: undefined,
    kunreiTyping: "zwu",
  },
  ずぇ: {
    hepburn: undefined,
    hepburnTyping: "zwe",
    kunrei: undefined,
    kunreiTyping: "zwe",
  },
  ずぉ: {
    hepburn: undefined,
    hepburnTyping: "zwo",
    kunrei: undefined,
    kunreiTyping: "zwo",
  },
  だ: {
    hepburn: "da",
    hepburnTyping: "da",
    kunrei: "da",
    kunreiTyping: "da",
  },
  ぢ: {
    hepburn: "ji",
    hepburnTyping: "di",
    kunrei: "zi",
    kunreiTyping: "di",
  },
  づ: {
    hepburn: "zu",
    hepburnTyping: "du",
    kunrei: "zu",
    kunreiTyping: "du",
  },
  で: {
    hepburn: "de",
    hepburnTyping: "de",
    kunrei: "de",
    kunreiTyping: "de",
  },
  ど: {
    hepburn: "do",
    hepburnTyping: "do",
    kunrei: "do",
    kunreiTyping: "do",
  },
  ぢゃ: {
    hepburn: "ja",
    hepburnTyping: "dya",
    kunrei: "zya",
    kunreiTyping: "dya",
  },
  ぢぃ: {
    hepburn: undefined,
    hepburnTyping: "dyi",
    kunrei: undefined,
    kunreiTyping: "dyi",
  },
  ぢゅ: {
    hepburn: "ju",
    hepburnTyping: "dyu",
    kunrei: "zyu",
    kunreiTyping: "dyu",
  },
  ぢぇ: {
    hepburn: undefined,
    hepburnTyping: "dye",
    kunrei: undefined,
    kunreiTyping: "dye",
  },
  ぢょ: {
    hepburn: "jo",
    hepburnTyping: "dyo",
    kunrei: "zyo",
    kunreiTyping: "dyo",
  },
  でゃ: {
    hepburn: undefined,
    hepburnTyping: "dha",
    kunrei: undefined,
    kunreiTyping: "dha",
  },
  でぃ: {
    hepburn: undefined,
    hepburnTyping: "dhi",
    kunrei: undefined,
    kunreiTyping: "dhi",
  },
  でゅ: {
    hepburn: undefined,
    hepburnTyping: "dhu",
    kunrei: undefined,
    kunreiTyping: "dhu",
  },
  でぇ: {
    hepburn: undefined,
    hepburnTyping: "dhe",
    kunrei: undefined,
    kunreiTyping: "dhe",
  },
  でょ: {
    hepburn: undefined,
    hepburnTyping: "dho",
    kunrei: undefined,
    kunreiTyping: "dho",
  },
  どぁ: {
    hepburn: undefined,
    hepburnTyping: "dwa",
    kunrei: undefined,
    kunreiTyping: "dwa",
  },
  どぃ: {
    hepburn: undefined,
    hepburnTyping: "dwi",
    kunrei: undefined,
    kunreiTyping: "dwi",
  },
  どぅ: {
    hepburn: undefined,
    hepburnTyping: "dwu",
    kunrei: undefined,
    kunreiTyping: "dwu",
  },
  どぇ: {
    hepburn: undefined,
    hepburnTyping: "dwe",
    kunrei: undefined,
    kunreiTyping: "dwe",
  },
  どぉ: {
    hepburn: undefined,
    hepburnTyping: "dwo",
    kunrei: undefined,
    kunreiTyping: "dwo",
  },
  ば: {
    hepburn: "ba",
    hepburnTyping: "ba",
    kunrei: "ba",
    kunreiTyping: "ba",
  },
  び: {
    hepburn: "bi",
    hepburnTyping: "bi",
    kunrei: "bi",
    kunreiTyping: "bi",
  },
  ぶ: {
    hepburn: "bu",
    hepburnTyping: "bu",
    kunrei: "bu",
    kunreiTyping: "bu",
  },
  べ: {
    hepburn: "be",
    hepburnTyping: "be",
    kunrei: "be",
    kunreiTyping: "be",
  },
  ぼ: {
    hepburn: "bo",
    hepburnTyping: "bo",
    kunrei: "bo",
    kunreiTyping: "bo",
  },
  びゃ: {
    hepburn: "bya",
    hepburnTyping: "bya",
    kunrei: "bya",
    kunreiTyping: "bya",
  },
  びぃ: {
    hepburn: undefined,
    hepburnTyping: "byi",
    kunrei: undefined,
    kunreiTyping: "byi",
  },
  びゅ: {
    hepburn: "byu",
    hepburnTyping: "byu",
    kunrei: "byu",
    kunreiTyping: "byu",
  },
  びぇ: {
    hepburn: undefined,
    hepburnTyping: "bye",
    kunrei: undefined,
    kunreiTyping: "bye",
  },
  びょ: {
    hepburn: "byo",
    hepburnTyping: "byo",
    kunrei: "byo",
    kunreiTyping: "byo",
  },
  ぱ: {
    hepburn: "pa",
    hepburnTyping: "pa",
    kunrei: "pa",
    kunreiTyping: "pa",
  },
  ぴ: {
    hepburn: "pi",
    hepburnTyping: "pi",
    kunrei: "pi",
    kunreiTyping: "pi",
  },
  ぷ: {
    hepburn: "pu",
    hepburnTyping: "pu",
    kunrei: "pu",
    kunreiTyping: "pu",
  },
  ぺ: {
    hepburn: "pe",
    hepburnTyping: "pe",
    kunrei: "pe",
    kunreiTyping: "pe",
  },
  ぽ: {
    hepburn: "po",
    hepburnTyping: "po",
    kunrei: "po",
    kunreiTyping: "po",
  },
  ぴゃ: {
    hepburn: "pya",
    hepburnTyping: "pya",
    kunrei: "pya",
    kunreiTyping: "pya",
  },
  ぴぃ: {
    hepburn: undefined,
    hepburnTyping: "pyi",
    kunrei: undefined,
    kunreiTyping: "pyi",
  },
  ぴゅ: {
    hepburn: "pyu",
    hepburnTyping: "pyu",
    kunrei: "pyu",
    kunreiTyping: "pyu",
  },
  ぴぇ: {
    hepburn: undefined,
    hepburnTyping: "pye",
    kunrei: undefined,
    kunreiTyping: "pye",
  },
  ぴょ: {
    hepburn: "pyo",
    hepburnTyping: "pyo",
    kunrei: "pyo",
    kunreiTyping: "pyo",
  },
  ぁ: {
    hepburn: "a",
    hepburnTyping: "xa",
    kunrei: "a",
    kunreiTyping: "xa",
  },
  ぃ: {
    hepburn: "i",
    hepburnTyping: "xi",
    kunrei: "i",
    kunreiTyping: "xi",
  },
  ぅ: {
    hepburn: "u",
    hepburnTyping: "xu",
    kunrei: "u",
    kunreiTyping: "xu",
  },
  ぇ: {
    hepburn: "e",
    hepburnTyping: "xe",
    kunrei: "e",
    kunreiTyping: "xe",
  },
  ぉ: {
    hepburn: "o",
    hepburnTyping: "xo",
    kunrei: "o",
    kunreiTyping: "xo",
  },
  ん: {
    hepburn: "n",
    hepburnTyping: "n",
    kunrei: "n",
    kunreiTyping: "n",
  },
  っ: {
    hepburn: "tsu",
    hepburnTyping: "xtsu",
    kunrei: "tu",
    kunreiTyping: "xtu",
  },
  ゃ: {
    hepburn: "ya",
    hepburnTyping: "xya",
    kunrei: "ya",
    kunreiTyping: "xya",
  },
  ゅ: {
    hepburn: "yu",
    hepburnTyping: "xyu",
    kunrei: "yu",
    kunreiTyping: "xyu",
  },
  ょ: {
    hepburn: "yo",
    hepburnTyping: "xyo",
    kunrei: "yo",
    kunreiTyping: "xyo",
  },
  ゎ: {
    hepburn: "wa",
    hepburnTyping: "xwa",
    kunrei: "wa",
    kunreiTyping: "xwa",
  },
  ー: {
    hepburn: "-",
    hepburnTyping: "-",
    kunrei: "-",
    kunreiTyping: "-",
  },
};
