"use client";
import {
  Avatar,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import { Container, Draggable } from "../../../react-smooth-dnd";
import { StackEditList } from "../../StackSpace/StackEditList";
import { StackViewTab } from "../../StackSpace/components/ViewTab";
import { defaultContents } from "../../answerKit/data/problems";
import { ExamContentsData, ExamProblem } from "../../answerKit/type/examType";
import { LgContainer } from "../../siteKit/uiKit/Container/Container";
import { SimpleTabs } from "../../siteKit/uiKit/Tabs";
import { CommonAppProp } from "./CommponAppProp";
import { answerKitDataTable } from "../../StackSpace/editItems2/data/answerKitData";
import { SceneInfo } from "../../answerKit/SceneInfo";

export const AnswerKitView: FC<CommonAppProp> = ({
  currentNode,
  updateNode,
}) => {
  const [value, setValue] = useState(0);

  const [examContents, setExamContents] = useLocalStorage("exam-contents", {
    ...defaultContents,
  });
  const [contents, _setContents] = useState(defaultContents);

  const setContents = useCallback(
    (newContents: React.SetStateAction<ExamContentsData>) => {
      _setContents(newContents);
      setExamContents(newContents);
    },
    [_setContents, setExamContents]
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const onDrop = ({
    removedIndex,
    addedIndex,
  }: {
    removedIndex: number | null;
    addedIndex: number | null;
  }) => {
    if (removedIndex === null || addedIndex === null) {
      return;
    }
    setContents((prev) => {
      const items = prev.problems;
      const result = Array.from(items);
      const [removed] = result.splice(removedIndex, 1);
      result.splice(addedIndex, 0, removed);
      return { problems: result };
    });
  };

  const [selectedContentsIndex, setSelectedContentsIndex] = useState(-1);
  const [tabIndex, setTabIndex] = useState(0);
  const tabContents = useMemo(() => {
    return (
      contents.problems[selectedContentsIndex]?.tab[tabIndex] ?? {
        list: [],
      }
    );
  }, [contents, selectedContentsIndex, tabIndex]);
  const setTabContents = useCallback(
    (newContents: any) => {
      // callback or object
      const newProblems: ExamProblem[] = Array.from(contents.problems);
      if (typeof newContents === "function") {
        newProblems[selectedContentsIndex].tab[tabIndex] = newContents(
          newProblems[selectedContentsIndex].tab[tabIndex]
        );
      } else {
        newProblems[selectedContentsIndex].tab[tabIndex] = newContents;
      }
      setContents({
        problems: newProblems,
      });
    },
    [contents.problems, selectedContentsIndex, setContents, tabIndex]
  );

  const editorDataTable = answerKitDataTable;
  return (
    <>
      <LgContainer>
        <StackViewTab
          value={value}
          handleChange={handleChange}
          labels={["シーン設定", "問題設定", "プレビュー"]}
        />
      </LgContainer>
      {value === 0 && <SceneInfo contents={contents} />}
      {value === 1 && (
        <LgContainer
          layout={{
            height: "100%",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setExamContents({ ...defaultContents });
              _setContents({ ...defaultContents });
            }}
            sx={{ my: 1 }}
          >
            リセット
          </Button>
          <Divider />
          <Grid
            container
            spacing={2}
            sx={{
              my: 2,
            }}
          >
            <Grid xs={8}>
              {selectedContentsIndex !== -1 &&
                contents.problems[selectedContentsIndex] && (
                  <>
                    <SimpleTabs
                      value={tabIndex}
                      handleChange={(event, newValue) => {
                        setTabIndex(newValue);
                      }}
                      items={contents.problems[selectedContentsIndex].tab.map(
                        (tab) => tab.title
                      )}
                    />
                    <Stack>
                      <FormControlLabel
                        control={<Switch checked={false} onChange={() => {}} />}
                        sx={{ ml: 0, mt: 1 }}
                        label="開放条件を設定"
                      />
                      <FormControlLabel
                        control={<Switch checked={false} onChange={() => {}} />}
                        sx={{ ml: 0, mt: 1 }}
                        label="クローズ条件を設定"
                      />
                      <FormControlLabel
                        control={<Switch checked={false} onChange={() => {}} />}
                        sx={{ ml: 0, mt: 1 }}
                        label="表示条件を設定"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={
                              !!contents.problems[selectedContentsIndex]?.answer
                            }
                            onChange={() => {}}
                          />
                        }
                        sx={{ ml: 0, mt: 1 }}
                        label="解答を設定"
                      />

                      {!!contents.problems[selectedContentsIndex]
                        ?.answer?.[0] && (
                        <TextField
                          label="正解"
                          value={
                            contents.problems[
                              selectedContentsIndex
                            ]?.answer?.[0].words.join("\n") ?? ""
                          }
                          onChange={(event) => {
                            const newProblems = Array.from(contents.problems);
                            newProblems[selectedContentsIndex].title = [
                              event.target.value,
                            ];
                            setContents({
                              problems: newProblems,
                            });
                          }}
                          sx={{
                            mt: 2,
                            width: "350px",
                          }}
                          multiline
                        />
                      )}
                      <FormControlLabel
                        control={<Switch checked={false} onChange={() => {}} />}
                        sx={{ ml: 0, mt: 1 }}
                        label="選択肢を設定"
                      />
                    </Stack>
                    <StackEditList
                      key={selectedContentsIndex + "-" + tabIndex}
                      contents={tabContents}
                      setContents={setTabContents}
                      editorDataTable={editorDataTable}
                    />
                  </>
                )}
            </Grid>
            <Grid xs={4}>
              <List>
                <Container
                  dragHandleSelector=".drag-handle"
                  lockAxis="y"
                  onDrop={onDrop}
                >
                  {examContents?.problems?.map((problem, i) => (
                    <Draggable key={i}>
                      <ListItem>
                        <ListItemButton
                          className="drag-handle"
                          onClick={() => {
                            setSelectedContentsIndex(i);
                          }}
                        >
                          <ListItemAvatar>
                            <Avatar
                              alt={problem.title.join("")}
                              src={problem.thumbnail}
                              variant="square"
                              sx={{
                                width: 48,
                                height: 32,
                                img: { objectFit: "contain" },
                              }}
                            />
                          </ListItemAvatar>
                          <ListItemText primary={problem.title.join("")} />
                        </ListItemButton>
                      </ListItem>
                    </Draggable>
                  ))}
                </Container>
                <ListItem>
                  <ListItemButton
                    className="drag-handle"
                    onClick={() => {
                      setContents({
                        problems: [
                          ...examContents.problems,
                          {
                            title: ["問題"],
                            thumbnail: "",
                            tab: [
                              {
                                title: "問題",
                                list: [],
                              },
                            ],
                          },
                        ],
                      });
                    }}
                  >
                    <ListItemText primary={"アイテムを追加"} />
                  </ListItemButton>
                </ListItem>
              </List>
            </Grid>
          </Grid>
          {/*<StackEditList contents={storageContents} setContents={setContents} />*/}
        </LgContainer>
      )}
      {value === 2 && <></>}
    </>
  );
};
