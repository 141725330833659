"use client";
import { Alert, Button, Snackbar } from "@mui/material";
import { FC, useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import { pAndRTopContents } from "../../../../components/EnigmaHouse/Workspace/Project/p-and-r/data/pAndRTopData";
import { useSiteConfig } from "../../EniverseProvider";
import { StackEditList } from "../../StackSpace/StackEditList";
import { StackViewTab } from "../../StackSpace/components/ViewTab";
import { lpEditorDataTable } from "../../StackSpace/editItems2/data/lpEditorData";
import { MdContainer } from "../../siteKit/uiKit/Container/Container";
import { useDoc } from "../../storekit/firestore/pure/useDoc";
import { CommonAppProp } from "./CommponAppProp";
import { useDocWithLocalState } from "../../storekit/firestore/pure/useDocWithLocal";

const defaultContents = {
  list: [],
};

// const defaultContents = pAndRTopContents;

export const StackSpaceView: FC<CommonAppProp> = (props) => {
  const { currentNode, updateNode, project } = props;
  const [value, setValue] = useState(0);

  const [{ serviceId }] = useSiteConfig();
  const path = `/workspace/${serviceId}/project/${project}/contents/${currentNode.data?.contentsId}`;
  const {
    serverData: serverContents,
    localData: localContents,
    resetData,
    saveData,
    setLocalData,
  } = useDocWithLocalState<any>({
    path,
    initialData: { ...defaultContents },
  });

  console.log("serverContents", serverContents);
  const [changed, setChanged] = useState(false);

  const setContents = (newContents: any) => {
    setChanged(true);
    setLocalData(newContents);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          保存しました
        </Alert>
      </Snackbar>
      <Button
        variant="outlined"
        color="primary"
        onClick={async () => {
          setChanged(false);
          await saveData();
          handleClick();
        }}
        sx={{ my: 1 }}
        disabled={!changed}
      >
        下書き保存
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={async () => {}}
        sx={{ my: 1 }}
        disabled={!changed}
      >
        リリース
      </Button>
      <MdContainer>
        <StackViewTab value={value} handleChange={handleChange} />
      </MdContainer>
      {value === 0 && (
        <MdContainer>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              resetData();
            }}
            sx={{ my: 1 }}
          >
            リセット
          </Button>
          {localContents && (
            <StackEditList
              contents={localContents}
              setContents={setContents}
              editorDataTable={lpEditorDataTable}
            />
          )}
        </MdContainer>
      )}
      {value === 1 && <></>}
    </>
  );
};
