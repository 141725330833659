import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  TextField
} from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import { useStatePersist } from "use-state-persist";
import { DictionaryKey } from "../../../../../../../param/dictionaries";
import { DictionaryMatchFilter } from "../../../../../../../param/generalSearchFilter";
import {
  Sequence,
} from "../../../../../../../param/generalSearchQuery";
import { RegexReplace } from "../../../../../../../param/generalSearchTransform";
import { DictionarySelector } from "../../../../../commonStudio/DictionarySelector";
import { BasicFormProps } from "../../FormProps";
import { presetItems } from "../../list/presetItems";

export const RotateKeyForm: FC<BasicFormProps<Sequence>> = ({
  index,
  query,
  updateQuery,
  isDemo,
  onBlur,
}) => {
  const replacementToParams = (replacement: string) => {
    const match = replacement.match(
      /rotate\(\s*([^\s,()]*?)\s*,\s*([^\s,()]+)\s*,\s*([0-9-~&]+)\s*(?:,\s*([^()]*)\s*)?\s*\)/
    );
    if (!match) return;
    console.log(match, match[1] || "$1", match[2], match[3], match[4]);
    return {
      text: match[1] || "$1",
      list: match[2],
      rotate: match[3],
      loopOption: match[4],
    };
  };

  const [text] = useState<string>(
    replacementToParams((query.processes[0] as RegexReplace)?.replacement ?? "")
      ?.text || "$1"
  );
  const [list, setList] = useState<string>(
    replacementToParams((query.processes[0] as RegexReplace)?.replacement ?? "")
      ?.list || ""
  );

  const [rotate, setRotate] = useState<string>(
    replacementToParams((query.processes[0] as RegexReplace)?.replacement ?? "")
      ?.rotate || ""
  );

  const [loopOption, setLoopOption] = useState<string>(
    replacementToParams((query.processes[0] as RegexReplace)?.replacement ?? "")
      ?.loopOption || ""
  );

  const [editableItems] = useStatePersist<{ name: string; value: string }[]>(
    "enigma-studio-function-search-editable-items",
    []
  );
  const items = useMemo(() => {
    return [...presetItems, ...editableItems];
  }, [editableItems]);

  useEffect(() => {
    query.processes = query.processes || [
      {
        type: "regexReplace",
        pattern: "",
        replacement: "rotate($1,,)",
        matchOnly: false,
      },
      {
        type: "dictionaryMatch",
        dictionary: "",
        anagramMatch: false,
      },
    ];
    const process = query.processes[0];
    if (process.type !== "regexReplace") return;

    if (
      process.replacement ===
      `rotate(${text},${list},${rotate}${loopOption ? `,${loopOption}` : ""})`
    ) {
      return;
    }
    process.pattern = "";
    process.replacement = `rotate(${text},${list},${rotate}${
      loopOption ? `,${loopOption}` : ""
    })`;
    updateQuery(index, "processes", query.processes);
  }, [text, list, rotate, loopOption, query, updateQuery, index]);

  const [dictionary, setDictionary] = useState<string>(
    (query.processes[1] as DictionaryMatchFilter)?.dictionary || ""
  );
  const [anagramMatch, setAnagramMatch] = useState<boolean>(
    (query.processes[1] as DictionaryMatchFilter)?.anagramMatch || false
  );

  useEffect(() => {
    query.processes = query.processes || [
      {
        type: "regexReplace",
        pattern: "",
        replacement: "rotate($1,,)",
        matchOnly: false,
      },
      {
        type: "dictionaryMatch",
        dictionary: "",
        anagramMatch: false,
      },
    ];
    const process = query.processes[1] || {
      type: "dictionaryMatch",
      dictionary: "",
      anagramMatch: false,
    };
    if (process.type !== "dictionaryMatch") return;
    if (
      process.dictionary === dictionary &&
      process.anagramMatch === anagramMatch
    ) {
      return;
    }
    process.dictionary = dictionary as DictionaryKey;
    process.anagramMatch = anagramMatch;
    updateQuery(index, "processes", query.processes);
  }, [dictionary, anagramMatch, updateQuery, index, query]);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Autocomplete
          id="free-solo-text"
          freeSolo
          options={items.map((option) => option.value)}
          value={list || ""}
          onChange={(event: any, newValue: string | null) => {
            setList(newValue || "");
          }}
          onBlur={onBlur}
          disabled={isDemo}
          renderInput={(params) => (
            <TextField
              {...params}
              label="リスト・文字列"
              variant="outlined"
              sx={{ ml: 1, width: "40vw", mb: 2 }}
            />
          )}
        />
        <TextField
          label="移動量"
          variant="outlined"
          value={rotate || ""}
          onChange={(e) => {
            setRotate(e.target.value);
          }}
          onBlur={onBlur}
          sx={{ ml: 1, width: "20vw", mb: 2 }}
          disabled={isDemo}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={loopOption.includes("l") || false}
              onChange={(e) => setLoopOption(e.target.checked ? "l" : "")}
            />
          }
          sx={{ ml: 1 }}
          label="ループを許容"
          disabled={isDemo}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <DictionarySelector
          labelName="変換後辞書"
          dictionary={dictionary}
          setDictionary={setDictionary}
          isDemo={isDemo}
          onBlur={onBlur}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={anagramMatch || false}
              onChange={(e) => setAnagramMatch(e.target.checked)}
            />
          }
          sx={{ ml: 1 }}
          label="アナグラムマッチ"
          disabled={isDemo}
          onBlur={onBlur}
        />
      </Box>
    </>
  );
};
