import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Tooltip,
} from "@mui/material";
import { FC } from "react";
import { useStatePersist } from "use-state-persist";
import { BasicFormProps } from "../FormProps";
import { FishBoneFilter } from "../../../../../../param/generalSearchFilter";
export const FishboneFilterForm: FC<BasicFormProps<FishBoneFilter>> = ({
  index,
  query,
  updateQuery,
  isDemo,
}) => {
  const [displayTooltip, setDisplayTooltip] = useStatePersist(
    "general-search-display-tooltip",
    true
  );

  return (
    <>
      <TextField
        label="キーワード"
        helperText="カンマ,/,スペース,改行区切りで複数の単語を入力, *で任意の文字"
        variant="outlined"
        type="number"
        multiline
        value={query.keyword || ""}
        disabled={isDemo}
        onChange={(e) => updateQuery(index, "keyword", e.target.value)}
        sx={{ ml: 1, mb: 2 }}
      />

      <Box sx={{ mt: 2 }}>
        <Tooltip
          title={
            displayTooltip &&
            "キーワードをいくつか使ってできる単語も拾います。長さフィルタと併用します"
          }
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={query.partialMatch || false}
                onChange={(e) => {
                  updateQuery(index, "partialMatch", e.target.checked);
                }}
              />
            }
            sx={{ ml: 1 }}
            label="部分マッチ"
            disabled={isDemo}
          />
        </Tooltip>
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => {
                updateQuery(index, "ordered", e.target.checked);
              }}
              checked={query.ordered}
            />
          }
          sx={{ ml: 1 }}
          label="順番固定"
          disabled={isDemo}
        />

        {query.partialMatch && (
          <FormControlLabel
            control={
              <Checkbox
                checked={query.consecutively}
                onChange={(e) => {
                  updateQuery(index, "consecutively", e.target.checked);
                }}
              />
            }
            sx={{ ml: 1 }}
            label="連続した単語から拾う"
            disabled={isDemo}
          />
        )}
      </Box>
    </>
  );
};
