import {
  Box,
  Button,
  Container,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Title } from "../../common/Title";
import { FC, useMemo, useState } from "react";
import { PlainText } from "../../common/PlainText";
import { Helmet } from "react-helmet";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { JpWrap, JpWrapProps } from "../../../../lib/jp-wrap/jp-wrap";
import { useLocalStorage } from "usehooks-ts";
import { textLength } from "../../../../lib/wordGenerator/textLength";

export const WritingCounter: FC = () => {
  const [text, setText] = useState("");
  const [width, setWidth] = useLocalStorage("writing-counter-width", 20);
  const [options, setOptions] = useLocalStorage<JpWrapProps>(
    "writing-counter-options",
    {
      half: false,
      trim: false,
      breakAll: false,
      fullWidthSpace: true,
      sameWidth: false,
    }
  );

  const converted = useMemo(() => {
    const jpWrap = new JpWrap(width, options);
    return jpWrap.wrap(text).join("\n");
  }, [text, width, options]);

  const lineCount = useMemo(() => {
    return text.split("\n").length;
  }, [text]);
  const textCount = useMemo(() => {
    return textLength(text);
  }, [text]);
  const lineCountNonSpace = useMemo(() => {
    return text.split("\n").filter((e) => e.replace(/\s/g, "").length > 0)
      .length;
  }, [text]);

  const textCountNonSpace = useMemo(() => {
    return textLength(text.replace(/\s/g, ""));
  }, [text]);

  const writingLineCount = useMemo(() => {
    return converted.split("\n").length;
  }, [converted]);

  const writingTextCount = useMemo(() => {
    return writingLineCount * width;
  }, [writingLineCount, width]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Helmet>
        <title>原稿文字数カウント - EnigmaStudio</title>
        <meta
          name="description"
          content="入力した文章を印刷物として表記した時の文字数・行数をカウントします。"
        />
      </Helmet>
      <Title>原稿文字数カウント</Title>
      <PlainText>
        <span style={{ fontSize: "1rem" }}>
          入力した文章を印刷物として表記した時の文字数・行数をカウントします。
          <br />
          入力したデータはブラウザ内でのみ処理され、外部に送信されることはありません。
        </span>
      </PlainText>
      <Box>
        <Stack>
          <Box maxWidth={"100%"}>
            <TextField
              label="テキストボックス"
              variant="outlined"
              sx={{
                width: "100%",
                mt: 2,
              }}
              multiline
              rows={8}
              value={text}
              onChange={(event) => {
                setText(event.target.value);
              }}
              inputProps={{}}
            />
          </Box>
          <Typography
            variant="body2"
            sx={{
              color: "#ab47bc",
              my: 0,
              mb: 0.5,
              py: 0,
            }}
          >
            {lineCount.toLocaleString()}行 {textCount.toLocaleString()}文字
            <br />
            {lineCountNonSpace.toLocaleString()}行{" "}
            {textCountNonSpace.toLocaleString()}文字（空白・空行を除く）
          </Typography>
          <Box maxWidth={"100%"}>
            <TextField
              label="原稿"
              variant="outlined"
              sx={{
                width: "100%",
                mt: 2,
              }}
              multiline
              rows={8}
              value={converted}
              InputProps={{
                readOnly: true,
              }}
              aria-readonly="true"
            />
          </Box>
          <Typography
            variant="body2"
            sx={{
              color: "#ab47bc",
              my: 0,
              mb: 0.5,
              py: 0,
            }}
          >
            {writingLineCount.toLocaleString()}行{" "}
            {writingTextCount.toLocaleString()}文字
          </Typography>
          <Box maxWidth={"100%"}>
            <Button
              sx={{ mt: 2, mr: 2 }}
              variant="contained"
              onClick={() => {
                setText("");
              }}
              disabled={text.trimEnd() === ""}
            >
              リセット
            </Button>
            <Button
              sx={{ mt: 2, mr: 2 }}
              variant="contained"
              onClick={() => {
                navigator.clipboard.writeText(text);
              }}
              startIcon={
                <ContentCopyIcon
                  style={{ fontSize: "1rem", marginRight: "0.5rem" }}
                />
              }
            >
              元の文をコピー
            </Button>
            <Button
              sx={{ mt: 2, mr: 2 }}
              variant="contained"
              onClick={() => {
                navigator.clipboard.writeText(converted);
              }}
              startIcon={
                <ContentCopyIcon
                  style={{ fontSize: "1rem", marginRight: "0.5rem" }}
                />
              }
            >
              変換後をコピー
            </Button>
            <Button
              sx={{ mt: 2, mr: 2 }}
              variant="contained"
              onClick={() => {
                navigator.clipboard.readText().then((clipText) => {
                  setText(clipText);
                });
              }}
              startIcon={
                <ContentPasteIcon
                  style={{ fontSize: "1rem", marginRight: "0.5rem" }}
                />
              }
            >
              ペースト
            </Button>
          </Box>

          <Box maxWidth={"100%"} sx={{ mt: 2 }}>
            <Title>詳細設定</Title>
            <Typography
              variant="body2"
              sx={{
                color: "gray",
                my: 0,
                mb: 0.5,
                py: 0,
              }}
            >
              設定はブラウザに保存されます
            </Typography>
          </Box>
          <Box maxWidth={"100%"} sx={{ mt: 2 }}>
            <TextField
              label="行幅"
              variant="outlined"
              type="number"
              value={width}
              onChange={(event) => {
                try {
                  setWidth(parseInt(event.target.value));
                } catch (e) {
                  console.error(e);
                }
              }}
            />
          </Box>
          <FormControlLabel
            control={
              <Switch
                checked={options.trim}
                onChange={(event) => {
                  setOptions({ ...options, trim: event.target.checked });
                }}
              />
            }
            label="入力文字列の改行を無視"
          />
          <FormControlLabel
            control={
              <Switch
                checked={options.half}
                onChange={(event) => {
                  setOptions({ ...options, half: event.target.checked });
                }}
              />
            }
            label="半角文字の行頭禁則処理を行う"
          />
          <FormControlLabel
            control={
              <Switch
                checked={options.breakAll}
                onChange={(event) => {
                  setOptions({ ...options, breakAll: event.target.checked });
                }}
              />
            }
            label="英単語の文中でも改行"
          />
          <FormControlLabel
            control={
              <Switch
                checked={options.fullWidthSpace}
                onChange={(event) => {
                  setOptions({
                    ...options,
                    fullWidthSpace: event.target.checked,
                  });
                }}
              />
            }
            label="行頭の全角スペースを削除"
          />
          <FormControlLabel
            control={
              <Switch
                checked={options.sameWidth}
                onChange={(event) => {
                  setOptions({ ...options, sameWidth: event.target.checked });
                }}
              />
            }
            label="半角も1文字として扱う"
          />
        </Stack>
        <Box maxWidth={"100%"} sx={{ mt: 2 }}>
          <Typography
            variant="body2"
            sx={{
              color: "gray",
              my: 0,
              mb: 0.5,
              py: 0,
            }}
          >
            ※ ペーストは、ブラウザの設定によっては動作しない場合があります。
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};
