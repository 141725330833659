import { NodeModel } from "@minoru/react-dnd-treeview";
import { FC, useMemo } from "react";
import { useImaginateConfig } from '../../../components/EnigmaHouse/Studio/InnerImaginate/ImaginateProvider';
import { useSiteConfig } from "../EniverseProvider";
import { PuzsqContestMain } from "../puzsqView/PuzsqContestMain";
import { If } from "../util/Condition";
import { randomKeyGen } from "../util/keygen";
import { AnswerKitView } from "./mainView/AnswerKitView";
import { App } from "./mainView/Apps";
import { ExcalidrawView } from "./mainView/ExcalidrawView";
import { FolderView } from "./mainView/FolderView";
import { GridSheetView } from "./mainView/GridSheetView";
import { ImageLibraryView } from "./mainView/ImageLibraryView";
import { StackSpaceView } from "./mainView/StackSpaceView";
import { CustomData, TreeCommand } from "./treeView/types";

export type MainProps = {
  currentNode: NodeModel<CustomData>;
  updateNode: (node: NodeModel<CustomData>) => void;
  treeData: NodeModel<CustomData>[];
  setSelectedNode: (node: NodeModel<CustomData>) => void;
  treeCommand: TreeCommand;
};

export const Main: FC<MainProps> = (props) => {
  const { currentNode, updateNode, treeData, setSelectedNode, treeCommand } =
    props;

  const [{ serviceId }] = useSiteConfig();
  const [{ projectId }] = useImaginateConfig();
  const workspace = useMemo(() => serviceId, [serviceId]);


  if (!currentNode) {
    return <></>;
  }

  const createDocument = (type: string) => {
    if (type.endsWith("folder")) {
      updateNode({
        ...currentNode,
        droppable: true,
        data: {
          ...currentNode.data,
          contentsId: currentNode.data?.contentsId || randomKeyGen(16),
          fileType: type,
        },
      });
    } else {
      updateNode({
        ...currentNode,
        droppable: false,
        data: {
          ...currentNode.data,
          contentsId: currentNode.data?.contentsId || randomKeyGen(16),
          fileType: type,
        },
      });
    }
  };

  if (!currentNode.data || currentNode.data.fileType === "") {
    return (
      <>
        <If condition={!currentNode.droppable}>
          <App createDocument={createDocument} />
        </If>
        <If condition={currentNode.droppable}>
          <FolderView
            currentNode={currentNode}
            treeData={treeData}
            addApp={() => {
              treeCommand.addNode(currentNode.id, treeData);
            }}
            setSelectedNode={setSelectedNode}
          />
        </If>
      </>
    );
  }

  return (
    <>
      <If condition={currentNode.data.fileType === "app"}>
        <App createDocument={createDocument} />
      </If>
      <If condition={currentNode.data.fileType === "folder"}>
        <FolderView
          currentNode={currentNode}
          treeData={treeData}
          addApp={() => {
            treeCommand.addNode(currentNode.id, treeData);
          }}
          setSelectedNode={setSelectedNode}
        />
      </If>
      <If condition={currentNode.data.fileType === "image-library"}>
        <ImageLibraryView
          currentNode={currentNode}
          updateNode={updateNode}
          workspace={workspace}
          project={projectId}
        />
      </If>
      <If condition={currentNode.data.fileType === "stack-space"}>
        <StackSpaceView
          currentNode={currentNode}
          updateNode={updateNode}
          workspace={workspace}
          project={projectId}
        />
        ;
      </If>
      <If condition={currentNode.data.fileType === "excalidraw"}>
        <ExcalidrawView
          currentNode={currentNode}
          updateNode={updateNode}
          workspace={workspace}
          project={projectId}
        />
        ;
      </If>
      <If condition={currentNode.data.fileType === "answerkit"}>
        <AnswerKitView
          currentNode={currentNode}
          updateNode={updateNode}
          workspace={workspace}
          project={projectId}
        />
        ;
      </If>
      <If condition={currentNode.data.fileType === "gridsheet"}>
        <GridSheetView
          currentNode={currentNode}
          updateNode={updateNode}
          workspace={workspace}
          project={projectId}
        />
        ;
      </If>
      <If condition={currentNode.data.fileType.startsWith("puzsq")}>
        <PuzsqContestMain {...props} />;
      </If>
    </>
  );
};
