export const hiraganaToKatakana = (word: string) => {
  // hiragana to katakana
  return word.replace(/[\u3041-\u3096]/g, (match) =>
    String.fromCharCode(match.charCodeAt(0) + 0x60)
  );
};

export const katakanaToHiragana = (word: string) => {
  // katakana to hiragana
  return word.replace(/[\u30a1-\u30f6]/g, (match) =>
    String.fromCharCode(match.charCodeAt(0) - 0x60)
  );
};

const hiraganaToRomajiMap: { [key: string]: string } = {
  あ: "a",
  い: "i",
  う: "u",
  え: "e",
  お: "o",
  か: "ka",
  き: "ki",
  く: "ku",
  け: "ke",
  こ: "ko",
  さ: "sa",
  し: "shi",
  す: "su",
  せ: "se",
  そ: "so",
  た: "ta",
  ち: "chi",
  つ: "tsu",
  て: "te",
  と: "to",
  な: "na",
  に: "ni",
  ぬ: "nu",
  ね: "ne",
  の: "no",
  は: "ha",
  ひ: "hi",
  ふ: "fu",
  へ: "he",
  ほ: "ho",
  ま: "ma",
  み: "mi",
  む: "mu",
  め: "me",
  も: "mo",
  や: "ya",
  ゆ: "yu",
  よ: "yo",
  ら: "ra",
  り: "ri",
  る: "ru",
  れ: "re",
  ろ: "ro",
  わ: "wa",
  を: "wo",
  ん: "n",
  っ: "",
};

const smallHiraganaToRomajiMap: { [key: string]: string } = {
  ゃ: "ya",
  ゅ: "yu",
  ょ: "yo",
};

export const hiraganaToRomaji = (hiragana: string): string => {
  let romaji = "";
  let smallTsu = false;

  for (let i = 0; i < hiragana.length; i++) {
    const char = hiragana[i];

    if (smallTsu) {
      romaji += hiraganaToRomajiMap[char]?.charAt(0) ?? "";
      smallTsu = false;
    }

    if (char === "っ") {
      smallTsu = true;
      continue;
    }

    if (smallHiraganaToRomajiMap[char]) {
      romaji = romaji.slice(0, -1); // Remove the last 'i' from the romaji string
      romaji += smallHiraganaToRomajiMap[char];
    } else {
      romaji += hiraganaToRomajiMap[char] ?? char;
    }
  }

  return romaji;
};
