import { MenuBook } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormLabel,
  LinearProgress,
  Stack,
  Switch,
  ToggleButton,
  Typography,
} from "@mui/material";
import { useCallback, useContext, useState } from "react";
import { useWorker } from "../../../hooks/useWorker";
import { readFileAsText } from "../../../lib/readFileAsText";
import { DictionaryKey, dictionaries } from "../../../param/dictionaries";
import { EnigmaStudioContext } from "../context/EnigmaStudioContext";
import { useStatePersist } from "use-state-persist";

export const NewDictionarySelector = ({
  selectedDictionaries,
  setSelectedDictionaries,
  displayLabel = true,
}: {
  selectedDictionaries: string[];
  setSelectedDictionaries: (value: string[]) => void;
  displayLabel?: boolean;
}) => {
  const ctx = useContext(EnigmaStudioContext);
  const worker = useWorker();

  const onChangeFile = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      if (files) {
        const file = files[0];
        const newChecked = [...ctx.enabledDictionary];
        readFileAsText(file).then((text) => {
          worker.loadCustom(text, (result: string[] | undefined) => {
            console.log("load", result?.length, newChecked.length);
            if (
              result?.sort().join(",") ===
              ctx.enabledDictionary.sort().join(",")
            ) {
              ctx.setLoaded(true);
            }
            const progress =
              (((result?.length ?? 0) / newChecked.length) * 100) | 0;
            ctx.setLoadProgress(Math.min(progress, 200 - progress));
          });
        });
      }
    },
    [ctx, worker]
  );

  const handleToggle = useCallback(
    (value: string) => () => {
      const key = value as DictionaryKey;
      const currentIndex = ctx.enabledDictionary.indexOf(key);
      const newChecked = [...ctx.enabledDictionary];
      console.log("handleToggle", key, currentIndex, newChecked);

      if (currentIndex === -1) {
        newChecked.push(key);
        ctx.setLoaded(false);
        ctx.setLoadProgress(
          ((ctx.enabledDictionary.length / newChecked.length) * 100) | 0
        );
        worker.load(
          key,
          ctx.enabledDictionary.includes("all"),
          (result: string[] | undefined) => {
            console.log("load", result?.length, newChecked.length);

            if (
              result?.sort().join(",") ===
              ctx.enabledDictionary.sort().join(",")
            ) {
              ctx.setLoaded(true);
            }
            const progress =
              (((result?.length ?? 0) / newChecked.length) * 100) | 0;
            ctx.setLoadProgress(Math.min(progress, 200 - progress));
            console.log(
              progress,
              ctx.loadProgress,
              result?.length ?? 0,
              newChecked.length
            );
          }
        );
      } else {
        newChecked.splice(currentIndex, 1);
        worker.unload(key, ctx.enabledDictionary.includes("all"));
      }

      ctx.setEnabledDictionary(newChecked);
    },
    [ctx, worker]
  );

  const handleOn = useCallback(
    (_dictionaries: DictionaryKey[]) => {
      worker.unload("all", false);
      (Object.keys(dictionaries) as DictionaryKey[]).forEach(
        (item: DictionaryKey) => {
          if (!_dictionaries.includes(item)) {
            worker.unload(item, false);
          }
        }
      );
      const newChecked = _dictionaries;
      let count = 0;
      _dictionaries.forEach((item: DictionaryKey) => {
        if (!ctx.enabledDictionary.includes(item)) {
          ++count;
          ctx.setLoaded(false);
          ctx.setLoadProgress(
            ((ctx.enabledDictionary.length / count) * 100) | 0
          );
          worker.load(item, false, (result: string[] | undefined) => {
            console.log("load", result?.length, count);
            if (
              result?.sort().join(",") ===
              ctx.enabledDictionary.sort().join(",")
            ) {
              ctx.setLoaded(true);
            }
            const progress = (((result?.length ?? 0) / count) * 100) | 0;
            ctx.setLoadProgress(Math.min(progress, 200 - progress));
          });
        }
      });

      if (_dictionaries.includes("all")) {
        worker.load("all", true, (result: string[] | undefined) => {
          console.log("load", result?.length, newChecked.length);
          if (
            result?.sort().join(",") === ctx.enabledDictionary.sort().join(",")
          ) {
            ctx.setLoaded(true);
          }
          const progress =
            (((result?.length ?? 0) / newChecked.length) * 100) | 0;
          ctx.setLoadProgress(Math.min(progress, 200 - progress));
        });
      }
      ctx.setEnabledDictionary(newChecked);
    },
    [ctx, worker]
  );

  const handleOff = useCallback(
    (dictionaries: DictionaryKey[]) => {
      worker.unload("all", false);
      dictionaries.forEach((item: DictionaryKey) => {
        worker.unload(item, false);
      });
      ctx.setEnabledDictionary([]);
    },
    [ctx, worker]
  );

  const [loadMode, setLoadMode] = useState(false);
  const [multiSelect, setMultiSelect] = useStatePersist(
    "dictionary-multi-select",
    false
  );

  return (
    <Stack>
      <FormControl variant="outlined">
        {displayLabel && (
          <FormLabel id="radio-buttons-group-label">辞書</FormLabel>
        )}

        <Box sx={{ mt: 2 }}>
          {loadMode && (
            <LinearProgress
              variant="determinate"
              value={ctx.loadProgress}
              sx={{ mb: 1 }}
            />
          )}
          <ToggleButton
            value="check"
            selected={loadMode}
            size="small"
            onChange={() => {
              setLoadMode(!loadMode);
            }}
          >
            <MenuBook />
            {"　"}
            辞書読み込み
          </ToggleButton>

          <Typography variant="caption" sx={{ ml: 1 }}>
            {loadMode && "選択した辞書を読み込みます"}
          </Typography>
        </Box>

        {!loadMode && (
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>複数選択</Typography>
            <Switch
              checked={multiSelect}
              onChange={() => setMultiSelect(!multiSelect)}
            />
          </Stack>
        )}

        <>
          <Box sx={{ mt: 1 }}>
            <Box>
              {(loadMode
                ? Object.keys(dictionaries).map((e) => e as DictionaryKey)
                : ctx.enabledDictionary.sort(
                    (a, b) => dictionaries[a].index - dictionaries[b].index
                  )
              )
                .filter((item) => item !== "all")
                .map((item: DictionaryKey, i) => (
                  <>
                    <Chip
                      key={item}
                      variant={
                        ctx.enabledDictionary.includes(item)
                          ? selectedDictionaries.includes(item)
                            ? "filled"
                            : "outlined"
                          : "filled"
                      }
                      color={
                        ctx.enabledDictionary.includes(item)
                          ? selectedDictionaries.includes(item)
                            ? "primary"
                            : "default"
                          : "default"
                      }
                      label={dictionaries[item].name}
                      sx={{
                        mb: 1,
                        mr: 1,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (loadMode) {
                          if (selectedDictionaries.includes(item)) {
                            setSelectedDictionaries(
                              selectedDictionaries.filter((e) => e !== item)
                            );
                          }
                          handleToggle(dictionaries[item].key)();
                        } else {
                          if (multiSelect) {
                            if (selectedDictionaries.includes(item)) {
                              setSelectedDictionaries(
                                selectedDictionaries.filter((e) => e !== item)
                              );
                            } else {
                              setSelectedDictionaries([
                                ...selectedDictionaries,
                                item,
                              ]);
                            }
                          } else {
                            if (selectedDictionaries.includes(item)) {
                              setSelectedDictionaries([]);
                            } else {
                              setSelectedDictionaries([item]);
                            }
                          }
                        }
                      }}
                    />
                  </>
                ))}
            </Box>

            {loadMode && (
              <Box sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setSelectedDictionaries([]);
                    handleOff(Object.keys(dictionaries) as DictionaryKey[]);
                    handleOn(["buta", "cefr", "common", "illust1"]);
                  }}
                >
                  推奨設定で読み込み
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleOn(Object.keys(dictionaries) as DictionaryKey[]);
                  }}
                  sx={{ ml: 1 }}
                >
                  一括読み込み
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleOff(Object.keys(dictionaries) as DictionaryKey[]);
                  }}
                  sx={{ ml: 1 }}
                >
                  一括読み込み解除
                </Button>
              </Box>
            )}
            {!loadMode && multiSelect && (
              <Box sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setSelectedDictionaries(ctx.enabledDictionary);
                  }}
                >
                  一括選択
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setSelectedDictionaries([]);
                  }}
                  sx={{ ml: 1 }}
                >
                  一括選択解除
                </Button>
              </Box>
            )}
          </Box>
        </>
      </FormControl>
    </Stack>
  );
};
