import { FC } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Box } from "@mui/system";
import { BasicFormProps } from "../FormProps";
import { ReplacementInput } from "../../../../commonStudio/ReplacementInput";
import { ParallelTransform } from "../../../../../../param/generalSearchTransform";

export const ParallelTransformForm: FC<BasicFormProps<ParallelTransform>> = ({
  index,
  query,
  updateQuery,
  isDemo,
}) => {
  return (
    <>
      {query.processes?.map((process: any, pIndex: number) => (
        <Box key={pIndex} mb={0} mt={2}>
          <Button
            variant="text"
            color="secondary"
            onClick={() => {
              const updatedProcesses = [...(query.processes || [])];
              updatedProcesses.splice(pIndex, 1);
              updateQuery(index, "processes", updatedProcesses);
            }}
          >
            削除
          </Button>
          <FormControl variant="outlined" sx={{ mb: 2, width: "200px" }}>
            <InputLabel>変換処理タイプ</InputLabel>
            <Select
              value={process.type || ""}
              onChange={(e) => {
                const updatedProcesses = [...(query.processes || [])];
                updatedProcesses[pIndex].type = e.target.value;
                updateQuery(index, "processes", updatedProcesses);
              }}
              disabled={isDemo}
            >
              <MenuItem value="regexReplace">正規表現置換</MenuItem>
            </Select>
          </FormControl>

          {process.type === "regexReplace" && (
            <>
              <TextField
                label="正規表現パターン"
                variant="outlined"
                value={process.pattern || ""}
                onChange={(e) => {
                  const updatedProcesses = [...(query.processes || [])];
                  updatedProcesses[pIndex].pattern = e.target.value;
                  updateQuery(index, "processes", updatedProcesses);
                }}
                disabled={isDemo}
                sx={{ ml: 1 }}
              />
              <ReplacementInput
                replacement={process.replacement || ""}
                onReplacementChange={(newReplacement) => {
                  const updatedProcesses = [...(query.processes || [])];
                  updatedProcesses[pIndex].replacement = newReplacement;
                  updateQuery(index, "processes", updatedProcesses);
                }}
                disabled={isDemo}
                sx={{ ml: 1, width: "300px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={process.matchOnly || false}
                    onChange={(e) => {
                      const updatedProcesses = [...(query.processes || [])];
                      updatedProcesses[pIndex].matchOnly = e.target.checked;
                      updateQuery(index, "processes", updatedProcesses);
                    }}
                    sx={{ ml: 1 }}
                  />
                }
                disabled={isDemo}
                label="マッチのみ抽出"
              />
            </>
          )}
        </Box>
      ))}

      <Button
        variant="text"
        color="primary"
        onClick={() => {
          const updatedProcesses = [...(query.processes || []), {}];
          updateQuery(index, "processes", updatedProcesses);
        }}
        disabled={isDemo}
        sx={{ mb: 2 }}
      >
        変換処理を追加
      </Button>
    </>
  );
};
