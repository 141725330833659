import { Unsubscribe, doc, onSnapshot, setDoc } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { useFirebaseConfig } from "../../../EniverseProvider";

type DocArg<T> = {
  pathList?: string[] | null;
  initialData?: T;
};

type DocData<T> = {
  id: string;
  data: T;
};

const useMultipleDoc = <T>({
  pathList,
}: DocArg<T>): [{ [key: string]: DocData<T> }] => {
  const [dataTable, setLocalDataTable] = useState<{
    [key: string]: DocData<T>;
  }>({});
  const [{ cloudFirestore }] = useFirebaseConfig();
  useEffect(() => {
    if (!cloudFirestore) {
      return;
    }
    if (!pathList) {
      return;
    }
    console.log(pathList);
    const unsubList: Unsubscribe[] = [];
    pathList.forEach((path) => {
      const unsub = onSnapshot(doc(cloudFirestore, path), (docSnapshot) => {
        setLocalDataTable((prev) => {
          prev[path] = {
            id: docSnapshot.id,
            data: docSnapshot.data() as T,
          } as DocData<T>;
          return { ...prev };
        });
      });
      unsubList.push(unsub);
    });

    return () => {
      unsubList.forEach((unsub) => {
        unsub();
      });
    };
  }, [cloudFirestore, pathList]);

  return [dataTable];
};

export { useMultipleDoc };
