import { Box, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { DictionaryKey } from "../../../../../../../param/dictionaries";
import { DictionaryMatchFilter } from "../../../../../../../param/generalSearchFilter";
import { Sequence } from "../../../../../../../param/generalSearchQuery";
import { RegexReplace } from "../../../../../../../param/generalSearchTransform";
import { DictionarySelector } from "../../../../../commonStudio/DictionarySelector";
import { BasicFormProps } from "../../FormProps";

export const AnswerKeySearch: FC<BasicFormProps<Sequence>> = ({
  index,
  query,
  updateQuery,
  isDemo,
  onBlur,
}) => {
  const replacementToSrcStr = (replacement: string) => {
    const match = replacement.match(/pair\([^,]*,(.*),.*\)/);
    if (!match) return;
    return match[1];
  };
  const replacementToDstStr = (replacement: string) => {
    const match = replacement.match(/pair\(.*,.*,(.*)\)/);
    if (!match) return;
    return match[1];
  };

  const [srcStr, setSrcStr] = useState<string>(
    replacementToSrcStr(
      (query.processes[0] as RegexReplace)?.replacement ?? ""
    ) || ""
  );
  const [dstStr, setDstStr] = useState<string>(
    replacementToDstStr(
      (query.processes[0] as RegexReplace)?.replacement ?? ""
    ) || ""
  );
  const [dictionary, setDictionary] = useState<string>(
    (query.processes[1] as DictionaryMatchFilter)?.dictionary || ""
  );
  const [anagramMatch, setAnagramMatch] = useState<boolean>(
    (query.processes[1] as DictionaryMatchFilter)?.anagramMatch || false
  );

  useEffect(() => {
    query.processes = query.processes || [
      {
        type: "regexReplace",
        pattern: "",
        replacement: "",
      },
      {
        type: "dictionaryMatch",
        dictionary: "",
        anagramMatch: false,
      },
    ];
    const process = query.processes[0] || {
      type: "regexReplace",
      pattern: "",
      replacement: "",
    };
    if (process.type !== "regexReplace") return;
    if (process.replacement === `pair($1,${srcStr},${dstStr})`) return;
    process.pattern = "";
    process.replacement = `pair($1,${srcStr},${dstStr})`;
    updateQuery(index, "processes", query.processes);
  }, [srcStr, dstStr, updateQuery, index, query]);

  useEffect(() => {
    query.processes = query.processes || [
      {
        type: "regexReplace",
        pattern: "",
        replacement: "",
      },
      {
        type: "dictionaryMatch",
        dictionary: "",
        anagramMatch: false,
      },
    ];
    const process = query.processes[1] || {
      type: "dictionaryMatch",
      dictionary: "",
      anagramMatch: false,
    };
    if (process.type !== "dictionaryMatch") return;
    if (
      process.dictionary === dictionary &&
      process.anagramMatch === anagramMatch
    ) {
      return;
    }
    process.dictionary = dictionary as DictionaryKey;
    process.anagramMatch = anagramMatch;
    updateQuery(index, "processes", query.processes);
  }, [dictionary, anagramMatch, updateQuery, index, query]);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <TextField
          label="変換前リスト・文字列"
          variant="outlined"
          value={srcStr || ""}
          onChange={(e) => {
            setSrcStr(e.target.value);
          }}
          sx={{ ml: 1, width: "40vw", mb: 2 }}
          disabled={isDemo}
          onBlur={onBlur}
        />
      </Box>
      <Box>
        <TextField
          label="変換後リスト・文字列"
          variant="outlined"
          value={dstStr || ""}
          onChange={(e) => {
            setDstStr(e.target.value);
          }}
          sx={{ ml: 1, width: "40vw", mb: 2 }}
          disabled={isDemo}
          onBlur={onBlur}
        />
        <DictionarySelector
          labelName="変換後辞書"
          dictionary={dictionary}
          setDictionary={(value) => setDictionary(value)}
          isDemo={isDemo}
          onBlur={onBlur}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={anagramMatch || false}
              onChange={(e) => setAnagramMatch(e.target.checked)}
              onBlur={onBlur}
            />
          }
          sx={{ ml: 1 }}
          label="アナグラムマッチ"
          disabled={isDemo}
        />
      </Box>
    </>
  );
};
