import { Checkbox, FormControlLabel } from "@mui/material";
import { FC } from "react";
import { DictionarySelector } from "../../../../commonStudio/DictionarySelector";
import { BasicFormProps } from "../FormProps";
import { DictionaryMatchFilter } from "../../../../../../param/generalSearchFilter";

export const DictionaryMatchForm: FC<BasicFormProps<DictionaryMatchFilter>> = ({
  index,
  query,
  updateQuery,
  isDemo,
}) => {
  return (
    <>
      <DictionarySelector
        labelName="辞書"
        dictionary={query.dictionary}
        setDictionary={(value) => updateQuery(index, "dictionary", value)}
        isDemo={isDemo}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={query.anagramMatch || false}
            disabled={isDemo}
            onChange={(e) =>
              updateQuery(index, "anagramMatch", e.target.checked)
            }
          />
        }
        sx={{ ml: 1 }}
        label="アナグラムマッチ"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={query.notMatch || false}
            disabled={isDemo}
            onChange={(e) => updateQuery(index, "notMatch", e.target.checked)}
          />
        }
        sx={{ ml: 1 }}
        label="否定マッチ"
      />
    </>
  );
};
