import ArticleIcon from "@mui/icons-material/Article";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import CreateIcon from "@mui/icons-material/Create";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import SearchIcon from "@mui/icons-material/Search";
import WorkIcon from "@mui/icons-material/Work";
import { Container, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC, useContext } from "react";
import { useFirebaseLogin } from "../../../_lib/eniverse/siteKit/auth/useFirebaseLogin";
import {
  getLongServiceName,
  getStudioServiceDescription,
} from "../common/CommonAppBar";
import { PlainText } from "../common/PlainText";
import { Title } from "../common/Title";
import { EnigmaStudioContext } from "../context/EnigmaStudioContext";
import { SimpleSearchForm } from "./DictionaryTools/GeneralSearch/recipe/form/SimpleSearch";
import { CommonMenuItem, MenuItem } from "./Menu/CommonMenuItem";

const menuItems: MenuItem[] = [
  {
    title: "辞書検索",
    path: "/search-menu",
    description: "辞書を使った検索ツール",
    Icon: SearchIcon,
  },
  {
    title: "ツール",
    path: "/tool-menu",
    description: "辞書検索以外のツール群",
    Icon: CreateIcon,
  },
  {
    title: "旧ツール",
    path: "/oldtool-menu",
    description: "EnigmaStudioの外部で公開した謎制作支援ツール",
    Icon: OpenInBrowserIcon,
  },
];

const guideItems: MenuItem[] = [
  {
    title: "逆引き正規表現 forクロスワード検索",
    path: "https://scrapbox.io/wandsbox/%E9%80%86%E5%BC%95%E3%81%8D%E6%AD%A3%E8%A6%8F%E8%A1%A8%E7%8F%BE_for%E3%82%AF%E3%83%AD%E3%82%B9%E3%83%AF%E3%83%BC%E3%83%89%E6%A4%9C%E7%B4%A2",
    description: "ワードサーチツールの正規表現検索機能をフル活用する正規表現集",
    openExternal: true,
    Icon: ArticleIcon,
    author: {
      name: "わんど",
      avatar:
        "https://pbs.twimg.com/profile_images/1476938792640528386/mikTeCbe_400x400.jpg",
      description: "scrapbox.io/wandsbox",
    },
  },
  {
    title: "正規表現は今日から使おう(初級編)",
    path: "https://note.com/1220oz_an/n/nc6c032d5286f",
    description:
      "謎解き問題に使えそうな単語を辞書ファイルから検索する方法について",
    openExternal: true,
    Icon: MenuBookIcon,
    author: {
      name: "フライパン職人",
      avatar:
        "https://pbs.twimg.com/profile_images/960019138365108224/NlaLehMU_400x400.jpg",
      description: "note.com/1220oz_an",
    },
  },
];

export const StudioTop: FC = () => {
  const { service, plan, role, claims } = useContext(EnigmaStudioContext);
  const { user } = useFirebaseLogin();

  return (
    <>
      <Container
        maxWidth="lg"
        sx={{
          my: 4,
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Typography
            variant="h3"
            sx={{
              color: "primary.main",
              textShadow: "2px 2px 2px #ccc",
              userSelect: "none",
            }}
          >
            {getLongServiceName({
              service,
              plan,
            })}
          </Typography>
          <PlainText>{getStudioServiceDescription({ plan })}</PlainText>
        </Box>
        <Box sx={{ mt: 2, mb: 4 }} />
        {false && (
          <Container maxWidth="sm">
            <SimpleSearchForm
              index={0}
              query={{
                type: "sequence",
                sequenceType: "simpleSearch",
                processes: [],
              }}
              isDemo={false}
              updateQuery={() => {}}
            />
          </Container>
        )}
        <Box sx={{ mt: 4 }} />
        {["biz", "biz-trial"].includes(plan) ? (
          <Title>謎制作環境</Title>
        ) : (
          <Title>謎制作支援ツール</Title>
        )}
        <Stack
          spacing={{ xs: 1, sm: 2 }}
          direction="row"
          useFlexGap
          flexWrap="wrap"
        >
          {(claims?.workspace ?? []).map((workspace) => (
            <CommonMenuItem
              menuItem={{
                title: "ワークスペース",
                path: `/team/${workspace}`,
                description: `${workspace}に最適化されたクラウド制作環境`,
                Icon: WorkIcon,
              }}
              key={workspace}
            />
          ))}
          {user &&
            ((claims?.workspace ?? []).length === 0 ||
              role === "cloudplus") && (
              <CommonMenuItem
                menuItem={{
                  title: "ワークスペース",
                  path: "/projects",
                  description: "クラウド管理された制作環境",
                  Icon: CloudQueueIcon,
                }}
              />
            )}
          {menuItems.map((item, i) => (
            <CommonMenuItem menuItem={item} key={item.path} />
          ))}
        </Stack>
        <Box sx={{ mt: 4 }} />
        <Title>謎制作ガイド</Title>
        <Stack
          spacing={{ xs: 1, sm: 2 }}
          direction="row"
          useFlexGap
          flexWrap="wrap"
        >
          {guideItems.map((item, i) => (
            <CommonMenuItem menuItem={item} key={item.path} />
          ))}
        </Stack>
      </Container>
    </>
  );
};
